import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import KakaoIcon from "../components/helpers/KakaoIcon";
import NotionIcon from "../components/helpers/NotionIcon";
import KakaoChannelIcon from "../components/helpers/KakaoChannelIcon";
// import CoinIcon from "../components/helpers/CoinIcon";
import NaverBlogIcon from "../components/helpers/NaverBlogIcon";
import MediumIcon from "../components/helpers/MediumIcon";

Vue.use(Vuetify);

const theme = {
    // primary: '#353445',
    // secondary: '#9C27b0',
    // accent: '#9C27b0',
    // info: '#00CAE3',
    // topbar: '#FFFFFF',
    // // topbar: '#045F5F',
    // background: '#F1F2F4',
    // secondbackground: '#F9F9F9',
    // primary: '#004D40',
    // secondary: '#00695C',
    // accent: '#00796B',
    // info: '#00897B',
    // topbar: '#00897B',
    // // topbar: '#045F5F',
    // background: '#F1F2F4',
    // secondbackground: '#F9F9F9',

    // blue
    // primary: '#083efd',
    // secondary: '#e12836',
    // accent: '#fffffb',
    // info: '#cabf45',
    // topbar: '#083efd',
    // // topbar: '#045F5F',
    // // background: '#ff8a80',
    // background: '#083efd',
    // secondbackground: '#C8FFBE',

    // red
    // primary: '#CF001A',
    // primary: '#b71c1c',
    primary: '#A20916',
    secondary: '#DE2828',
    accent: '#fffffb',
    // accent: '#000000',
    info: '#cabf45',
    topbar: '#083efd',
    // topbar: '#045F5F',
    // background: '#ff8a80',
    graishBackground: '#F7F8FA',
    background: '#ffcccb',
    secondbackground: '#ffcccb',
};


export default new Vuetify({
    theme: {
        themes: {
            light: theme,
        }
    },
    icons: {
        values: {
            kakao: {
                component: KakaoIcon,
            },
            notion: {
                component: NotionIcon,
            },
            kakaoChannel: {
                component: KakaoChannelIcon,
            },
            naverBlog: {
                component: NaverBlogIcon,
            },
            medium: {
                component: MediumIcon,
            }
            // coin: {
            //     component: CoinIcon,
            // }
        }
    }

});
