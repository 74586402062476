<template>

    <v-container class="class-note-background">
        <!--                                                <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="200px" width="" alt="" />-->
        <div class="text-center py-10">
            <h2>IVYED lorem ipsum John Doh</h2>

        </div>
        <div class="slider">
            <div class="slide-track">
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="300px" width="" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="100" width="250" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="100" width="250" alt=""/>
                </div>
                <div class="slide">
                    <img src="https://i.postimg.cc/fTSd5nqm/IMG-0129.png" height="100" width="250" alt=""/>
                </div>


                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/1.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
                <!--                <div class="slide">-->
                <!--                    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png" height="100" width="250" alt="" />-->
                <!--                </div>-->
            </div>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "ClassNoteIntro"
    }
</script>

<style scoped>
    .class-note-background {
        background-color: #EDF1F7;
        min-height: 500px;
    }

    body {
        align-items: center;
        background: #E3E3E3;
        display: flex;
        height: 100vh;
        justify-content: center;
    }
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 7));
        }
    }

    .slider {
        background: #EDF1F7;
        /*box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);*/
        /*height: px;*/
        margin: auto;
        overflow: hidden;
        position: relative;
        width: 960px;
    }

    .slider::before, .slider::after {
        /*background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);*/
        content: "";
        height: 300px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    .slider::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    .slider::before {
        left: 0;
        top: 0;
    }

    .slider .slide-track {
        animation: scroll 40s linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slider .slide {
        height: 300px;
        width: 250px;
    }
    img {
        border-radius: 10px;

    }

</style>
