<template>
    <div>
        <div>
            <v-card class="pa-5">
                <v-card-title class="font-weight-bold text-h5">
                    SAT는 필수 vs 선택
                </v-card-title>
                <v-card-text style="font-size: 17px; line-height: 30px">
                    코로나 시대를 맞이하며 상위권 대학을 포함한 많은 대학교들이 SAT 점수를 더 이상 필수로 요구하지 않고 있습니다.
                    <br>
                    <br>
                    하버드 대학교 홈페이지에서도 점수를 보내지 않아도 불리하게 보지 않는다고 이야기하고있는 것을 확인하실 수 있습니다.
                    <br/>
                    <br/>
                    <div class="font-italic">
                        "... Students who do not submit standardized test scores will not be disadvantaged in their
                        application ..."
                    </div>
                    <div class="text-right">
                        Harvard Admissions
                    </div>
                    <br/>
                    <br/>
                    <div>
                        이렇게 선택사항으로 바뀐 SAT 정말 안 봐도 될까요?
                        <br/>
                        <br/>
                        SAT 점수 없이 명문대 입학이 가능한 것은 사실이지만
                        SAT 점수 있는 학생들이 입시에 여러모로 유리하니 SAT 응시를 적극 권유하고 있습니다.
                    </div>

                    <v-divider class="my-7"></v-divider>
                    <h3>SAT 응시가 유리한 이유</h3>
                    <br/>
                    <h4 style="font-weight: normal">
                        <div class="font-weight-bold font-italic">1. SAT 점수가 Optional이지만 입시 결정에 전혀 반영이 안 되는 것은 아닙니다.</div>
                        이건 간단한 통계자료만 봐도 알 수 있는데 2020년 SAT Optional 제도를 도입한 University of Pennsylvania 같은 경우 수시 합격생의 75%가 SAT 점수를 제출한 학생입니다.
                        <v-divider dark class="my-7"></v-divider>
                        <div class="font-weight-bold font-italic">2. SAT 응시자들은 더 많은 대학에 응시할 수 있습니다.</div>
                        Georgetown, Georgia Institute of Technology, MIT, University of Michigan - Ann Arbor, ... 등 많은 대학교들 SAT를 요구하거나 권장하고 있습니다.
                        또한 코로나 규제가 점점 완화되면서 SAT 요구하는 학교가 늘어날 수 있는 점도 배제할 수 없어 정말 특수한 경우가 아니면 SAT를 응시하여 모든 학교에 지원할 수 있게 준비하시는 것이 유리합니다.
                        <v-divider  class="my-12"></v-divider>
                        SAT 시험은 틈틈이 그리고 미리 공부하는 것이 정말 중요합니다. 원서 시즌에 SAT 점수가 아직 준비 안되어 급하게 준비하면 성적도 잘 나오지 않을뿐더러 최악의 경우 수시 지원 deadline까지 놓져버리게 됩니다.
                        <br/>
                        <br/>
                        방학 기간 집중 수업도 좋지만 학기 중에도 이어서 꾸준히 문제풀이를 하시면 원하는 SAT 점수를 좀 더 빨리 준비하실 수 있을 겁니다.
                    </h4>

                </v-card-text>

                <v-card-text style="line-height: 30px">

                </v-card-text>
            </v-card>

        </div>
    </div>
</template>

<script>
    export default {
        name: "SATorNOT"
    }
</script>

<style scoped>

</style>
