<template>
    <HomeTest></HomeTest>
<!--    <div style="background-color: white" class="home-background">-->
<!--        &lt;!&ndash; Hero Container &ndash;&gt;-->
<!--        &lt;!&ndash;    <div>&ndash;&gt;-->
<!--        <v-container fluid class="accent&#45;&#45;text hidden-sm-and-down center-vertical pt-10"-->
<!--                     :class="{'hero-container': $vuetify.breakpoint.mdAndUp}">-->
<!--            <v-container class="new-container " fluid>-->
<!--                <v-row>-->
<!--                    <v-col md="1">-->
<!--                    </v-col>-->
<!--                    <v-col cols="12" md="4" class="primary&#45;&#45;text"-->

<!--                    >-->



<!--                        <span class="ivy-ed-font"-->
<!--                              :class="{-->

<!--                    }">-->
<!--                            &lt;!&ndash;                            비대면 명문대<br/> 유학과외<br/>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            어디서나 <br/> 명문대 선생님과 <br/> 1:1 유학과외<br/>&ndash;&gt;-->
<!--                            <h1 class="main-color">IVY EDUCATION<br/> ON THE GO<br/></h1>-->
<!--                        </span>-->


<!--                        <h2 class="text-h6 font-weight-bold black&#45;&#45;text  mt-10">-->
<!--                            나에게 딱 맞는 <br/> <strong>미국 최상위 명문대 선생님</strong>과 <br/>온라인 1:1 과외-->
<!--                        </h2>-->
<!--                        <v-btn-->
<!--                                to="/register"-->
<!--                                color="secondary"-->
<!--                                width="30%"-->
<!--                                x-large-->
<!--                                class="text-h6 mt-7 mb-4 register-btn">시작하기-->
<!--                        </v-btn>-->
<!--                    </v-col>-->

<!--                    <v-col md="7">-->
<!--                        <v-card max-width="100%" class="text-center" flat>-->
<!--                            <v-carousel style="margin: 0 !important; padding: 0 !important; "-->
<!--                                        height="380px"-->
<!--                                        ref="myCarousel"-->
<!--                                        hide-delimiter-background-->
<!--                                        :show-arrows-on-hover="true"-->
<!--                                        next-icon="mdi-menu-right"-->
<!--                                        prev-icon="mdi-menu-left"-->
<!--                                        cycle-->
<!--                                        interval="4000"-->
<!--                            >-->
<!--                                <v-carousel-item>-->
<!--                                    <img src="../assets/test.gif" width="" class="tablet-img"/>-->

<!--                                </v-carousel-item>-->
<!--                                <v-carousel-item>-->
<!--                                    <img src="../assets/final-cs-1.gif" width="" class="tablet-img"/>-->

<!--                                </v-carousel-item>-->
<!--                            </v-carousel>-->
<!--                        </v-card>-->
<!--                    </v-col>-->

<!--                    &lt;!&ndash;                    <v-col cols="12" class="" style=""&ndash;&gt;-->
<!--                    &lt;!&ndash;                           md="6"&ndash;&gt;-->
<!--                    &lt;!&ndash;                           :class="{&ndash;&gt;-->
<!--                    &lt;!&ndash;                    'home-text': $vuetify.breakpoint.mdAndUp&ndash;&gt;-->
<!--                    &lt;!&ndash;                    }">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                        <v-carousel&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                cycle&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                height="400"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                hide-delimiter-background&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                show-arrows-on-hover>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                            <v-carousel-item&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                    v-for="(item,i) in items"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                    :key="i"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                    :src="item.src"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                    reverse-transition="fade-transition"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                                    transition="fade-transition"&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                            ></v-carousel-item>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;                        &lt;!&ndash;                        </v-carousel>&ndash;&gt;&ndash;&gt;-->

<!--                    &lt;!&ndash;&lt;!&ndash;                        <Flicking :options="{ align: 'prev', circular: true }" @move-end="onMoveEnd">&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;&lt;!&ndash;                            <div class="panel">1</div>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;&lt;!&ndash;                            <div class="panel">2</div>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;&lt;!&ndash;                            <div class="panel">3</div>&ndash;&gt;&ndash;&gt;-->
<!--                    &lt;!&ndash;&lt;!&ndash;                        </Flicking>&ndash;&gt;&ndash;&gt;-->

<!--                    &lt;!&ndash;                        <v-carousel style="margin: 0 !important; padding: 0 !important; width: 550px;"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    height="380px"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    ref="myCarousel"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    hide-delimiters&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    hide-delimiter-background&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    :show-arrows-on-hover="true"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    next-icon="mdi-menu-right"&ndash;&gt;-->
<!--                    &lt;!&ndash;                                    >&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <v-carousel-item>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <img src="../assets/test.gif" class="tablet-img"/>&ndash;&gt;-->

<!--                    &lt;!&ndash;                            </v-carousel-item>&ndash;&gt;-->
<!--                    &lt;!&ndash;                            <v-carousel-item>&ndash;&gt;-->
<!--                    &lt;!&ndash;                                <img src="../assets/final-cs.gif" class="tablet-img"/>&ndash;&gt;-->

<!--                    &lt;!&ndash;                            </v-carousel-item>&ndash;&gt;-->
<!--                    &lt;!&ndash;                        </v-carousel>&ndash;&gt;-->

<!--                    &lt;!&ndash;                        &lt;!&ndash;                        <button class="button-85" role="button">가입하기</button>&ndash;&gt;&ndash;&gt;-->

<!--                    &lt;!&ndash;                    </v-col>&ndash;&gt;-->


<!--                </v-row>-->

<!--                &lt;!&ndash;                <v-container class="new-container align-center">&ndash;&gt;-->
<!--                &lt;!&ndash;                    <v-btn&ndash;&gt;-->
<!--                &lt;!&ndash;                            to="/register"&ndash;&gt;-->
<!--                &lt;!&ndash;                            color="secondary"&ndash;&gt;-->
<!--                &lt;!&ndash;                            width="20%"&ndash;&gt;-->
<!--                &lt;!&ndash;                            x-large&ndash;&gt;-->
<!--                &lt;!&ndash;                            class="text-h6 mb-4 register-btn">가입하기&ndash;&gt;-->
<!--                &lt;!&ndash;                    </v-btn>&ndash;&gt;-->
<!--                &lt;!&ndash;                    &lt;!&ndash;                <v-divider class="transparent">&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;                    &lt;!&ndash;                </v-divider>&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;&lt;!&ndash;                    <v-btn color="white" class="" text @click="scrollto('success-section', 'mycourses-section')">&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;&lt;!&ndash;                        <v-icon size="40" color="primary">mdi-chevron-triple-down</v-icon>&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;&lt;!&ndash;                    </v-btn&ndash;&gt;&ndash;&gt;-->
<!--                &lt;!&ndash;&lt;!&ndash;                    >&ndash;&gt;&ndash;&gt;-->

<!--                &lt;!&ndash;                </v-container>&ndash;&gt;-->
<!--            </v-container>-->
<!--        </v-container>-->

<!--        <v-container fluid class="accent&#45;&#45;text hidden-md-and-up mobile-hero-background pt-10">-->
<!--            <v-container class="new-container">-->
<!--                <v-row>-->
<!--                    <v-col cols="12" md="6" class="primary&#45;&#45;text mx-3 ivy-ed-font"-->
<!--                    >-->

<!--                        <p class="font-weight-bold mt-5 main-color slogan-text">-->
<!--                            IVY EDUCATION-->
<!--                            <br/>ON THE GO-->
<!--                        </p>-->
<!--                        &lt;!&ndash;                        <p class="font-weight-bold main-text-color">&ndash;&gt;-->
<!--                        &lt;!&ndash;                            ON THE GO&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </p>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <span class="font-weight-bold text-h4 color-1">IVY EDUCATION</span><br/>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <span class="font-weight-bold text-h4 color-2">IVY EDUCATION</span><br/>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <span class="font-weight-bold text-h4 color-3">IVY EDUCATION</span>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <p class="font-weight-bold text-h4  black&#45;&#45;text">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        내 자리에서&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </p>&ndash;&gt;-->
<!--                        &lt;!&ndash;                    <v-divider class="transparent my-8"></v-divider>&ndash;&gt;-->

<!--                        <p class="text-h6 font-weight-medium mt-8 black&#45;&#45;text">-->
<!--                            나에게 딱 맞는 <br/> <strong>미국 최상위 명문대 선생님</strong>과 <br/>온라인 1:1 과외-->
<!--                        </p>-->
<!--                        &lt;!&ndash;                    <p class="text-subtitle-1 font-weight-bold mt-4">&ndash;&gt;-->
<!--                        &lt;!&ndash;                        나에게 딱 맞는&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <br/> IVY LEAGUE 및 미국 최상위 명문대 선생님을&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <br/> 아이비에드 스마트 매칭으로 바로 만나보세요!&ndash;&gt;-->
<!--                        &lt;!&ndash;                    </p>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        <img src="../assets/test.gif" class="mt-8" width="90%"/>&ndash;&gt;-->

<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-card flat style="padding-left: 12px; padding-right: 12px">-->
<!--                    <v-carousel-->

<!--                            ref="myCarousel"-->
<!--                            hide-delimiter-background-->
<!--                            :show-arrows="false"-->
<!--                            next-icon="mdi-menu-right"-->
<!--                            prev-icon="mdi-menu-left"-->
<!--                            cycle-->
<!--                            delimiter-icon=""-->
<!--                            interval="4000"-->
<!--                            height=""-->
<!--                    >-->
<!--                        <v-carousel-item class="text-center">-->
<!--                            <img width="100%" src="../assets/test.gif" class=""/>-->

<!--                        </v-carousel-item>-->
<!--                        <v-carousel-item class="text-center">-->
<!--                            <img width="100%" src="../assets/final-cs-1.gif" class=""/>-->

<!--                        </v-carousel-item>-->
<!--                    </v-carousel>-->
<!--                </v-card>-->

<!--                <div class="center-horizontal">-->

<!--                    <v-btn-->
<!--                            to="/register"-->
<!--                            color="secondary"-->
<!--                            width="40%"-->
<!--                            x-large-->
<!--                            class="font-weight-bold my-7 register-btn"-->
<!--                    >시작하기-->
<!--                    </v-btn>-->

<!--                    &lt;!&ndash;                    <v-btn color="white" text @click="scrollto('success-section')">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        <v-icon size="40" color="primary">mdi-chevron-triple-down</v-icon>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </v-btn&ndash;&gt;-->
<!--                    >-->
<!--                </div>-->
<!--            </v-container>-->
<!--        </v-container>-->


<!--        &lt;!&ndash;    Join Now Section&ndash;&gt;-->
<!--        <v-container fluid id="success-section" class="hidden-sm-and-down pt-15 pb-15"-->
<!--                     :class="{-->
<!--        'selling-point-container': $vuetify.breakpoint.mdAndUp,-->
<!--        'mobile-selling-point-container': $vuetify.breakpoint.smAndDown,}">-->


<!--            &lt;!&ndash;            <p class="text-h3 font-weight-bold main-text-color text-center center-vertical">&ndash;&gt;-->
<!--            &lt;!&ndash;                아이비에드가 왜 특별한가요?&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->
<!--            <div class="mt-8">-->

<!--                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    &lt;!&ndash;                1. [HARD TO FIND FIT / FREQUENT TURNOVERS] <br/>&ndash;&gt;-->

<!--                    &lt;!&ndash;                [PHYSICAL DISTANCE]&ndash;&gt;-->
<!--                    <p class="font-weight-bold main-text-color text-h4">ANYWHERE </p>-->
<!--                    <p class="text-h6 font-weight-medium">-->
<!--                        학생과 선생님이 <br/> 각자 원하는 장소에서<br/>태블릿으로 수업을 진행합니다.-->
<!--                    </p>-->

<!--                </v-container>-->

<!--                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    <p class="font-weight-bold main-text-color text-h4"> TOP CLASS TUTORS<br/>-->
<!--                    <p class="text-h6 font-weight-medium">-->
<!--                        아이비리그 및 미국 최상위 명문대 <br/>선생님들을 만나 볼 수 있습니다.-->
<!--                    </p>-->
<!--                </v-container>-->
<!--                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    <p class="font-weight-bold main-text-color text-h4"> SMART MATCHING <br/>-->
<!--                    <p class="text-h6 font-weight-medium">-->
<!--                        학생의 요청사항에 맞춰 <br/> 자동으로 선생님을 배정해드립니다.-->
<!--                    </p>-->
<!--                </v-container>-->

<!--            </div>-->

<!--            &lt;!&ndash;            <p class="text-h5 font-weight-bold text-center center-vertical">&ndash;&gt;-->
<!--            &lt;!&ndash;                3. [MATERIAL KEEPING]<br/>&ndash;&gt;-->
<!--            &lt;!&ndash;                문제 풀이하느라 노트도 적지 못해 수업 후에 복습하기가 어렵지 않으셨나요?&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->

<!--            &lt;!&ndash;            <p class="text-h5 font-weight-bold text-center center-vertical">&ndash;&gt;-->
<!--            &lt;!&ndash;                3. [MATERIAL KEEPING]<br/>&ndash;&gt;-->
<!--            &lt;!&ndash;                문제 풀이하느라 노트도 적지 못해 수업 후에 복습하기가 어렵지 않으셨나요?&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->


<!--        </v-container>-->

<!--        <v-container fluid id="success-section" class="primary&#45;&#45;text hidden-md-and-up pt-15 pb-15"-->
<!--                     :class="{-->
<!--        'selling-point-container': $vuetify.breakpoint.mdAndUp,-->
<!--        'mobile-selling-point-container': $vuetify.breakpoint.smAndDown,}">-->


<!--            &lt;!&ndash;            <p class="text-h4 font-weight-bold  text-center center-vertical">&ndash;&gt;-->
<!--            &lt;!&ndash;                아이비에드가 <br/> 왜 특별한가요?&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->
<!--            <div class="mt-8">-->

<!--                <v-container class=" main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    &lt;!&ndash;                1. [HARD TO FIND FIT / FREQUENT TURNOVERS] <br/>&ndash;&gt;-->

<!--                    &lt;!&ndash;                [PHYSICAL DISTANCE]&ndash;&gt;-->
<!--                    <p class="font-weight-bold main-text-color text-h4">ANYWHERE </p>-->
<!--                    <p class="text-h6 font-weight-medium ">-->
<!--                        학생과 선생님이 <br/> 각자 원하는 장소에서<br/>태블릿으로 수업을 진행합니다.-->
<!--                    </p>-->

<!--                </v-container>-->

<!--                <v-container class=" main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    <p class="font-weight-bold main-text-color text-h4"> TOP CLASS TUTORS<br/>-->
<!--                    <p class="text-h6 font-weight-medium ">-->
<!--                        아이비리그 및 미국 최상위 명문대 <br/>선생님들을 만나 볼 수 있습니다.-->
<!--                    </p>-->
<!--                </v-container>-->
<!--                <v-container class=" main-text-color mb-4" color="transparent" elevation="0">-->
<!--                    <p class="font-weight-bold main-text-color text-h4"> SMART MATCHING <br/>-->
<!--                    <p class="text-h6 font-weight-medium ">-->
<!--                        학생의 요청사항에 맞춰 <br/> 자동으로 선생님을 배정해드립니다.-->
<!--                    </p>-->
<!--                </v-container>-->

<!--            </div>-->
<!--        </v-container>-->


<!--        &lt;!&ndash;Anywhere Section&ndash;&gt;-->
<!--        <v-container fluid class="anywhere-section pt-15 pb-10">-->

<!--            <v-container class="new-container">-->
<!--                <v-row-->
<!--                        justify="center"-->
<!--                        align="center"-->
<!--                        class="mt-3"-->
<!--                        :class="{-->
<!--            'mb-5': $vuetify.breakpoint.xs,-->
<!--          }">-->
<!--                    <v-col class="center-horizontal mb-7">-->
<!--                        <p class="sub-main-01 black&#45;&#45;text">IVYED 수업은?</p>-->
<!--                    </v-col>-->
<!--                </v-row>-->

<!--                &lt;!&ndash;  MOBILE &ndash;&gt;-->
<!--                <div class="hidden-md-and-up center-horizontal">-->
<!--                    <v-card-->
<!--                            color="#F7F8FA"-->
<!--                            class="black&#45;&#45;text "-->
<!--                            max-width="344"-->
<!--                            elevation="0"-->

<!--                    >-->
<!--                        <v-list-item three-line>-->
<!--                            <v-list-item-avatar-->
<!--                                    tile-->
<!--                                    size="80"-->
<!--                            >-->
<!--                                <v-icon color="#A20916" class="icon-style" size="70px">mdi-bullseye-arrow</v-icon>-->


<!--                            </v-list-item-avatar>-->
<!--                            <v-list-item-content>-->
<!--                                &lt;!&ndash;                                <div class="text-overline mb-4">&ndash;&gt;-->
<!--                                &lt;!&ndash;                                    OVERLINE&ndash;&gt;-->
<!--                                &lt;!&ndash;                                </div>&ndash;&gt;-->
<!--                                <v-list-item-title class="font-weight-bold text-h6 mb-1">-->
<!--                                    FOCUS-->
<!--                                </v-list-item-title>-->

<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">음성, 필기, 화면 실시간 공유 <span-->
<!--                                        class="">🙆🏻‍♂️</span>-->
<!--                                </v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">얼굴 공개 <span-->
<!--                                        class="">🙅🏻</span></v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">수업에만 신경쓰세요!-->
<!--                                </v-list-item-subtitle>-->
<!--                            </v-list-item-content>-->


<!--                        </v-list-item>-->
<!--                    </v-card>-->
<!--                    <v-card-->
<!--                            color="#F7F8FA"-->
<!--                            class=""-->
<!--                            max-width="344"-->
<!--                            elevation="0"-->
<!--                    >-->
<!--                        <v-list-item three-line>-->
<!--                            <v-list-item-avatar-->
<!--                                    tile-->
<!--                                    size="80"-->
<!--                            >-->
<!--                                <v-icon color="#1F5C00" class="icon-style" size="70px">mdi-earth</v-icon>-->


<!--                            </v-list-item-avatar>-->
<!--                            <v-list-item-content>-->
<!--                                <v-list-item-title class="text-h6 font-weight-bold">-->
<!--                                    ON THE GO-->
<!--                                </v-list-item-title>-->
<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">인터넷 연결만 있다면 <br/> 어디서나 수업 가능-->
<!--                                </v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                            </v-list-item-content>-->


<!--                        </v-list-item>-->
<!--                    </v-card>-->

<!--                    <v-card-->
<!--                            class=""-->
<!--                            max-width="344"-->
<!--                            elevation="0"-->
<!--                            color="#F7F8FA"-->
<!--                    >-->
<!--                        <v-list-item three-line>-->
<!--                            <v-list-item-avatar-->
<!--                                    tile-->
<!--                                    size="80"-->
<!--                            >-->
<!--                                <v-icon color="#56423E" class="icon-style" size="70px">mdi-cog-outline</v-icon>-->


<!--                            </v-list-item-avatar>-->
<!--                            <v-list-item-content>-->
<!--                                <v-list-item-title class="text-h6 mb-1 font-weight-bold">-->
<!--                                    PERSONALIZED-->
<!--                                </v-list-item-title>-->
<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">교재, 진도, 수업 방식 모두 <br/> 나에게 맞춘-->
<!--                                    1:1 수업-->
<!--                                </v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                            </v-list-item-content>-->


<!--                        </v-list-item>-->
<!--                    </v-card>-->

<!--                    <v-card-->
<!--                            class=""-->
<!--                            max-width="344"-->
<!--                            elevation="0"-->
<!--                            color="#F7F8FA"-->

<!--                    >-->
<!--                        <v-list-item three-line>-->
<!--                            <v-list-item-avatar-->
<!--                                    tile-->
<!--                                    size="80"-->
<!--                            >-->
<!--                                <v-icon color="#32476C" class="icon-style" size="70px">-->
<!--                                    mdi-book-open-page-variant-outline-->
<!--                                </v-icon>-->


<!--                            </v-list-item-avatar>-->
<!--                            <v-list-item-content>-->
<!--                                <v-list-item-title class="text-h6 font-weight-bold mb-1">-->
<!--                                    REVIEW-->
<!--                                </v-list-item-title>-->
<!--                                <v-list-item-subtitle class="black&#45;&#45;text text-subtitle-1">매 수업 녹화본과 <br/>수업 리포트로 복습도-->
<!--                                    손쉽게!-->
<!--                                </v-list-item-subtitle>-->
<!--                                <v-list-item-subtitle></v-list-item-subtitle>-->
<!--                            </v-list-item-content>-->


<!--                        </v-list-item>-->

<!--                        &lt;!&ndash;                        <v-card-actions>&ndash;&gt;-->
<!--                        &lt;!&ndash;                            <v-btn&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    outlined&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    rounded&ndash;&gt;-->
<!--                        &lt;!&ndash;                                    text&ndash;&gt;-->
<!--                        &lt;!&ndash;                            >&ndash;&gt;-->
<!--                        &lt;!&ndash;                                Button&ndash;&gt;-->
<!--                        &lt;!&ndash;                            </v-btn>&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </v-card-actions>&ndash;&gt;-->
<!--                    </v-card>-->


<!--                </div>-->

<!--                &lt;!&ndash;  BROWSER &ndash;&gt;-->
<!--                <v-row class="hidden-sm-and-down">-->

<!--                    <v-card width="50%" class="justify-left" color="graishBackground" elevation="0">-->
<!--                        <div class="center-horizontal">-->
<!--                            <v-card width="20%" elevation="0" color="graishBackground" class="center-horizontal mb-5"-->
<!--                                    rounded>-->
<!--                                <v-icon color="#A20916" class="icon-style" size="70px">mdi-bullseye-arrow</v-icon>-->
<!--                            </v-card>-->

<!--                            <p class="text-h4 font-weight-bold">FOCUS</p>-->

<!--                            <div>음성, 필기, 화면 실시간 공유 <span class="text-h6"> 🙆🏻‍♂️</span></div>-->
<!--                            <div>얼굴 공개 <span class="text-h6">🙅🏻</span></div>-->
<!--                            <div>오로지 수업에만 신경쓰세요!</div>-->


<!--                        </div>-->
<!--                    </v-card>-->

<!--                    <v-card width="50%" class="justify-left" color="graishBackground" elevation="0">-->
<!--                        <div class="center-horizontal">-->
<!--                            <v-card width="20%" color="graishBackground" elevation="0" class="center-horizontal mb-5"-->
<!--                                    rounded>-->
<!--                                <v-icon color="#1F5C00" size="70px">mdi-earth</v-icon>-->
<!--                            </v-card>-->
<!--                            <p class="text-h4 font-weight-bold">ON THE GO</p>-->
<!--                            <p>인터넷 연결만 있다면 어디서나 수업 가능-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </v-card>-->
<!--                </v-row>-->
<!--                <v-divider class="transparent mb-15"></v-divider>-->
<!--                <v-row class="hidden-sm-and-down">-->
<!--                    <v-card width="50%" class="justify-left" color="graishBackground" elevation="0">-->
<!--                        <div class="center-horizontal">-->
<!--                            <v-card width="20%" color="graishBackground" elevation="0" class="center-horizontal mb-5"-->
<!--                                    rounded>-->
<!--                                <v-icon color="#56423E" size="70px">mdi-cog-outline</v-icon>-->
<!--                            </v-card>-->
<!--                            <p class="text-h4 font-weight-bold">PERSONALIZED</p>-->
<!--                            <p>교재, 진도, 수업 방식 모두 나에게 맞춘 1:1 수업-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </v-card>-->

<!--                    <v-card width="50%" class="justify-left" color="graishBackground" elevation="0">-->
<!--                        <div class="center-horizontal">-->
<!--                            <v-card width="20%" color="graishBackground" elevation="0" class="center-horizontal mb-5"-->
<!--                                    rounded>-->
<!--                                <v-icon color="#32476C" size="70px">mdi-book-open-page-variant-outline</v-icon>-->
<!--                            </v-card>-->
<!--                            <p class="text-h4 font-weight-bold">REVIEW</p>-->
<!--                            <p>매 수업 녹화본과 선생님 피드백 리포트로 복습도 손쉽게!-->
<!--                            </p>-->
<!--                        </div>-->
<!--                    </v-card>-->

<!--                </v-row>-->
<!--            </v-container>-->
<!--        </v-container>-->


<!--        <v-container fluid class="top-class-tutor-section pt-15 pb-10">-->

<!--            <v-container class="new-container">-->
<!--                <v-row-->
<!--                        align="right"-->
<!--                        class="mt-3"-->
<!--                        :class="{-->
<!--            'mb-5': $vuetify.breakpoint.xs,-->
<!--          }">-->
<!--                    <v-col class="justify-left">-->
<!--                        <p class="sub-main-01 black&#45;&#45;text pb-7">IVYED 선생님은?</p>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                &lt;!&ndash;                <img class="mt-15" src="../assets/cropped-ivy.png" width="100%">&ndash;&gt;-->
<!--                &lt;!&ndash;                <img class="mt-15" src="../assets/emblem.png" width="100%">&ndash;&gt;-->

<!--                <v-row class="mt-3 paragraph-font justify-center"-->
<!--                       :class="{-->
<!--                                'mb-5': $vuetify.breakpoint.xs,-->
<!--                                'mt-10': $vuetify.breakpoint.mdAndUp,-->
<!--                              }"-->
<!--                >-->


<!--                    &lt;!&ndash;                    <br/>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    <br/>&ndash;&gt;-->
<!--                    <div class="hidden-sm-and-down">-->
<!--                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="500px">-->
<!--                            <v-card-title class="teacher-section-title">미국 아이비리그 및 최고 명문대 출신</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">U.S. News Ranking 기준 <br/>-->
<!--                                상위 1% 대학교 출신으로만 구성-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <div class="center-horizontal">-->
<!--                                &lt;!&ndash;                            <img  src="../assets/ivyleagelogostransparent.png" width="70%" />&ndash;&gt;-->
<!--                                <img src="../assets/finalNewPrinceton.png" width="70%"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                            <img src="../assets/ivyleagelogostransparent.png" width="100%" />&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->

<!--                        <v-divider class="mb-4 transparent"></v-divider>-->
<!--                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="500px">-->
<!--                            <v-card-title class="teacher-section-title"> 깐깐한 선발과정으로 검증된 선생님</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">서류전형과 1:1 면접 심사를 거친 <br/>-->
<!--                                학생들이 신뢰할 수 있는 최고의 선생님-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <v-container fluid class="mb-5 center-horizontal">-->
<!--                                <div>-->
<!--                                    <v-icon size="100px" color="primary">mdi-certificate-outline</v-icon>-->
<!--                                    <v-icon color="" size="60">mdi-arrow-right-bold</v-icon>-->
<!--                                    <v-icon size="100px" color="#2146B3">mdi-account-supervisor</v-icon>-->
<!--                                    <v-icon size="60">mdi-arrow-right-bold</v-icon>-->
<!--                                    <v-icon size="100px" color="#6876EC">mdi-human-male-board</v-icon>-->
<!--                                </div>-->
<!--                                &lt;!&ndash;                            <img src="../assets/teacherOnboardingProcess.png" width="100%" height=""/>&ndash;&gt;-->
<!--                            </v-container>-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                        <v-card elevation="24" class="mb-8 rounded-xl" max-width="500px">-->
<!--                            <v-card-title class="teacher-section-title">비대면 온라인 과외 교육 수료</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">IVYED만의 강의와 피드백 노하우로 <br/>-->
<!--                                고퀄리티 비대면 수업 보장-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <div class="center-horizontal">-->
<!--                                <img src="../assets/ELs__Tech_2_.width-800.png" width="90%"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                        <v-divider class="mb-4 transparent"></v-divider>-->
<!--                    </div>-->

<!--                    <p class="hidden-md-and-up">-->

<!--                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="344">-->
<!--                            <v-card-title class="teacher-section-title">미국 아이비리그 및 <br/> 최고 명문대 출신</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">U.S. News Ranking 기준 <br/>-->
<!--                                상위 1% 대학교 출신으로만 구성-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <div class="center-horizontal">-->
<!--                                &lt;!&ndash;                            <img  src="../assets/ivyleagelogostransparent.png" width="70%" />&ndash;&gt;-->
<!--                                <img src="../assets/finalNewPrinceton.png" width="70%"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                            <img src="../assets/ivyleagelogostransparent.png" width="100%" />&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->

<!--                        <v-divider class="mb-4 transparent"></v-divider>-->
<!--                        <v-card elevation="24" class="mb-15 rounded-xl" max-width="344">-->
<!--                            <v-card-title class="teacher-section-title"> 깐깐한 선발과정으로 <br/> 검증된 선생님</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">서류전형과 1:1 면접 심사를 거친 <br/>-->
<!--                                학생들이 신뢰할 수 있는 최고의 선생님-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <v-container fluid class="mb-5">-->
<!--                                <v-icon size="70px" color="primary">mdi-certificate-outline</v-icon>-->
<!--                                <v-icon color="" size="45">mdi-arrow-right-bold</v-icon>-->
<!--                                <v-icon size="70px" color="#2146B3">mdi-account-supervisor</v-icon>-->
<!--                                <v-icon size="45">mdi-arrow-right-bold</v-icon>-->
<!--                                <v-icon size="70px" color="#6876EC">mdi-human-male-board</v-icon>-->
<!--                                &lt;!&ndash;                            <img src="../assets/teacherOnboardingProcess.png" width="100%" height=""/>&ndash;&gt;-->
<!--                            </v-container>-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                        <v-divider class="mb-4 transparent"></v-divider>-->

<!--                        <v-card elevation="24" class="mb-15 rounded-xl" max-width="344">-->
<!--                            <v-card-title class="teacher-section-title">비대면 온라인 과외 교육 수료</v-card-title>-->
<!--                            <v-card-subtitle class="text-subtitle-1">IVYED만의 강의와 피드백 노하우로 <br/>-->
<!--                                고퀄리티 비대면 수업 보장-->
<!--                            </v-card-subtitle>-->
<!--                            &lt;!&ndash;                            <v-divider></v-divider>&ndash;&gt;-->
<!--                            <div class="center-horizontal">-->
<!--                                <img src="../assets/ELs__Tech_2_.width-800.png" width="80%"/>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>&ndash;&gt;-->
<!--                        </v-card>-->
<!--                    </p>-->

<!--                    <br/>-->

<!--                </v-row>-->

<!--            </v-container>-->
<!--        </v-container>-->

<!--        <v-container fluid class="smart-matching-section pt-15 pb-13">-->

<!--            <v-container class="new-container">-->
<!--                <v-row-->
<!--                        align=""-->
<!--                        class="mt-3"-->
<!--                        :class="{-->
<!--            'mb-5': $vuetify.breakpoint.xs,-->
<!--          }">-->
<!--                    <v-col>-->
<!--                        <p class="sub-main-01 black&#45;&#45;text pb-5">Smart Matching</p>-->
<!--                    </v-col>-->
<!--                    <v-col>-->
<!--                        &lt;!&ndash;                        <img src="../assets/img.png">&ndash;&gt;-->
<!--                        &lt;!&ndash;&ndash;&gt;-->
<!--                        &lt;!&ndash;                        </img>&ndash;&gt;-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <SmartMatching></SmartMatching>-->
<!--            </v-container>-->


<!--        </v-container>-->
<!--        <v-container fluid class="pt-15 pb-10 faq-section">-->

<!--            <v-container class="new-container  center-horizontal">-->
<!--                <v-row-->
<!--                        align=""-->
<!--                        class="mt-3"-->
<!--                        :class="{-->
<!--            'mb-5': $vuetify.breakpoint.xs,-->
<!--          }">-->
<!--                </v-row>-->
<!--                <FAQ></FAQ>-->
<!--            </v-container>-->


<!--        </v-container>-->

<!--        <v-container fluid class="grayish-color pt-15 pb-15">-->

<!--            <v-container class="new-container">-->
<!--                <v-row-->
<!--                        align=""-->
<!--                        class="mt-3"-->
<!--                        :class="{-->
<!--            'mb-5': $vuetify.breakpoint.xs,-->
<!--          }">-->
<!--                </v-row>-->
<!--                <MarketingHome></MarketingHome>-->
<!--            </v-container>-->


<!--        </v-container>-->

<!--    </div>-->

</template>

<script>
    import info from "../../info";
    import HomeTest from "./Intro/HomeTest";
    // import SmartMatching from "./SmartMatching";
    // import FAQ from "../views/pages/Faq";
    // import MarketingHome from "../views/pages/Marketing-Home";
    // import { Flicking } from "@egjs/vue-flicking";

    export default {
        name: "Home",
        components:
            {
                HomeTest
                // MarketingHome,
                // FAQ,
                // SmartMatching,
                // Flicking: Flicking
            },
        data() {
            return {
                move: [],
                drag: false,
                touch: false,
                content: "",
                data: info.home,
                items: [
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
                    },
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
                    },
                ]

            };
        },
        methods: {
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            }
        },
        mounted() {
            // For touch devices
            this.$refs.myCarousel.$el.addEventListener("touchmove", (e) => {
                this.drag = false;
                this.touch = true;
                this.logic(e);
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("touchend", (e) => {
                this.move = [];
            });

            // For non-touch devices
            this.$refs.myCarousel.$el.addEventListener("mousedown", (e) => {
                this.drag = true;
                this.touch = false;
                this.logic(e);
            });
            this.$refs.myCarousel.$el.addEventListener("mousemove", (e) => {
                this.drag ? this.logic(e) : null;
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("mouseup", (e) => {
                this.drag = false;
                this.touch = false;
                this.move = [];
            });


        },
    };
</script>
<style scoped>
    .glow {
        color: #A20916;
        text-align: center;
        animation: glow 2s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
        }
    }

    .courses-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .courses-container {
        background-color: rgb(245, 245, 245);
    }

    .console-container {

        font-family: Khula;
        font-size: 4em;
        text-align: center;
        height: 200px;
        width: 600px;
        display: block;
        position: absolute;
        color: white;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .console-underscore {
        display: inline-block;
        position: relative;
        top: -0.14em;
        left: 10px;
    }

    .hero-container {
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/worldmap.png) center center/cover no-repeat;*/
        background-color: #fffffb;
        height: 750px;
        /*calc(100vh - 50px);*/
        /*width: 100%;*/
        font-weight: normal;
        /*box-shadow: inset 0 0 0 1000px rgb('#A20916');*/
        /*rgba(0, 0, 0, 0.3);*/
        object-fit: contain;
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        /*align-items: center;*/
        /*color: #A20916;*/
        /*font-family: HallymGothic-Regular;*/
    }

    .mobile-hero-container {
        /*background: url(../assets/background_option_books_001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        background-color: #fffffb;
        /*height: 100%;*/
        /*height: 100%;*/
        /*calc(100vh - 50px);*/
        width: 100%;
        font-weight: normal;
        /*box-shadow: inset 0 0 0 1000px rgb('#A20916');*/
        /*rgba(0, 0, 0, 0.3);*/
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*color: #A20916;*/
        /*font-family: HallymGothic-Regular;*/
    }


    .selling-point-container {
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background-color: #62FFE0;*/
        /*background-color: #5C060D;*/
        /*background: linear-gradient( #5C060D 9.16%, #A20916 43.89%, #cd6060 64.72%);*/
        background-color: #A30717;
        /*height: calc(100vh - 50px);*/
        /*color: #fffffb;*/
        height: 750px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-selling-point-container {
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background-color: #5C060D;*/
        padding-bottom: 40px;
        padding-top: 40px;
        background-color: #AF0915;
        /*height: calc(100vh - 50px);*/
        height: 100%;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .faq-container {
        /*background: url(../assets/library_003.jpeg) center center/cover no-repeat;*/
        background-color: #5C060D;
        /*background-color: #ffcccb;*/
        /*height: calc(100vh - 50px);*/
        height: 750px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);*/
        /*object-fit: contain;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .mobile-faq-container {
        /*background: url(../assets/library_003.jpeg) center center/cover no-repeat;*/
        background-color: #A20916;
        /*height: calc(100vh - 50px);*/
        height: 500px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);*/
        /*object-fit: contain;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }


    .center-horizontally {
        display: flex;
        flex-direction: column;
        /*justify-content: left;*/
        align-items: center;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-large {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }

    /*.center-horizontally {*/
    /*    text-align: center;*/
    /*    margin: auto;*/
    /*}*/

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-large {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }

    .anywhere-section {
        background-color: #F7F8FA;
        color: #5C060D;
    }

    .faq-section {


    }

    .slogan-text {
        font-size: 40px;
    }

    .main-color {
        color: #5C060D
    }

    .top-class-tutor-section {
        background-color: #ffffff;
        color: #000000;
    }

    .smart-matching-section {
        background-color: #F7F8FA;
    }

    .circles-container {
        position: relative;
        overflow: hidden;
        height: 400px;
        max-width: 500px;
    }

    .card-transparent {
        background-color: transparent;
    }

    #circle-one-outer {
        position: absolute;
        top: 0;
        left: 50%;
    }

    #circle-one-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 32px;
        font-weight: 500;
        border-radius: 50%;
        width: 270px;
        height: 270px;
        z-index: 2;
        background-color: #ffffff;
    }

    #circle-two-outer {
        position: absolute;
        bottom: 0;
        left: 25%;
    }

    #circle-two-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 26px;
        font-weight: 500;
        border-radius: 50%;
        width: 230px;
        height: 230px;
        z-index: 1;
        background-color: #ffffff;
    }

    #circle-three-outer {
        position: absolute;
        bottom: 5%;
        left: 75%;
    }

    #circle-three-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 22px;
        font-weight: 500;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        z-index: 3;
        background-color: #ffffff;
    }

    .home-text {
        /*font-family: HallymGothic-Regular;*/
        font-size: 42px;
        text-align: left;
        font-weight: bolder;
        /*text-shadow: 3px 3px 3px #0D0D0D;*/
        color: #000000;
    }

    .home-btn {
        color: #3c4043;
    }

    .mobile-home-text {
        font-size: 28px;
        line-height: 130%;
        text-align: center;
        color: #A20916;
    }

    @media (min-width: 1904px) {
        .new-container {
            max-width: 1440px;
        }
    }

    .button-85 {
        padding: 0.6em 2em;
        border: none;
        outline: none;
        color: rgb(255, 255, 255);
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: 30px;
        height: 50px;
    }

    .button-85:before {
        content: "";
        background: linear-gradient(
                45deg,
                #ff0000,
                #ff7300,
                #fffb00,
                #48ff00,
                #00ffd5,
                #002bff,
                #7a00ff,
                #ff00c8,
                #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-button-85 20s linear infinite;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
    }

    @keyframes glowing-button-85 {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }

    .button-85:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #A20916;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    .tablet-img {
        /*    !*background-color: #000000;*!*/
        /*    !*border: 1px solid #fffffb;*!*/
        /*    border-radius: 1px;*/
        /*    border: 1px solid #000000;*/
        /*    !*padding: 20px;*!*/
        /*    !*width: 200px;*!*/
        height: 100%;
    }

    .register-btn {
        border: 1px solid #fffffb;
        border-radius: 10px;
        border: 15px solid #000000;
        /*box-shadow: inset 0px 0px 0px 1000px #A20916;*/
    }

    .mobile-hero-background {
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        background-color: #fffffb;
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
    .ivy-ed-font {
        font-family: 'Montserrat', sans-serif;
        letter-spacing: -2px;
        font-size: 21px;
        color: #1F383A;
    }

    .align-right {
        text-align: right;
    }

    /*.mobile-hero-text { }*/

    .sub-desc-font {
        color: #A20916;
        font-size: 20px;
        font-weight: normal;
    }

    .main-desc-font {
        color: #253523;
    }

    .color-1 {
        color: #290F29;

    }

    .color-2 {
        color: #75363B;
        /*color: #1D3638;*/

    }

    .main-text-color {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 40px;
        /*color: #75363B;*/
        color: #fffffb;
        text-shadow: 2px 2px 5px #5C060D;

    }

    .mobile-sub-text {
        font-weight: normal;
    }

    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }

    .paragraph-font {
        font-size: 23px;
        font-weight: bold;
    }

    .justify-right {
        text-align: end;
    }

    .justify-left {
        text-align: left;
    }

    .grayish-color {
        background-color: #F7F8FA;
        color: #F7F8FA;
    }

    .teacher-section-title {
        font-size: 22px;
        font-weight: bold;
    }

    .rounded-card {
        border-radius: 100px;
    }

    .icon-style {
        text-shadow: 1px 1px 1.5px #5C060D;
    }

    .faq-section {
        background-color: #F3E5E5;
    }

    .home-background {
    }

    /*.v-carousel__next > button,*/
    /*.v-carousel__prev > button {*/
    /*    color: transparent !important;*/
    /*    background-color: transparent !important;*/
    /*}*/

</style>
