<template>
    <div>
        <v-card class="pa-5">
            <v-card-title class="font-weight-bold text-h5">
                Digital SAT - Overall Changes
            </v-card-title>
            <v-card-text style="font-size: 17px; line-height: 30px">
                오늘은 중고등학생들이 꼭 인지해야하는 Digital SAT 개편에 대해서 이야기하도록 하겠습니다.
                <v-divider class="my-7"></v-divider>
                <h3>일정</h3>
                <br/>
                <v-img class="my-4" :src="'https://i.postimg.cc/mZdqpWBt/digitalsat.png'"></v-img>


                <h4 style="font-weight: normal">
                    SAT US 시험은 2024년 봄학기부터, International은 2023년 봄학기부터 전면 교체됩니다.
                    <br/>
                    <br/>
                    왜 International 먼저 바뀌는지 궁금해하실 수 있는데 Collegeboard에 의하면 International을 먼저 서둘리 진행하는 것은 US 시험 일정과 동일한 연
                    7회의 시험을 제공하기 위함이라고 합니다.
                </h4>
                <br/>
                <div class="font-italic">
                    "We’re launching the digital SAT internationally first because going digital will allow us to offer
                    two additional international SAT administrations starting when we launch in 2023. Moving from five
                    to seven administrations to match the domestic calendar is an important way to expand access for
                    students around the world."

                    <div class="text-right font-weight-bold">CollegeBoard</div>
                </div>
                <br/>
                <br/>
                당장 내년부터 바뀌는 SAT 어떤 것들이 바뀌는지 좀 더 자세히 알아보겠습니다.
                <v-divider class="my-4"></v-divider>
                <v-img :src="'https://i.postimg.cc/FHPVNNCL/digitalsat2.png'"></v-img>
                <br/>

                <h2>Overall Changes</h2>
                <div class="my-4"></div>
                <h4>시험 시간이 단축됩니다.</h4>
                <h4 style="font-weight: normal">
                    기존 3시간에서 2시간14분으로 대폭 줄었습니다. 아무래도 시간이 단축되면서 학생들은 피로감을 덜 느끼며 끝가지 집중할 수 있는 환경이 조성될 것 같아 기대되는 변화입니다. 시험
                    기간을 단축시킬 수 있는 가장 큰 이유로는 학생의 정답률에 따라 난이도가 변하는 Adaptive Testing 방식이어서 가능한데 이것은 아래에 좀더 상세하게 다루도록 하겠습니다.
                </h4>
                <br/>
                <h4>문제풀이 시간이 더 증가합니다.</h4>
                <h4 style="font-weight: normal">
                    기존 Paper based SAT 영어 부분 Reating & Writing 섹션은 96문제를 100분동안 풀게되어 평균 문제당 63초가 주어지는 반면 Digital SAT는
                    54문제와 64분이 주어져 문제당 71초 요구됩니다.
                    <br/>
                    <br/>
                    문제당 8초의 풀이 시간이 더 주어지게 된것으로 문제풀이 속도보다는 능력/정확성에 좀더 치우친 평가가 이루어집니다.
                    <br/>
                    <br/>
                    <div class="font-italic">
                        "Test takers have more time, on average, to answer each question, meaning that, more so than
                        ever before, the digital SAT Suite tests are measures of students’ skills and knowledge, not
                        test-taking speed"

                        <div class="text-right my-3">College Board</div>

                    </div>
                </h4>
                <br/>
                <h4>학생별로 Unique한 시험 문제</h4>
                <h4 style="font-weight: normal">
                    디지털로 시험 기반으로 개개인의 시험이 유사하지만 조금씩 다르게 출제됩니다. SAT 문제 유출 및 컨닝 등의 부정행위로부터 좀더 안전한 시험 시스템으로 변하게 되는 것 입니다.
                    <br/>
                    <br/>
                    또한 이번 개편에 가장 핵심인 Multistage Adaptive Testing (MST) 방식으로 구현되어 학생들의 실력에 맞춤 문제들이 출제될 예정입니다.

                </h4>
                <v-img :src="'https://i.postimg.cc/HxWm504K/digitalsat3.png'" class="my-4"></v-img>
                <h4 class="font-weight-light">
                    Reading & Writing (RW) 와 Math 섹션 각각 두개의 모듈로 나뉘며 첫째 모듈은 상중하 난이도의 문제들이 고루 분포되어있는 반면 두번째 모듈은 첫째 모듈의 성적에
                    따라서 난이도가 전체적으로 상향/하향 조절되어 좀더 효율적으로 학생들의 성적을 산출해내는 것입니다. 단순하게 생각한다면 1번 모듈에서는 대략적인 점수 레인지로 구분되고 2번
                    모듈에서는 해당 레인지안에서의 세부 점수를 산출하는 방식 정도로 이해하면 될 것 같습니다.
                    <br/>
                    <br/>
                    Adaptive test라서 모르는 문제를 skip 하고 다시 와서 풀수는 없는지 궁금해하실수 있는데 각각 모듈안에서는 자유롭게 문제를 skip하고 다시 돌아와서 풀수 있게 되어있다고하니 무조건 순서대로 풀어야 하는 것은 아닌 점 참고하시면 될 것 같습니다.
                </h4>
                <v-divider class="my-4"></v-divider>
                <h4 class="my-3 font-weight-light">
                    각 섹션 별 문제 유형도 조금 변경되었는데 이부분은 다음 포스트에서 상세하게 이야기하도록 하겠습니다.
                </h4>
                <br/>
                <br/>
            </v-card-text>

            <v-card-text style="line-height: 30px">

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "EAvsED"
    }
</script>

<style scoped>

</style>
