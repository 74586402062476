<template >
    <div class="center-horizontal">

    <v-card width="500px" flat class="">

        <div class="text-right">
            <v-btn
                    class="text-right ma-2"
                    icon
                    small
                    dark
                    color="primary"
                    @click="goBackOnePage"
            >
                <v-icon>mdi-close</v-icon>

            </v-btn>
        </div>
<!--        <div class="mx-5 pb-5 mt-3">-->
<!--&lt;!&ndash;            <h2 class="black&#45;&#45;text ">&ndash;&gt;-->
<!--&lt;!&ndash;            </h2>&ndash;&gt;-->
<!--&lt;!&ndash;            <v-btn target="_blank" text x-large width="250px" :href="'mailto: support@ivyed.co.kr'" color="" elevation="0" class="rounded-xl text-center my-5 black&#45;&#45;text">&ndash;&gt;-->
<!--&lt;!&ndash;                <v-icon color="" size="40">mdi-email&ndash;&gt;-->
<!--&lt;!&ndash;                </v-icon> &nbsp; Email&ndash;&gt;-->
<!--&lt;!&ndash;            </v-btn>&ndash;&gt;-->

<!--        </div>-->
        <div class="mx-5 pb-5 mt-3">
            <h2 class="black--text ">
                Contact Us
            </h2>
            <div style="font-size: 17px">
                Interested in learning more about IVYED and our programs?
<!--                For any inquiry, please leave your number with a country code.-->
                <br/>
                <div class="mt-2">
<!--                    A counselor will reach out for consultation within 1~2 business days at your timezone.-->
<!--                상담 후 <strong>체험 수업이 무료로 진행</strong>됩니다.-->
                </div>
            </div>
        </div>
        <v-divider class="mx-5"></v-divider>
        <template>
            <v-form v-model="valid" class="mx-5">
                <v-container>
                    <v-row >
                        <v-col
                                cols="12"
                                md="12"
                        >
                            <div style="font-size: 14px; color: #757575; font-weight: bold"
                                 class="mb-2">
                                Full Name (Parent or Student) *
                            </div>
                            <v-text-field
                                    dense
                                    outlined
                                    label="Please enter your full name"
                                    v-model="parentName"
                                    :rules="[rules.required]"
                                    hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                md="12"
                        >
                            <div style="font-size: 14px; color: #757575; font-weight: bold"
                                 class="mb-2">
                                Email *
                            </div>
                            <v-row no-gutters justify="center" align="center" class=" text-h4">
<!--                                <v-col-->
<!--                                        cols="12"-->
<!--                                        md="4"-->
<!--                                        sm="4"-->
<!--                                >-->
<!--                                    <v-autocomplete-->
<!--                                            outlined-->
<!--                                            label="Country Code"-->
<!--                                            required-->
<!--                                            :rules="[rules.required]"-->

<!--                                            :items="countryCodes"-->
<!--                                            v-model="parentCountryCode"-->
<!--                                            class="font-weight-thin"-->
<!--                                            dense-->
<!--                                            :messages="['Country Code']"-->
<!--                                            auto-select-first-->

<!--                                    >-->
<!--                                    </v-autocomplete>-->
<!--                                </v-col>-->
                                <v-col
                                        cols="12"
                                        md="8"
                                        sm="8"
                                >
                                    <v-text-field
                                            outlined
                                            label="Email Address *"
                                            required
                                            :rules="[rules.required, rules.email]"
                                            v-model="parentPhone"
                                            dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                                cols="12"
                                md="12"
                                style="margin-top: -20px"
                        >
                            <div style="font-size: 14px; color: #757575; font-weight: bold"
                                 class="mb-2">
                                Message *
                            </div>
<!--                            <div style="font-size: 12px; font-weight: bold" class="secondary&#45;&#45;text">-->
<!--&lt;!&ndash;                                * 2 business days &ndash;&gt;-->
<!--                            </div>-->
                            <v-textarea
                                    outlined
                                    placeholder="Message / Inquiry *"
                                    v-model="parentLocationAndTime"
                                    required
                                    :rules="[rules.required]"
                            ></v-textarea>
                        </v-col>
<!--                        <v-col-->
<!--                                cols="12"-->
<!--                                md="12"-->
<!--                                style="margin-top: -20px"-->
<!--                        >-->
<!--                            <div style="font-size: 14px; color: #757575; font-weight: bold"-->
<!--                                 class="mb-2">-->
<!--                                Current Grade-->
<!--                            </div>-->
<!--                            <v-select-->
<!--                                    dense-->
<!--                                    outlined-->
<!--                                    required-->
<!--                                    label="As of 2022"-->
<!--                                    :items="grades"-->
<!--                                    :rules="[rules.required]"-->
<!--                                    v-model="studentGrade"-->
<!--                            ></v-select>-->
<!--                        </v-col>-->
                    </v-row>
                    <div class="my-7"></div>
                    <v-btn :disabled="!valid" large width="100%" @click="sendEmail" class="small-button rounded-lg" height="50px" text elevation="0"

                    >
<!--                    <v-btn large width="100%" @click="snackbar=true" class="small-button rounded-lg" height="50px" text elevation="0" >-->
                        <h2 style="font-weight: bold; color: white; letter-spacing: -1px !important;">
                        Submit
                        </h2>
                    </v-btn>
                    <v-snackbar
                            v-model="snackbar"
                            class="text-center"
                            top
                    >
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn
                                    color="pink"
                                    text
                                    v-bind="attrs"
                                    @click="snackbar = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>

                </v-container>
            </v-form>
        </template>
    </v-card>
    </div>
</template>

<script>
    import info from "../../../info";
    import config from "../../../config";
    import emailjs from "emailjs-com";

    export default {
        name: "AppointmentDialog",
        data() {
            return {
                countryCodes: info.countryCodes,
                grades : info.newGradesList,
                parentName: '',
                parentCountryCode: '',
                parentPhone: '',
                parentLocationAndTime: '',
                studentGrade: '',
                snackbar: false,
                text: `예약이 접수 되었습니다.`,
                valid: false,

                rules: {
                    required: (value) => !!value || '필수 입력',
                    email: (value) => {
                        const pattern =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address.';
                    },
                    isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => (value.length == 11) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    isValidPhoneNumber: (value) => (value.match(/^[0-9]+$/)) || '전화번호는 \'-\' 없이 숫자로 입력 해주세요',
                    // isValidBirthdate: (value) => (value.length == 8) || '생년월일은 8자리 숫자로 입력 해주세요',
                    // isValidPhoneNumber: (value) => isNaN(value) || '핸드폰 번호는 '-' 없이 11자리 숫자로 입력 해주세요',
                    isAYear: (value) => (value >= 19400101 && value <= 20251231 && value.length == 8) || '생일은 8자리 숫자 및 1940 - 2022년만 유효합니다. ',
                    hourlyRate: (value) => (value >= 10 && value <= 2000) || 'Must Be between 10 and 2000',
                    biggerthanzero: (value) => value > 0 || 'Can not work less than 1 month',
                    overviewLength: (v) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        (v.length >= 50 && v.length <= 5000) || 'Min 50 characters, Max 5000 characters',
                    emptyArray: (v) => v.length > 0 || 'Min One Skill is needed',
                    passwordMatch: (v1, v2) => v1 === v2 || '비밀번호가 일치하지 않습니다.',
                    MinPassLen: (v) => v.length >= 8 || '8자 이상의 비밀번호만 허용 됩니다.',
                },
            }
        },
        methods: {
            goBackOnePage() {
                this.$router.push("/");
            },
            sendEmail() {
                this.email = "학부모님 성함: " + this.parentName + ", " + "휴대전화: " + this.parentCountryCode + " " + this.parentPhone + ", " + " 희망 상담시간: " + this.parentLocationAndTime + ", 학년: " + this.studentGrade;

                var obj = {
                    user_email: "상담 신청 from " + this.parentName,
                    from_name: this.parentName + ": " + this.parentCountryCode + " " + this.parentPhone,
                    message_html: this.email,
                    to_name: "IVYED",
                };

                emailjs
                    .send(
                        config.emailjs.serviceID,
                        config.emailjs.templateID,
                        obj,
                        config.emailjs.userID
                    )
                    .then(
                        // eslint-disable-next-line no-unused-vars
                        (result) => {
                            this.snackbar = true;
                            this.$router.push('/profile')
                            this.email = "";
                            this.text = "";
                            this.name = "";
                        },
                        // eslint-disable-next-line no-unused-vars
                        (error) => {
                            this.showSnackbar = true;
                            this.snackbarMessage = "Oops! Something went wrong.";
                            this.snackbarColor = "#64808E";
                        }
                    );
            }

        }
    }
</script>

<style scoped>

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .small-button {
        /*box-shadow: 1px 1px 1px 1px;*/
        /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
        /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
        /*box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;*/
        /*position: fixed;*/
        /*bottom: 10px;*/
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        /*background-color: #DE2828;*/
        /*border-radius: 15px;*/
        /*!*border-radius: 8px;*!*/
        /*!*border-style: none;*!*/
        /*!*box-sizing: border-box;*!*/
        /*color: #FFFFFF;*/
        /*cursor: pointer;*/
        /*!*flex-shrink: 0;*!*/
        /*!*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*!*/
        /*!*font-family: 'SuncheonB';*!*/
        /*!*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*!*/
        /*font-size: 15px;*/
        /*height: 50px;*/
        /*width: 120%px;*/
        /*!*width: 100%;*!*/
        /*font-weight: 500;*/
    }


</style>
