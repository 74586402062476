<template>
    <!--------------------------------->
    <!-------- Browser Version -------->
    <!--------------------------------->


    <!--            <div class="d-flex">-->
<!--                <v-checkbox-->
<!--                        v-model="disabled"-->
<!--                        label="Disabled"-->
<!--                ></v-checkbox>-->
<!--            </div>-->
    <div>

        <v-card width="350" elevation="0" color="#F3E5E5" class="pb-12">
            <div class="justify-center">
<!--            <p class="sub-main-01 main-color">FAQ</p>-->
            <p class="sub-main-01 main-color pb-10">FAQ</p>
            </div>
            <FaqTemplate></FaqTemplate>
        </v-card>
    </div>

</template>
<script>

    import info from "../../../info";
    import FaqTemplate from "./FaqTemplate";

    export default {
        name: 'FAQ',
        components: {FaqTemplate},
        data() {
            return {
                tab: null,
                // items: [
                //     '학생', '선생님', '결제', '화이트보드', '수업문의',
                // ],
                faqTitles: info.faq,
                faqContent: info.faqContent,
                panel: [0, 1],
                disabled: false,
                readonly: false,
            }
        },
    }
</script>
<style scoped>
    .faq-style {
        /*background: url(../../assets/library_cornell_001_dark.png) center center/cover no-repeat;*/
    }
    .container {
        margin: 0 auto;
        max-width: 600px;
        padding: 1rem;
    }

    .justify-center {
        text-align: center;
    }
    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }
    .question-font {
        font-size: 17px;
    }
    .test {
        /*box-shadow: 0px -2px 2px rgba(34, 34, 34, 0.6);*/

    }
</style>
