<template>
<img src="../../assets/KakaoTalk_logo.svg">
</template>

<script>
    export default {
        name: "KakaoIcon"
    }
</script>

<style scoped>

</style>
