<template>
    <v-container fluid style="background-color: #EFF0F1; height: 100%">
        <div class="container">
            <!--            <div class="text-center my-7">-->
            <!--            <h2>IVYED 블로그</h2>-->
            <!--            </div>-->
            <v-chip-group class="mb-4">
                <v-chip @click="collegeInfo=true" class=""
                        style="background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                    입시정보
                </v-chip>
                <v-chip @click="showStudyMaterial" class=""
                        style="background: blue; background: linear-gradient(to bottom, blue, darkblue); color: #fafafa;">
                    시험자료
                </v-chip>
            </v-chip-group>
            <div v-if="collegeInfo">

                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/bNd1rTGB/App-Essay-Part2-1.jpg'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                Application Essay 어떻게 준비해야 할까? (Part 2)
                                <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">오늘은 지난시간에 이어 미국 대학 입시에 필수인 Application Essay를 어떻게 준비해야좋을지 다뤄 보겠습니다. </v-list-item-subtitle>
                            <div class="mt-1"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/8">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>

                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://blog.collegevine.com/wp-content/uploads/2016/08/college-applications-components.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                Application Essay가 미국 상위권 대학 입시에 중요한 이유 (Part 1)
                                <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">오늘은 미국 대학을 지원하는데 있어 한국과는 다르게 Application Essay / Personal Statement 가 얼마나 중요하고 왜 입학사정처에서 무게있게 다루는지를 알아보겠습니다.</v-list-item-subtitle>
                            <div class="mt-1"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/7">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>

                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/QCt64xXJ/digitalsat4.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                Digital SAT - Section Changes
                                <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">지난 시간 Digital SAT Overall Changes에 이어 오늘은 각 섹션별로 변경/유의해야할 점들을 소개해 드리겠습니다.</v-list-item-subtitle>
                            <div class="mt-4"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/5">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/mZdqpWBt/digitalsat.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                Digital SAT - Overall Changes<br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">오늘은 중고등학생들이 꼭 인지해야하는 Digital SAT 개편에 대해서 이야기하도록 하겠습니다.</v-list-item-subtitle>
                            <div class="mt-4"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/4">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/tTztVT0w/Screen-Shot-2022-08-01-at-10-13-50-AM.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                2022/23 SAT 꼭 봐야할까? <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">코로나 시대를 맞이하며 상위권 대학을 포함한 많은 대학교들이 SAT 점수를 더
                                이상 필수로 요구하지 않고 있습니다.
                            </v-list-item-subtitle>
                            <div class="mt-4"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/1">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/TPnJgjQS/EA-ED.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                Early Action vs. Early Decision <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">대학 입시 준비할 때 고려 할 것이 정말 많습니다. 저 또한 고민을 많이
                                했었구요...
                                그 중에서도 오늘은 rising senior 들이 많이 고민하는 Early Action (EA) Early Decision (ED)에 관해서
                                이야기해보겠습니다.
                            </v-list-item-subtitle>
                            <div class="mt-4"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/3">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
                <div class="my-6"></div>
                <v-card
                        class="mx-auto rounded-xl"
                        elevation="5"
                        height="200"
                >

                    <v-list-item three-line class="pa-2">
                        <v-list-item-avatar
                                tile
                                size="90"
                                color="grey"
                                class="mr-4 ml-1"
                        >
                            <v-img :src="'https://i.postimg.cc/NfYSM47K/apcalculus.png'"></v-img>

                        </v-list-item-avatar>

                        <v-list-item-content>
                            <div class="font-weight-bold mt-3 mb-5">
                                AP Calculus AB와 BC의 차이 <br/>
                                <v-chip class="mt-2" small
                                        style="font-weight: normal !important; font-size: 12px !important; background: #cb2d3e; background: linear-gradient(to bottom, #ef473a, #cb2d3e); color: #fafafa;">
                                    입시정보
                                </v-chip>

                            </div>
                            <v-list-item-subtitle style="font-size: 12px;">오늘은 학생들이 가장 많이 듣는 AP Calculus에 대해서 좀 더
                                알아보겠습니다.
                            </v-list-item-subtitle>
                            <div class="mt-4"></div>
                            <v-list-item-subtitle style="font-size: 13px;"><a style="text-decoration: none !important;"
                                                                              href="/blog/2">더보기</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>

                </v-card>
            </div>
            <div v-if="studyMaterial=true">
                <v-snackbar
                        top
                        v-model="snackbar"
                >
                    {{ studyMaterialMessage }}

                    <!--                    <template v-slot:action="{ attrs }">-->
                    <!--                        <v-btn-->
                    <!--                                color="pink"-->
                    <!--                                text-->
                    <!--                                v-bind="attrs"-->
                    <!--                                @click="snackbar = false"-->
                    <!--                        >-->
                    <!--                            Close-->
                    <!--                        </v-btn>-->
                    <!--                    </template>-->
                </v-snackbar>
            </div>

        </div>
    </v-container>
</template>

<script>
    export default {
        name: "Blog",
        data() {
            return {
                snackbar: false,
                collegeInfo: true,
                studyMaterial: false,
                studyMaterialMessage: '',
            }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            showStudyMaterial() {
                this.collegeInfo = false
                this.studyMaterial = true;
                if (!this.currentUser) {
                    this.snackbar = true;
                    this.studyMaterialMessage = "회원전용 컨텐츠입니다. 로그인을 해주세요."
                    this.collegeInfo = true;
                    this.studyMaterial = false;
                    // setTimeout(() => {this.$router.push("/login")}, 1000)
                }

            }
        }
    }
</script>

<style scoped>
    /*@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");*/

    /**,*/
    /**::before,*/
    /**::after {*/
    /*    box-sizing: border-box;*/
    /*    padding: 0;*/
    /*    margin: 0;*/
    /*}*/

    /*body {*/
    /*    font-family: "Quicksand", sans-serif;*/
    /*    display: grid;*/
    /*    place-items: center;*/
    /*    height: 100vh;*/
    /*    background: #7f7fd5;*/
    /*    background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);*/
    /*}*/

    /*.container {*/
    /*    display: flex;*/
    /*    flex-wrap: wrap;*/
    /*    justify-content: center;*/
    /*    max-width: 1200px;*/
    /*    margin-block: 2rem;*/
    /*    gap: 2rem;*/
    /*}*/

    /*img {*/
    /*    max-width: 100%;*/
    /*    display: block;*/
    /*    object-fit: cover;*/
    /*}*/

    /*.card {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    width: clamp(20rem, calc(20rem + 2vw), 22rem);*/
    /*    overflow: hidden;*/
    /*    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);*/
    /*    border-radius: 1em;*/
    /*    background: #ECE9E6;*/
    /*    background: linear-gradient(to right, #FFFFFF, #ECE9E6);*/

    /*}*/


    /*.card__body {*/
    /*    padding: 1rem;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    gap: .5rem;*/
    /*}*/


    .tag {
        align-self: flex-start;
        padding: .25em .75em;
        border-radius: 1em;
        font-size: .75rem;
        /*width: 10px;*/
    }

    .tag + .tag {
        margin-left: .5em;
    }

    .tag-blue {
        background: #56CCF2;
        background: linear-gradient(to bottom, #2F80ED, #56CCF2);
        color: #fafafa;
    }

    .tag-brown {
        background: #D1913C;
        background: linear-gradient(to bottom, #FFD194, #D1913C);
        color: #fafafa;
    }

    .tag-red {
        background: #cb2d3e;
        background: linear-gradient(to bottom, #ef473a, #cb2d3e);
        color: #fafafa;
    }

    /*.card__body h4 {*/
    /*    font-size: 1.5rem;*/
    /*    text-transform: capitalize;*/
    /*}*/

    /*.card__footer {*/
    /*    display: flex;*/
    /*    padding: 1rem;*/
    /*    margin-top: auto;*/
    /*}*/

    /*.user {*/
    /*    display: flex;*/
    /*    gap: .5rem;*/
    /*}*/

    /*.user__image {*/
    /*    border-radius: 50%;*/
    /*}*/

    /*.user__info > small {*/
    /*    color: #666;*/
    /*}*/
</style>
