<template>
    <div>
        <v-card class="pa-5">
            <v-card-title class="font-weight-bold text-h5">
                Application Essay / Personal Statement / SOP 어떻게 준비해야 할까? (Part 2)

            </v-card-title>
            <v-card-text style="font-size: 17px; line-height: 30px">
                <br/>
                오늘은 지난시간에 이어 미국 대학 입시에 필수인 Application Essay를 어떻게 준비해야좋을지 다뤄 보겠습니다.
                <br/>
                <br/>
                지난시간에도 얘기 했지만, 많은 학생들이 Application Essay 를 내 지식을 뽐내는 혹은 지금까지 외웠던 고급 어휘들을 마음껏 쓰는 공간이라고 생각하여 정말 중요한 "내용
                (content)"에 집중하기 보단 겉으로 보기에 글이 화려한가? 에 집중하는 경우들이 많습니다.
                <br/>
                <br/>
                <v-img class="my-4"
                       :src="'https://i.postimg.cc/bNd1rTGB/App-Essay-Part2-1.jpg'"></v-img>
                <br/>
                "그럼 대체 어떻게 College Application Essay를 써야한다는거야?" 라고 물으실 수 있는데요. 우선 입시원서에 Application Essay가 입학사정관의 눈에 어떤
                역할을 하는지 얘기 해보겠습니다.
                <br/>
                <br/>
                미국 입시원서는 생각보다 정교하고 다양한 시각에서 holistic한 평가를 할 수 있게 설계 되어 있습니다.
                <br/>
                <br/>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-center">
                                Application Requirements
                            </th>
                            <th class="text-center">
                                From Whom?
                            </th>
                        </tr>
                        </thead>
                        <div class="py-1"></div>
                        <tbody>
                        <tr>
                            <td>Standardized Exams (SAT 등)</td>
                            <td>객관적 (모든 학생들이 같은 기준으로 평가 됨)</td>
                        </tr>
                        <div class="py-1"></div>
                        <tr>
                            <td>Recommenation Letters</td>
                            <td>선생님들 및 학생의 교과활동을 가장 가까이서 지켜본 사람들의 관점</td>
                        </tr>
                        <div class="py-1"></div>
                        <tr>
                            <td><span class="text-decoration-underline">Application Essay</span></td>
                            <td>내 자신의 관점</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <br/>
                위 표에 보여지는 것과 같이, 미국 대학 원서는 모든 관점에서 평가를 하려 설계 되었습니다.
                <br/>
                <div class="py-3"></div>
                1. 객관적 평가 <br/>
                2. 주변인의 평가 <br/>
                3. <span class="font-weight-bold">학생 자신</span>으로 부터의 본인 평가 <br/>
                <br/>
                다시 말해, 학생에 관해 정말 제대로 짧은 시간내에 알고 싶고, 혹시나 학생에 대해 놓치는 부분이 없기 위해 이렇게 다양한 서류들을 요구 하고 있는거죠.

                <v-divider class="my-5"></v-divider>
                <h3>We want to get to know you!</h3>
                <br/>

                <h4 style="font-weight: normal">
                    입학사정관은 Application Essay 라는 글을 통해 학생에 대해 정말 알고 싶어 해요. 해마다 몇만건씩의 입시원서를 리뷰해야하는 입학사정관들은 시험 점수와 추천서만으로는
                    정말 어떤 학생이 우리학교와 잘 맞을지 평가하기 힘들거든요.
                    <br/>
                    <v-img class="my-4"
                           :src="'https://quizizz.com/media/resource/gs/quizizz-media/quizzes/3bb77982-0eef-4301-9bdf-cf1672c59a00'"></v-img>

                    <br/>
                    당연한 얘기겠지만, 학생의 성격, 성향, 경험, 환경, 생각 등은 SAT 점수를 통해 알 수가 없습니다.
                    <br/>
                    <br/>
                    예를 들면 "이 학생은 SAT Score가 높으니 우리학교 문화에 잘 적응할거야. 주변 친구들에게도 좋은 학우가 될거고." 라는 평가를 내리기가 쉽지 않겠죠?

                </h4>
                <v-divider class="my-5"></v-divider>

                <h3>그럼 대체 뭘 적어야 할까?</h3>
                <br/>
                <h4 style="font-weight: normal">
                    위에 언급하였듯, Application Essay 는 입시원서 중 유일하게 학교측에 "내 자신의 목소리"를 낼 수 있는 공간입니다. 그렇기 때문에 없는 이야기를 꾸며내서는 좋은
                    글이 나오지 못해요.
                    <br/>
                    <v-img class="my-4"
                           :src="'https://boyo.nyc3.digitaloceanspaces.com/wp-content/uploads/2019/11/what_to_write_about.jpg'"></v-img>

                    핵심은 바로 <span class="font-weight-bold text-decoration-underline">내 자신 (Personal)</span> 에 대해 이야기 하는 것
                    입니다. 학교에서 가장 친했던 친구와 나는 비슷한 부분도 많겠지만, 분명 모든 사람은 각자마다 생각부터, 성격, 경험, 성향 등이 모두 다르거든요.
                    <br/>
                    <br/>
                    가령 어떤 Essay topic 이 학생들의 의견을 묻는다면, 주장을 펼치기 위해 없는 이야기로 그 주장을 펼치지 마시고, 자신의 가치관을 뚜렷하게 설명하고, 그 가치관이 어떻게
                    생기게 됐는지를 상세하게 설명 해주시는게 좋아요.
                    <br/>
                    <br/>
                    Common App 에세이 토픽 중 하나인 아래 토픽으로 예를 들어보겠습니다.
                    <br/>
                    <br/>
                    <div class="font-italic">"Describe a topic, idea, or concept you find so engaging that it makes you
                        lose all track of time. Why does it captivate you? What or who do you turn to when you want to
                        learn more?"
                    </div>
                    <div class="py-3"></div>
                    <div class="font-italic">"학생을 시간이 가는지도 모르게 만드는 topic, idea, 혹은 concept이 있나요? 왜 그런가요? 만약 더 해당 부분에 대해
                        알고 싶을때 어떻게, 혹은 누구를 찾나요?"
                    </div>

                    <br/>
                    이런 토픽으로 에세이를 쓴다고 할때 바로 에세이를 쓰시지 마시고 시간을 갖고 한번 곰곰히 생각해보세요. 또한 무조건적으로 학구적인 스토리로 적으려 없는 이야기를 지어내서는 안돼요.
                    그럼 자연스레 좋은 글이 나올수가 없기 때문이죠.
                    <br/>
                    <br/>
                    예를들어 학생이 게임을 좋아하는데, 게임을 하다보면 자꾸 이런 게임은 누가 제작했고 또 어떻게 만들어졌는지, 또 왜 만들었는지 끊임없이 생각하게 된다면 해당 내용으로 에세이를
                    적으세요.
                    <br/>
                    <br/>
                    종종 "이 얘기하면 내가 안좋게 보이는거 아닐까?" 라는 생각으로 진짜 자신의 모습을 숨기고 꾸며낸 자신을 Application Essay에 적으려는 경우들이 있습니다.
                    <br/>
                    <br/>
                    게임 이야기하면 학교에서 난 공부 안하고 게임만 한다고 생각하는거 아니야?라고 생각 하실수도 있는데요, 그렇지 않습니다. 학생의 학업적 평가는 Standardized Exam
                    Score들과 Recommendation Letters를 통해 이미 거쳤기 때문이죠.
                    <br/>
                    <br/>
                    그렇다고 게임 이야기를 쓰라는게 아니고, 진솔된 본인을 에세이를 통해 보여 주는게 핵심입니다.
                </h4>
                <v-divider class="my-5"></v-divider>
                <h3>구조 (Structure)</h3>
                <br/>
                <h4 style="font-weight: normal">
                    그럼 어떻게 글의 구조를 잡고 적어 나가야 하고 토픽 내 여러개의 질문중 어떤 질문에 본인의 주장을 펼쳐야 할까요?
                    <br/>
                    <v-img class="my-4"
                           :src="'https://s3.amazonaws.com/blog.site/files/0257_edubirdie_how%20to%20structure%20an%20essay%20%281%29_1615481080.png'"></v-img>
                    위 게임 예제로 이어 나가보겠습니다.
                    <br/>
                    <br/>
                    <div class="font-weight-bold">무엇이 시간 가는지 모르게 하나요?</div>
                    난 게임을 무척 좋아해 -> 게임이 어떻게 제작되는지 궁금해져서 관련해서 인터넷 검색을 해보고 연구를 하다보면 난 시간 가는지도 모르게 빠져있어
                    <br/>
                    <br/>
                    입학사정관이 단순 무엇이라는 질문에 대한 답을 듣고 싶어 하는게 아니고 왜? 라는 질문에 학생들이 (각자마다 다른) 어떻게 답을 하는지 듣고싶은거에요.
                    <br/>
                    <br/>
                    <div class="font-weight-bold">왜 게임이 어떻게 제작되어지는 것이 학생을 사로잡나요 (Why captivate) ?</div>
                    <div class="py-2"></div>
                    여기서 학생들만의 personal 한 답으로 주장을 펼치시면 돼요.
                    <br/>
                    <br/>
                    <div class="text-decoration-underline">Bad example</div>
                    <div class="font-italic">"그냥 게임이 재밌으니까"</div>
                    <br/>
                    <div class="text-decoration-underline">Good example</div>
                    <div class="font-italic">"생각해보니 난 늘 게임뿐 아닌, 무엇이 어떻게 만들어지는지에 대해 관심이 많았던 것 같아. 난 표면적으로 보여지는 것들의 기원이
                        궁금하고, 단순 제작뿐 아닌, 어떤 과학적 이론과 법칙으로 만들어지는를 궁금해 하는 것 같아. 비유하기 부끄럽지만 마치 뉴턴이 사과가 땅으로 떨어지는걸 보고 중력에 대해
                        연구했던 것 처럼 말야."
                    </div>
                    <br/>
                    위 같이 적었다면, 해당 답변이 에세이의 주장(argument)이 된다고 생각하시고 남은 에세이 공간에서 그 주장을 뒷받침하는 개인적 경험담 / 예제들을 적어주시면 됩니다.
                    <br/>
                    <br/>
                    그럼에도 더 글을 이어 나가기 힘든 경우가 있으실텐데, 놀랍게도 essay topic 내에서 그런 학생들을 돕기위해 친절하게 질문이 또 있습니다.

                    <br/>
                    <br/>
                    <div class="font-italic">"...What or who do you turn to when you want to learn more?"</div>
                    <br/>
                    <div class="text-decoration-underline">주장</div>
                    <div class="font-italic">"난 표면적으로 보여지는 것들의 기원이 궁금해."</div>
                    <br/>
                    <div class="text-decoration-underline">뒷받침</div>
                    <div class="font-italic">"한번은 자동차가 어떻게 만들어지는지 궁금해서 도서관에 가서 자동차의 기원에 관련된 책을 다 읽어봤지만, 정작 어떻게 만들어지는지를
                        이해하는데 도움이 되지 않았어.
                    </div>
                    <br/>
                    <div class="font-italic">그래서 자동차를 만드는데 필요한 구성요소들을 다 공부하고 부모님께 부탁해 자동차 공장 견학을 갔었지. 거기서는 공정과정부터 책에서만
                        듣던 용어들을 사용해가며 하나하나 설명 해줬어.
                    </div>
                    <br/>
                    <div class="font-italic">내가 책과 현장경험에서 배운걸 토대로 집에 돌아와 미니 자동차를 만들어 봤어, 엔진부터 제네레이터 등..."</div>
                    <br/>
                    이런식으로 자연스럽게 토픽에 주어진 질문을 답해가며 에세이를 발전시켜 나가시면 됩니다.
                </h4>
                <v-divider class="my-5"></v-divider>
                <h3>마치며</h3>
                <br/>
                <h4 style="font-weight: normal">
                    오늘은 지난시간에 이어 미국 입시시즌이 코 앞에 다가온 만큼 Application Essay 를 어떻게 써야 할까에 대해 다뤄 보았습니다.
                    <br/>
                    <br/>
                    많은 학생들이 좋은 Impression을 줄 수 있을까 하는 생각에 종종 이야기를 꾸며내는 경우를 봤는데요. 이야기를 꾸며내는게 법적이나 도덕적으로 문제는 없습니다.
                    <br/>
                    <br/>
                    다만 정말 좋은 글이 나오기가 굉장히 어려워요. 내 진짜 생각과 모습이 아니기 때문이죠.
                    <br/>
                    <br/>
                    입학사정처에서는 Application Essay 를 통해 학업 평가를 하지 않습니다. 이미 SAT, SAT2, AP Score들로 학업 평가는 마쳤기 때문이죠.
                    <br/>
                    <br/>
                    Application Essay를 통해 입학사정처에서 기대하는건 정말 학생에 대해 알아가고 이해하는 것이에요. 그러니 진솔된 내 생각과 내 경험을 가지고 에세이를 써 내려가는 것이 자연스레 좋은글이 나올 뿐 아닌 주변 경쟁 입시생과도 차별적인 에세이가 나올 확률이 굉장히 높습니다.
                    <br/>
                    <br/>
                    벌써 Early Decision / Action deadline 이 한달정도 밖에 남지 않았네요.
                    <br/>
                    <br/>
                    입시를 준비하시는 모든 학생 / 학부모님들 응원합니다.
                </h4>
                <br/>
            </v-card-text>

        </v-card>

    </div>
</template>

<script>
    export default {
        name: "AppEssayPart2"
    }
</script>

<style scoped>

</style>
