<template>
    <div>
        <v-card class="pa-5">
            <h1 class="font-weight-bold ">
                Digital SAT - Section Changes
            </h1>
            <div class="my-1"></div>
            <v-card-text style="font-size: 17px; line-height: 30px">
                지난 시간 Digital SAT Overall Changes에 이어 오늘은 각 섹션별로 변경/유의해야할 점들을 소개해 드리겠습니다.
                <div class="my-2"><a href="/blog/4"> 지난 포스트 다시보기 -></a></div>
                <v-divider class="my-7"></v-divider>
                <h2>Reading&Writing Changes</h2>
                <br/>
                <h3>Reading & Writing 섹션 병합</h3>

                <h4 style="font-weight: normal">
                    기존 Reading & Writing 각가의 섹션이 한개의 섹션으로 병합됩니다. Reading & Writing 포괄적으로 영어 언어 능력을 평가하겠다는 Collegeboard
                    취지인데 저희 입장에서는 좀더 전략적으로 시간 배분을 할 수 있게되었다고 받아들이면 될 것 같습니다. Writing 문제를 잘 푸는 학생들은 Reading 문제들에, 그리고
                    Reading 을 잘하는 학생들은 Writing 문제에 시간을 더 할해하며 종합 스코어를 올릴 수 있게 된 것입니다.

                </h4>
                <br/>
                <h3>다수의 짧은 지문들이 긴 지문들을 대체</h3>
                <h4 style="font-weight: normal">
                    지문들이 전체적으로 짧아지고 많아지면서 수험생들은 더 많고 다양한 주제의 지문들을 접하게됩니다.

                    각 지문당 문제는 한개씩밖에 출제되지 않게되어 기존 긴 지문 -> 여러 문제 형태의 유형들은 없어집니다.

                    아래 Collegeboard에서 출제된 예제들을 보시면 한눈에 변화를 체감하실 수 있을 것입니다.
                </h4>
                <br/>
                <v-img class="my-4" :src="'https://i.postimg.cc/QCt64xXJ/digitalsat4.png'"></v-img>
                <div class="text-center">
                    <v-card-subtitle>2022 SAT Practice Question - CollegeBoard</v-card-subtitle>
                </div>
                <v-img class="my-4" height="50%" :src="'https://i.postimg.cc/02NdvtKW/Digital-SAT5.png'"></v-img>
                <div class="text-center">
                    <v-card-subtitle>2022 SAT Practice Question #1- CollegeBoard</v-card-subtitle>
                </div>
                <v-img class="my-4" height="50%" :src="'https://i.postimg.cc/PxxYHJPL/Digital-SAT6.png'"></v-img>
                <div class="text-center">
                    <v-card-subtitle>2022 SAT Practice Question #2- CollegeBoard</v-card-subtitle>
                </div>

                <br/>
                <v-divider class="my-4"></v-divider>
                <h2>Math Changes</h2>
                <br/>
                <h3>모든 섹션 계산기 사용가능</h3>
                <h4 style="font-weight: normal">
                    계산기 없이 풀어야되는 섹션이 없어지면서 모든 수학 문제에 계산기 사용이 가능해졌습니다. 계산 실수를 줄일 수 있게되었으며 특히 계산기의 그래프 기능이나 근의공식을 잘 활용하여 보다 쉽고 빠르게 문제를 풀수도 있으실 겁니다.
                </h4>

                <h3>문장형 문제 "word problem" 들이 좀더 간략해집니다</h3>
                <h4 style="font-weight: normal">영어 능력에 따라서 수학 능력이 평가가 된다는 피드백을 반영해서 상황/문장형 문제들이 전체적으로 짧아지고 간략해집니다.
                    </h4>
                <h4 style="font-weight: normal">
                    "World problem"들이 아예 없어지는 것은 아니라 이런 형태의 문제들도 기존과 동일하게 꾸준히 연습하셔야됩니다.
                </h4>
                <v-img class="mt-7" :src="'https://i.postimg.cc/fRYR3N68/Digital-SAT7.png'"></v-img>
                <v-img class="mt-2" :src="'https://i.postimg.cc/Jhn7pks2/Digital-SAT8.png'"></v-img>
                <v-divider class="my-5"></v-divider>
                <h4 style="font-weight: normal">
                    아무래도 수학 섹션보다는 영어 섹션의 변화를 학생들이 좀더 체감할 것 같고 특히 짧고 다양한 주제들의 지문을 얼마나 집중하면서 보는지가 Digital SAT에서의 고득점 key-point가 될 것 같습니다.
                    <br/>
                    디지털/온라인 시험이니 디지털/온라인으로 과외하면서 대비하는 것도 도움이 될꺼라 생각되네요  :)
                </h4>
            </v-card-text>

            <v-card-text style="line-height: 30px">

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "EAvsED"
    }
</script>

<style scoped>

</style>
