<template>
    <v-layout class="floating-button-background justify-center">
        <!--        <v-row align="center" justify="center">-->
        <!--            <v-col cols="12">-->
        <!--        <a href="/enroll" class="hidden-sm-and-down">-->
<!--        <v-btn bottom class="hidden-sm-and-down" text elevation="0">-->
<!--            <button class="button hidden-sm-and-down" onclick="location.href='/enroll'">-->
<!--                <span> 아이비리그 선생님과 1:1과외 </span><br/>-->
<!--                <span class="text-h5 font-weight-bold">지금 수강신청 ></span>-->
<!--            </button>-->
<!--        </v-btn>-->
<!--        <v-btn bottom class="hidden-md-and-up" text elevation="0">-->
<!--            <button class="small-button hidden-md-and-up" onclick="location.href='/enroll'">-->
<!--                <span>  아이비리그 선생님과 1:1과외  </span><br/>-->
<!--                <span class="text-h5 font-weight-bold">지금 수강신청 ></span>-->
<!--            </button>-->
<!--        </v-btn>-->
        <div>
        <v-btn bottom class="hidden-sm-and-down" text elevation="0" href="/schedule/call" >
            <button class="button hidden-sm-and-down" >
                <span> Private Tutoring with Ivy Leaguers </span><br/>
                <span class="text-h6 font-weight-bold">CONTACT US ></span>
            </button>
        </v-btn>
        <v-btn bottom class="hidden-md-and-up" text elevation="0" href="/schedule/call" >
            <button class="small-button hidden-md-and-up" >
                <span> Private Tutoring with Ivy Leaguers </span><br/>
                <span class="text-h6 font-weight-bold">CONTACT US ></span>
            </button>
        </v-btn>
        </div>
        <!--                <v-btn-->
        <!--                       color="red lighten-2"-->
        <!--                       fixed-->
        <!--                       bottom-->
        <!--                       x-large-->
        <!--                       class="button-36" role="button"-->
        <!--                       :class="{-->
        <!--               'width: 70%': $vuetify.breakpoint.mdAndDown-->
        <!--               }"-->
        <!--                >-->
        <!--                    &lt;!&ndash;                           mb-n4"&ndash;&gt;-->

        <!--                    <h5>지금 가입하시면 첫 수업 무료! <h3> 태블릿 받고 바로 과외 시작하기</h3></h5>-->

        <!--                    &lt;!&ndash;        <v-card elevation="0" color="primary">&ndash;&gt;-->
        <!--                    &lt;!&ndash;            Hello&ndash;&gt;-->
        <!--                    &lt;!&ndash;        </v-card>&ndash;&gt;-->
        <!--                </v-btn>-->
        <!--        </a>-->

        <!--        <a href="/enroll" class="hidden-md-and-up">-->
        <!--&lt;!&ndash;            <v-btn&ndash;&gt;-->
        <!--&lt;!&ndash;                    color="red lighten-2"&ndash;&gt;-->
        <!--&lt;!&ndash;                    dark&ndash;&gt;-->
        <!--&lt;!&ndash;                    fixed&ndash;&gt;-->
        <!--&lt;!&ndash;                    bottom&ndash;&gt;-->
        <!--&lt;!&ndash;                    x-large&ndash;&gt;-->
        <!--&lt;!&ndash;                    class="button-36-small" role="button"&ndash;&gt;-->
        <!--&lt;!&ndash;            >&ndash;&gt;-->
        <!--&lt;!&ndash;                &lt;!&ndash;                           mb-n4"&ndash;&gt;&ndash;&gt;-->

        <!--&lt;!&ndash;                <h5>지금 가입하시면 첫 수업 무료! <h3> 태블릿 받고 바로 과외 시작하기</h3></h5>&ndash;&gt;-->

        <!--&lt;!&ndash;                &lt;!&ndash;        <v-card elevation="0" color="primary">&ndash;&gt;&ndash;&gt;-->
        <!--&lt;!&ndash;                &lt;!&ndash;            Hello&ndash;&gt;&ndash;&gt;-->
        <!--&lt;!&ndash;                &lt;!&ndash;        </v-card>&ndash;&gt;&ndash;&gt;-->
        <!--&lt;!&ndash;            </v-btn>&ndash;&gt;-->
        <!--            <button class="button"> <span> 오늘 시작하면 첫 수업 무료! </span><br/>-->
        <!--                <span class="text-h5 font-weight-bold" @click="'/enroll'">지금 수강신청 ></span>-->
        <!--            </button>-->

        <!--        </a>-->

        <!--            </v-col>-->
        <!--        </v-row>-->

        <!--                <v-btn-->
        <!--                        slot="activator"-->
        <!--                        color="red lighten-2"-->
        <!--                        dark-->
        <!--                        fixed-->
        <!--                        bottom-->
        <!--                        large-->
        <!--                        class="ml-6 mr-6"-->
        <!--                >-->
        <!--                    Click Me-->
        <!--                </v-btn>-->
    </v-layout>


</template>

<script>
    export default {
        name: "FloatingPayButton",
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
        }
    }
</script>

<style scoped>
    .button-36-small {
        background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 20px;
        height: 1500px;
        width: 80%;
        font-weight: 500;
        /*height: 4rem;*/
        /*padding: 0 1.6rem;*/
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
        transition: all .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-36 {
        background-image: linear-gradient(92.88deg, #b71c1c 9.16%, #c23e3e 43.89%, #cd6060 64.72%);
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 20px;
        height: 1500px;
        width: 400px;
        font-weight: 500;
        /*height: 4rem;*/
        padding: 0 1.6rem;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
        transition: all .5s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    .button-36:hover {
        /*box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;*/
        box-shadow: white 0 1px 30px;
        /*rgba(256,256,256, 0.5) 0 1px 30px;*/
        transition-duration: .1s;
    }

    @media (min-width: 768px) {
        .button-36 {
            padding: 0 2.6rem;
        }
    }

    .fab-container {
        position: fixed;
        bottom: 10px;
        right: 50%;
        left: 50%;

    }

    .glow {
        color: #fff;
        text-align: center;
        animation: glow .5s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
        }
    }

    .button {
        position: fixed;
        bottom: 10px;
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        /*background-color: #DE2828;*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        /*background-image: linear-gradient(92.88deg, #DE2828 9.16%, #c23e3e 43.89%, #cd6060 64.72%);*/
        border-radius: 15px;
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 15px;
        height: 75px;
        width: 400px;
        font-weight: 500;
    }

    .small-button {
        /*box-shadow: 1px 1px 1px 1px;*/
        /*box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;*/
        /*box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;*/
        /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        position: fixed;
        bottom: 10px;
        /*left: 50%;*/
        /*margin-left: -104.5px; !*104.5px is half of the button's width*!*/
        background-image: linear-gradient(92.88deg, #DE2828 9.16%, #cd6060 64.72%);
        /*background-color: #DE2828;*/
        border-radius: 15px;
        /*border-radius: 8px;*/
        /*border-style: none;*/
        /*box-sizing: border-box;*/
        color: #FFFFFF;
        cursor: pointer;
        flex-shrink: 0;
        /*font-family: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI ",Roboto,Oxygen,Ubuntu,Cantarell," Open Sans "," Helvetica Neue ",sans-serif;*/
        /*font-family: 'SuncheonB';*/
        /*src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/SuncheonB.woff') format('woff');*/
        font-size: 15px;
        height: 60px;
        /*width: 350px;*/
        width: 95%;
        font-weight: 500;
    }

    .floating-button-background {
        background-color: #393130
    }
</style>
