import { render, staticRenderFns } from "./VerticalCarouselTwo.vue?vue&type=template&id=6a0650b4&scoped=true&"
import script from "./VerticalCarouselTwo.vue?vue&type=script&lang=js&"
export * from "./VerticalCarouselTwo.vue?vue&type=script&lang=js&"
import style0 from "./VerticalCarouselTwo.vue?vue&type=style&index=0&id=6a0650b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0650b4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCarousel,VCarouselItem,VContainer})
