<template>
    <v-container class="teacher-intro-container py-15" style="height: 100%;" fluid>

<!--        <div class="hidden-md-and-up pl-5 py-10 font-weight-bold" style="font-size: 30px">-->
<!--            Our Teachers-->
<!--        </div>-->
<!--        <VerticalCarousel class="pb-12"></VerticalCarousel>-->
        <VerticalCarouselTwo class="pb-12"></VerticalCarouselTwo>
    </v-container>
</template>

<script>
    // import VerticalCarousel from "./VerticalCarousel";
    import VerticalCarouselTwo from "./VerticalCarouselTwo";

    export default {
        name: "TeacherIntro",
        components: {
            VerticalCarouselTwo
        }
    }
</script>

<style scoped>
    body {
        align-items: center;
        background: #e3e3e3;
        display: flex;
        height: 100vh;
        justify-content: center;
    }

    @keyframes scroll {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-250px * 7));
        }
    }

    .slider {
        background: white;
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
        height: 300px;
        margin: auto;
        overflow: hidden;
        position: relative;
        /*width: 960px;*/
    }

    .slider::before, .slider::after {
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        /*content: &#34;*/
        /*&#34;*/;
        height: 100px;
        position: absolute;
        width: 200px;
        z-index: 2;
    }

    .slider::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    .slider::before {
        left: 0;
        top: 0;
    }

    .slider .slide-track {
        animation: scroll 15s linear infinite;
        display: flex;
        width: calc(250px * 14);
    }

    .slider .slide {
        height: 100px;
        width: 400px;
    }

    .teacher-intro-container {

    }
</style>
