<template>
    <div>
        <v-card class="pa-5">
            <v-card-title class="font-weight-bold text-h5">
                AP Calculus AB vs BC
            </v-card-title>
            <v-card-text style="font-size: 17px; line-height: 30px">
                안녕하세요 아이비에드입니다. <br/>
                오늘은 학생들이 가장 많이 듣는 AP Calculus에 대해서 좀 더 알아보겠습니다.
                <v-divider class="my-7"></v-divider>
                <h3>범위</h3>
                <br/>
                <h4 style="font-weight: normal">
                    AP Calculus는 <strong>범위</strong>에 따라서 AB, BC 두가지로 나누어집니다.
                    <br/>
                    <br/>
                    AB는 총 8개의 유닛, BC는 8개의 유닛에 2개의 유닛이 추가되어 총 10개의 유닛을 다루고 있습니다.
                    <br/>
                    <br/>
                    하단 표를 보시면 AB 과목에 해당하는 앞 부분 유닛의 비중이 대폭 감소하고, 후반부 난이도 높은 유닛들의 비중이 높아지는 경향을 보입니다. (신규 추가된 2개의 유닛이 전체
                    시험의 30%)
                    <v-img class="my-4" :src="'https://i.postimg.cc/8cBZg27T/apcal1.png'">
                    </v-img>

                </h4>
                <br/>
                <h3>AP Score 분포</h3>
                <br/>
                <h4 style="font-weight: normal">
                    5점 만점을 받은 학생의 비율은 AB 18%, BC 38% 수준입니다.

                    <v-img class="my-4" :src="'https://i.postimg.cc/vZ15rRGD/apcal2.png'">
                    </v-img>
                    BC 난이도가 더 높은데 만점자 비중이 높은 이유는 크게 두가지로 설명이 가능합니다.
                    <br/>
                    <br/>
                    <strong>첫째 Selection Bias,</strong>
                    <br/>
                    난이도 높은 BC 수학을 듣는 학생들은 대부분 어느정도 수학에 자신이 있는 경우가 많을 수밖에 없습니다. 수학을 잘 하는 학생들이 많이 수강하니 만점 비율이 자연스럽게 높게 나오는 것입니다.
                    <br/>
                    <br/>
                    <strong>둘째 Score Curve,</strong>
                    <br/>
                    어려운 과목인 만큼 College Board에서 5점 커트라인을 낮게 설정합니다. BC의 경우 일반적으로 시험에서 60%만 맞춰도 5점이 부여됩니다.

                    <v-img class="my-4" :src="'https://i.postimg.cc/NfYSM47K/apcalculus.png'">
                    </v-img>

                </h4>
                <v-divider class="my-5"></v-divider>
                <h3>시험 형식</h3>                <br/>

                <h4 style="font-weight: normal">
                    시험 형식은 AB/BC 동일하며 둘 다 객관식 45문항, 주관식 6문항으로 구성되어 있으며
                    며 공학용 계산기 사용이 불가한 문제들도 있으니 계산기 없이도 빠른 문제풀이가 가능하도록 많은 준비와 연습이 필요합니다.

                    <v-img class="my-4" :src="'https://i.postimg.cc/C5Bwv45C/apcal3.png'">
                    </v-img>
                </h4>
                <br/>
                <v-divider class="my-5"></v-divider>
                <h3>AB vs BC 어떤 수업을 들어야하나요?</h3>
                <br/>
                <h4 style="font-weight: normal">
                    수학에 대한 자신감, 이과/문과 계열 진학 계획, 다른 AP 과목은 몇개나 듣는지 등, 학생별로 상황이 다 다르기 때문에 일반화해서 뭐가 좋다고 말씀드릴 수는 없지만 아무래도 BC들은 학생이 AB들은 학생보다는 돋보이기 때문에 무리하지 않은 상황이라면 BC를 추천드립니다.
                    <br/>
                    <br/>
                    간혹 AB -> BC  순차적으로 둘 다 듣는 학생들도 있는데 가급적이면 하나만 택일해서 듣는 것을 추천드립니다.
                    <br/>
                    <br/>
                    앞서 말씀드린 것처럼 BC는 AB범위에 2개의 유닛만 추가된 거지 별개의 과목이 아닙니다. 실제로 BC시험을 보시면 AB subscore 점수가 따로 주어지며 Collegeboard는 해당 subscore를 Calculus AB 점수와 동일하게 취급하라고 대학교에 가이드 해주고 있습니다.
                    <br/>
                    <br/>
                    약 70%의 범위가 중복되는 과목을 다시 듣는 것보다는 Statistics, Physics, Chemistry, History등 다른 AP 과목을 듣는 것이 대학교 입학 심사관 눈에서도 더 효율적으로 학습했다고 어필이 될 것입니다.
                </h4>
                <v-divider class="my-5"></v-divider>
                <h3>AP Calculus 준비</h3>
                <br/>
                <h4 style="font-weight: normal">
                    범위가 넓고 이론이 처음 접했을 때 이해하기 쉽지 않은 것은 사실이지만 차근차근 개념을 이해하며 예제풀이를 많이 한다면 충분히 5점 받으실 수 있습니다.
                    <br/>
                    <br/>
                    AP 시험 출제된 문제들 보면 패턴이 비슷해서 Practice Exam을 많이 풀어보는게 절대적으로 유리하고 문제집도 상대적으로 난이도가 높은 Barrons 문제집으로 추천드립니다. 본 시험이 쉽게 느껴져 점수가 상대적으로 높게 나올 것입니다.
                </h4>
            </v-card-text>

            <v-card-text style="line-height: 30px">

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "EAvsED"
    }
</script>

<style scoped>

</style>
