<template>
    <!--------------------------------->
    <!-------- Browser Version -------->
    <!--------------------------------->
    <div class="container">


        <!--            <div class="d-flex">-->
        <!--                <v-checkbox-->
        <!--                        v-model="disabled"-->
        <!--                        label="Disabled"-->
        <!--                ></v-checkbox>-->
        <!--            </div>-->
        <v-card width="700" elevation="0" class="mt-10 mb-4" color="#F7F8FA">
            <div class="justify-center">
                <p class="sub-main-01 main-color">Still not sure? </p>
                <p class="font-weight-bold main-color" style="font-size: 25px">We provide a free 24/7 counseling for you</p>
            </div>
            <div class="justify-center">
                <p class="sub-main-02 " style="font-size: 22px;">Speak to our Ivy League graduate counselors. </p>
                <!--                <p class="sub-main-02 ">상담은 무료입니다.</p>-->
            </div>
            <div class="justify-center">
<!--                <v-btn x-large :href="'https://pf.kakao.com/_NxlSSb/chat'" width="250px" color="#ffe812"  class="rounded-xl text-center my-5 black&#45;&#45;text">-->
<!--                    <v-icon color="#ffe812" size="40">$vuetify.icons.kakao-->
<!--                    </v-icon> &nbsp; 톡상담-->
<!--                </v-btn>-->
<!--                <br/>-->
                <v-btn target="_blank" x-large width="250px" :href="'/schedule/call'" color="" elevation="2" class="rounded-xl text-center my-5 black--text">
                    <v-icon color="" size="40">mdi-face-agent
                    </v-icon> &nbsp; Book a call
                </v-btn>
                <div></div>
                <v-btn target="_blank" x-large width="250px" :href="'mailto: support@ivyed.co.kr'" color="" elevation="2" class="rounded-xl text-center my-5 black--text">
                    <v-icon color="" size="40">mdi-email
                    </v-icon> &nbsp; Email
                </v-btn>
                <br/>

            </div>
        </v-card>
        <!--        <v-container >-->
        <!--            <v-card-->
        <!--                    class="mx-auto ma-10 primary&#45;&#45;text hidden-sm-and-down"-->
        <!--                    max-width="700"-->
        <!--                    outlined-->
        <!--                    color="accent"-->
        <!--            >-->
        <!--                <v-card-title class="text-center justify-center py-6">-->
        <!--                    <h1 class="font-weight-bold text-h4 basil&#45;&#45;text">-->
        <!--                        Frequently Asked Questions-->
        <!--                    </h1>-->
        <!--                </v-card-title>-->

        <!--                <v-tabs-->
        <!--                        v-model="tab"-->
        <!--                        background-color="transparent"-->
        <!--                        color="basil"-->
        <!--                        grow-->
        <!--                >-->
        <!--                    <v-tab-->
        <!--                            v-for="item in faqTitles.topics"-->
        <!--                            :key="item"-->
        <!--                    >-->
        <!--                        {{ item }}-->
        <!--                    </v-tab>-->
        <!--                </v-tabs>-->
        <!--                <v-tabs-items v-model="tab" class="primary&#45;&#45;text">-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.student"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.teacher"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.payment"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <h3>need to add content</h3>-->
        <!--                            &lt;!&ndash;                    <v-expansion-panels>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <v-expansion-panel&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                v-for="(item,i) in faqContent.teacher"&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                :key="i">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                {{item.title}}&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            <v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                {{item.value}}&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                    </v-expansion-panels>&ndash;&gt;-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.etc"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->


        <!--                </v-tabs-items>-->
        <!--            </v-card>-->

        <!--            &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
        <!--            &lt;!&ndash;&#45;&#45;&#45;&#45;&#45;&#45; Mobile Version  &#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
        <!--            &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
        <!--            <v-card-->
        <!--                    class="mx-auto ma-10 primary&#45;&#45;text hidden-md-and-up"-->
        <!--                    max-width="700"-->
        <!--                    outlined>-->
        <!--                <v-card-title class="text-center justify-center py-6">-->
        <!--                    <h5 class="font-weight-bold text-md-h5 basil&#45;&#45;text">-->
        <!--                        Frequently Asked Questions-->
        <!--                    </h5>-->
        <!--                </v-card-title>-->

        <!--                <v-tabs-->
        <!--                        v-model="tab"-->
        <!--                        background-color="transparent"-->
        <!--                        color="basil"-->
        <!--                        grow-->
        <!--                        show-arrows-->
        <!--                >-->
        <!--                    <v-tab-->
        <!--                            v-for="item in faqTitles.topics"-->
        <!--                            :key="item"-->
        <!--                    >-->
        <!--                        {{ item }}-->
        <!--                    </v-tab>-->
        <!--                </v-tabs>-->


        <!--                <v-tabs-items v-model="tab" class="primary&#45;&#45;text">-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.student"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.teacher"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.payment"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <h3>need to add content</h3>-->
        <!--                            &lt;!&ndash;                    <v-expansion-panels>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        <v-expansion-panel&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                v-for="(item,i) in faqContent.teacher"&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                :key="i">&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            <v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                {{item.title}}&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            </v-expansion-panel-header>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            <v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                                {{item.value}}&ndash;&gt;-->
        <!--                            &lt;!&ndash;                            </v-expansion-panel-content>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                        </v-expansion-panel>&ndash;&gt;-->
        <!--                            &lt;!&ndash;                    </v-expansion-panels>&ndash;&gt;-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->
        <!--                    <v-tab-item>-->
        <!--                        <v-card color="basil" flat>-->
        <!--                            <v-expansion-panels>-->
        <!--                                <v-expansion-panel-->
        <!--                                        v-for="(item,i) in faqContent.etc"-->
        <!--                                        :key="i">-->
        <!--                                    <v-expansion-panel-header>-->
        <!--                                        {{item.title}}-->
        <!--                                    </v-expansion-panel-header>-->
        <!--                                    <v-expansion-panel-content>-->
        <!--                                        {{item.value}}-->
        <!--                                    </v-expansion-panel-content>-->
        <!--                                </v-expansion-panel>-->
        <!--                            </v-expansion-panels>-->
        <!--                        </v-card>-->
        <!--                    </v-tab-item>-->


        <!--                </v-tabs-items>-->
        <!--            </v-card>-->
        <!--        </v-container>-->
    </div>

</template>
<script>

    import info from "../../../info";

    export default {
        name: 'Marketing-Home',
        data() {
            return {
                tab: null,
                // items: [
                //     '학생', '선생님', '결제', '화이트보드', '수업문의',
                // ],
                faqTitles: info.faq,
                faqContent: info.faqContent,
                panel: [0, 1],
                disabled: false,
                readonly: false,
            }
        },
    }
</script>
<style scoped>
    .faq-style {
        /*background: url(../../assets/library_cornell_001_dark.png) center center/cover no-repeat;*/
    }

    .container {
        margin: 0 auto;
        max-width: 600px;
        /*padding: 1rem;*/


    }

    .justify-center {
        text-align: center;
    }

    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }

    .sub-main-02 {
        /*font-weight: bold;*/
        /*color: #ECE9E6;*/
        /*color: #290F29;*/
        color: dimgray;
        font-size: 20px;
    }

    .question-font {
        font-size: 17px;
    }
</style>
