<template>
    <div style="background-color: white" class="home-background">
        <!-- Hero Container -->
        <!--    <div>-->
        <v-container fluid class="accent--text hidden-sm-and-down center-vertical pt-10"
                     :class="{'hero-container': $vuetify.breakpoint.mdAndUp}">
            <v-container class="new-container " fluid>
                <v-row>
                    <v-col md="1">
                    </v-col>
                    <v-col cols="12" md="4" class="primary--text"

                    >



                        <span class="ivy-ed-font"
                              :class="{

                    }">
                            <!--                            비대면 명문대<br/> 유학과외<br/>-->
                            <!--                            어디서나 <br/> 명문대 선생님과 <br/> 1:1 유학과외<br/>-->
<!--                            <h1 class="main-color">IVY EDUCATION<br/> ON THE GO<br/></h1>-->
                            <h1 class="main-color">Online <br/>College Prep <br/> With Ivy Leaguers<br/></h1>
                        </span>


                        <h2 class="black--text  mt-5" style="color: #CECECE; font-weight: 500 !important; font-size: 24px;">
                            Ace your exams and school courses with Ivy League tutors
                            <!--                            나에게 딱 맞는 <br/> <strong>미국 최상위 명문대 선생님</strong>과 <br/>온라인 1:1 과외-->
                        </h2>
                        <div class="mt-15">
                            <v-row class="center-vertical">
<!--                                    <v-text-field filled label="Email">-->

<!--                                    </v-text-field>-->
                                    <v-btn
                                            large
                                            max-width="30%"
                                            color="secondary"
                                            class=" register-btn" style="font-size: 12px;">
                                        <div class="font-weight-bold">Get Started</div>
                                    </v-btn>
                            </v-row>
                        </div>
                    </v-col>

                    <v-col md="7">
                        <v-card max-width="100%" class="text-center" flat>
                            <v-carousel style="margin: 0 !important; padding: 0 !important; "
                                        height="380px"
                                        ref="myCarousel"
                                        hide-delimiter-background
                                        :show-arrows-on-hover="true"
                                        next-icon="mdi-menu-right"
                                        prev-icon="mdi-menu-left"
                                        cycle
                                        interval="4000"
                            >
                                <v-carousel-item>
                                    <img src="../../assets/test.gif" width="" class="tablet-img"/>

                                </v-carousel-item>
                                <v-carousel-item>
                                    <img src="../../assets/final-cs-1.gif" width="" class="tablet-img"/>

                                </v-carousel-item>
                            </v-carousel>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <v-container fluid class="accent--text hidden-md-and-up mobile-hero-background pt-10 pb-8">
            <v-row>
                <v-col cols="12" md="6" class="primary--text  ivy-ed-font"
                >
<!--                    <h1 class="main-color">Online <br/>College Prep Private Tutoring<br/> With Ivy Leaguers<br/></h1>-->

                    <p class="font-weight-bold mt-5 mx-3 main-color slogan-text">
                        Online College Prep with Ivy Leaguers
                    </p>
                    <!--                        <p class="font-weight-bold main-text-color">-->
                    <!--                            ON THE GO-->
                    <!--                        </p>-->
                    <!--                        <span class="font-weight-bold text-h4 color-1">IVY EDUCATION</span><br/>-->
                    <!--                        <span class="font-weight-bold text-h4 color-2">IVY EDUCATION</span><br/>-->
                    <!--                        <span class="font-weight-bold text-h4 color-3">IVY EDUCATION</span>-->
                    <!--                    <p class="font-weight-bold text-h4  black--text">-->
                    <!--                        내 자리에서-->
                    <!--                    </p>-->
                    <!--                    <v-divider class="transparent my-8"></v-divider>-->

                    <p class="text-h6 mx-3 font-weight-medium mt-5 mb-7 black--text">
                        Ace your exams and school courses with Ivy League tutors
                    </p>

                </v-col>
            </v-row>
            <div class="py-2"></div>
            <v-card flat style="padding-left: 12px; padding-right: 12px">
                <v-carousel

                        ref="myCarousel"
                        hide-delimiter-background
                        :show-arrows="false"
                        next-icon="mdi-menu-right"
                        prev-icon="mdi-menu-left"
                        cycle
                        delimiter-icon=""
                        interval="4000"
                        height=""
                        hide-delimiters
                >
                    <v-carousel-item class="text-center">
                        <img width="100%" src="../../assets/test.gif" class=""/>

                    </v-carousel-item>
                    <v-carousel-item class="text-center">
                        <img width="100%" src="../../assets/final-cs-1.gif" class=""/>

                    </v-carousel-item>
                </v-carousel>
            </v-card>

            <div class="center-horizontal pt-10 pb-12">

                <v-btn
                        to="/register"
                        color="secondary"
                        width="40%"
                        x-large
                        class="font-weight-bold  register-btn"
                >Get Started
                </v-btn>

                <!--                    <v-btn color="white" text @click="scrollto('success-section')">-->
                <!--                        <v-icon size="40" color="primary">mdi-chevron-triple-down</v-icon>-->
                <!--                    </v-btn-->
                >
            </div>
        </v-container>


        <!--    Join Now Section-->
        <v-container fluid id="success-section" class="hidden-sm-and-down pt-15 pb-15"
                     :class="{
        'selling-point-container': $vuetify.breakpoint.mdAndUp,
        'mobile-selling-point-container': $vuetify.breakpoint.smAndDown,}">


            <!--            <p class="text-h3 font-weight-bold main-text-color text-center center-vertical">-->
            <!--                아이비에드가 왜 특별한가요?-->
            <!--            </p>-->
            <div class="mt-8">

                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">
                    <!--                1. [HARD TO FIND FIT / FREQUENT TURNOVERS] <br/>-->

                    <!--                [PHYSICAL DISTANCE]-->
                    <p class="font-weight-bold main-text-color text-h4">ANYWHERE </p>
                    <p class="text-h6 font-weight-medium">
                        Improve your scores with Ivy League tutors <br/>
                        at anywhere, at anytime. <br/>
                        All you need is your device!

                    </p>

                </v-container>

                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">
                    <p class="font-weight-bold main-text-color text-h4"> TOP CLASS TUTORS<br/>
                    <p class="text-h6 font-weight-medium">
                     You will meet Top 1% and Ivy League tutors <br/> at your own desk!
                </p>
                </v-container>
                <v-container class="text-center main-text-color mb-4" color="transparent" elevation="0">
                    <p class="font-weight-bold main-text-color text-h4"> SMART MATCHING <br/>
                    <p class="text-h6 font-weight-medium">
                    We match your tutor based on your request details, <br/>100% tailored to your own needs.

                </p>
                </v-container>

            </div>

            <!--            <p class="text-h5 font-weight-bold text-center center-vertical">-->
            <!--                3. [MATERIAL KEEPING]<br/>-->
            <!--                문제 풀이하느라 노트도 적지 못해 수업 후에 복습하기가 어렵지 않으셨나요?-->
            <!--            </p>-->

            <!--            <p class="text-h5 font-weight-bold text-center center-vertical">-->
            <!--                3. [MATERIAL KEEPING]<br/>-->
            <!--                문제 풀이하느라 노트도 적지 못해 수업 후에 복습하기가 어렵지 않으셨나요?-->
            <!--            </p>-->


        </v-container>

        <v-container fluid id="success-section" class="primary--text text-center hidden-md-and-up "
                     style="padding-top: 70px"
                     :class="{
        'selling-point-container': $vuetify.breakpoint.mdAndUp,
        'mobile-selling-point-container': $vuetify.breakpoint.smAndDown,}">

            <v-container class=" main-text-color mb-5" color="transparent" elevation="0">
                <!--                1. [HARD TO FIND FIT / FREQUENT TURNOVERS] <br/>-->

                <!--                [PHYSICAL DISTANCE]-->
                <p class="font-weight-bold main-text-color text-h4 mt-5">ANYWHERE </p>
                <p class="text-h6 font-weight-medium ">
                    Improve your scores with Ivy League tutors at anywhere, at anytime. All you need is your device!
                </p>

            </v-container>

            <v-container class=" main-text-color mb-5" color="transparent" elevation="0">
                <p class="font-weight-bold main-text-color text-h4"> TOP CLASS TUTORS<br/>
                <p class="text-h6 font-weight-medium ">
                    You will meet Top 1% and Ivy League tutors at your own desk!
                </p>
            </v-container>
            <v-container class=" main-text-color mb-8" color="transparent" elevation="0">
                <p class="font-weight-bold main-text-color text-h4"> SMART MATCHING <br/>
                <p class="text-h6 font-weight-medium ">
                    We match your tutor based on your request details, <br/> 100% tailored to your own needs.
                </p>
            </v-container>


        </v-container>

        <HowItWorksIntro></HowItWorksIntro>



        <v-container fluid class="top-class-tutor-section pt-15">

            <v-container class="new-container">
                <v-row
                        align="right"
                        class="mt-3"
                        :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                    <v-col class="justify-left">
                        <p class="sub-main-01 black--text mb-7 " style="font-size: 36px !important;">Our Teachers</p>
                    </v-col>
                </v-row>
                <!--                <img class="mt-15" src="../assets/cropped-ivy.png" width="100%">-->
                <!--                <img class="mt-15" src="../assets/emblem.png" width="100%">-->

                <v-row class="mt-3 paragraph-font justify-center"
                       :class="{
                                'mb-5': $vuetify.breakpoint.xs,
                                'mt-10': $vuetify.breakpoint.mdAndUp,
                              }"
                >


                    <!--                    <br/>-->
                    <!--                    <br/>-->
                    <div class="hidden-sm-and-down">
                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="500px">
                            <v-card-title class="teacher-section-title">From Ivy Leagues and more</v-card-title>
                            <v-card-subtitle class="text-subtitle-1"> Based on U.S. New Best Colleges, you will learn from a tutor from top 1% schools.

                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <div class="center-horizontal">
                                <!--                            <img  src="../assets/ivyleagelogostransparent.png" width="70%" />-->
                                <img src="../../assets/finalNewPrinceton.png" width="70%"/>
                            </div>
                            <!--                            <img src="../assets/ivyleagelogostransparent.png" width="100%" />-->
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>

                        <v-divider class="mb-4 transparent"></v-divider>
                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="500px">
                            <v-card-title class="teacher-section-title"> Qualified Tutors Only</v-card-title>
                            <v-card-subtitle class="text-subtitle-1">All our tutors have gone through interviews, background checks, and mock lectures to guarantee quality to our students.

                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <v-container fluid class="mb-5 center-horizontal">
                                <div>
                                    <v-icon size="100px" color="primary">mdi-certificate-outline</v-icon>
                                    <v-icon color="" size="60">mdi-arrow-right-bold</v-icon>
                                    <v-icon size="100px" color="#2146B3">mdi-account-supervisor</v-icon>
                                    <v-icon size="60">mdi-arrow-right-bold</v-icon>
                                    <v-icon size="100px" color="#6876EC">mdi-human-male-board</v-icon>
                                </div>
                                <!--                            <img src="../assets/teacherOnboardingProcess.png" width="100%" height=""/>-->
                            </v-container>
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>
                        <v-card elevation="24" class="mb-8 rounded-xl" max-width="500px">
                            <v-card-title class="teacher-section-title">Certified Online Tutors</v-card-title>
                            <v-card-subtitle class="text-subtitle-1">All our tutors go through initial and regular IVYED's  'teaching online certificate programs' to provide the best online education experience.
                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <div class="center-horizontal">
                                <img src="../../assets/ELs__Tech_2_.width-800.png" width="90%"/>
                            </div>
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>
                        <v-divider class="mb-4 transparent"></v-divider>
                    </div>

                    <div class="hidden-md-and-up">

                        <v-card elevation="24" color="" class="mb-15 pb-5 rounded-xl" max-width="344">
                            <v-card-title class="teacher-section-title mb-1">From Ivy Leagues and more</v-card-title>
                            <v-card-subtitle style="font-size: 16px">
                                Based on U.S. New Best Colleges, you will learn from a tutor from top 1% schools.

                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <div class="center-horizontal">
                                <!--                            <img  src="../assets/ivyleagelogostransparent.png" width="70%" />-->
                                <img src="../../assets/finalNewPrinceton.png" width="70%"/>
                            </div>
                            <!--                            <img src="../assets/ivyleagelogostransparent.png" width="100%" />-->
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>

                        <v-divider class="mb-6 transparent"></v-divider>
                        <v-card elevation="24" class="mb-15 rounded-xl" max-width="344">
                            <v-card-title class="teacher-section-title mb-1"> Qualified Tutors Only</v-card-title>
                            <v-card-subtitle style="font-size: 16px">All our tutors have gone through interviews, background checks, and mock lectures to guarantee quality to our students.
                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <v-container fluid class="mb-5">
                                <v-icon size="70px" color="primary">mdi-certificate-outline</v-icon>
                                <v-icon color="" size="45">mdi-arrow-right-bold</v-icon>
                                <v-icon size="70px" color="#2146B3">mdi-account-supervisor</v-icon>
                                <v-icon size="45">mdi-arrow-right-bold</v-icon>
                                <v-icon size="70px" color="#6876EC">mdi-human-male-board</v-icon>
                                <!--                            <img src="../assets/teacherOnboardingProcess.png" width="100%" height=""/>-->
                            </v-container>
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>
                        <v-divider class="mb-6 transparent"></v-divider>

                        <v-card elevation="24" class="mb-12 rounded-xl" max-width="344">
                            <v-card-title class="teacher-section-title mb-1">Certified Online Tutors</v-card-title>
                            <v-card-subtitle style="font-size: 16px">All our tutors go through initial and regular IVYED's  'teaching online certificate programs' to provide the best online education experience.
                            </v-card-subtitle>
                            <!--                            <v-divider></v-divider>-->
                            <div class="center-horizontal">
                                <img src="../../assets/ELs__Tech_2_.width-800.png" width="80%"/>
                            </div>
                            <!--                            <v-img class="" src="../assets/teacherOnboardingProcess.png"></v-img>-->
                            <!--                            <v-img class="" src="https://cdn.vuetifyjs.com/images/cards/cooking.png" height="150"></v-img>-->
                        </v-card>
                    </div>


                </v-row>

            </v-container>
        </v-container>

        <WhyIvyed></WhyIvyed>

        <v-container fluid class="smart-matching-section pt-15 pb-13">

            <v-container class="new-container">
                <v-row
                        align=""
                        class="mt-3"
                        :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                    <v-col>
                        <p class="sub-main-01 black--text pb-5" style="font-size: 36px;">Smart Matching</p>
                    </v-col>
                    <v-col>
                        <!--                        <img src="../assets/img.png">-->
                        <!---->
                        <!--                        </img>-->
                    </v-col>
                </v-row>
                <SmartMatching></SmartMatching>
            </v-container>


        </v-container>
        <!--        <CourseIntro></CourseIntro>-->
        <TeacherIntro></TeacherIntro>
        <v-container fluid class="pt-15 pb-10 faq-section">

            <v-container class="new-container  center-horizontal">
                <v-row
                        align=""
                        class="mt-3"
                        :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                </v-row>
                <FAQ></FAQ>
            </v-container>


        </v-container>

        <v-container fluid class="grayish-color pb-15">

            <v-container class="new-container">
                <v-row
                        align=""
                        class="mt-3"
                        :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                </v-row>
                <MarketingHome></MarketingHome>
            </v-container>


        </v-container>

    </div>

</template>

<script>
    import info from "../../../info";
    import SmartMatching from "../SmartMatching";
    import FAQ from "../../views/pages/Faq";
    import MarketingHome from "../../views/pages/Marketing-Home"
    import HowItWorksIntro from "./HowItWorksIntro";
    import WhyIvyed from "./WhyIvyed";
    // import CourseIntro from "./CourseIntro";
    import TeacherIntro from "./TeacherIntro";
    // import { Flicking } from "@egjs/vue-flicking";

    export default {
        name: "HomeTest",
        components:
            {
                TeacherIntro,
                // CourseIntro,
                WhyIvyed,
                HowItWorksIntro,
                MarketingHome,
                FAQ,
                SmartMatching,
                // Flicking: Flicking
            },
        data() {
            return {
                move: [],
                drag: false,
                touch: false,
                content: "",
                data: info.home,
                items: [
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
                    },
                    {
                        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
                    },
                ]

            };
        },
        methods: {
            scrollto(el, secondaryel) {
                const bodyRect = document.body.getBoundingClientRect().top;
                let element = document.getElementById(el);
                if (element == null) element = document.getElementById(secondaryel);
                const headerOffset = 70;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - bodyRect - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
            logic(e) {
                let currentMove = this.touch ? e.touches[0].clientX : e.clientX;
                if (this.move.length == 0) {
                    this.move.push(currentMove);
                }
                if (this.move[this.move.length - 1] - currentMove < -100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__prev")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
                if (this.move[this.move.length - 1] - currentMove > 100) {
                    this.$refs.myCarousel.$el
                        .querySelector(".v-window__next")
                        .querySelector(".v-btn")
                        .click();
                    this.drag = false;
                    this.touch = false;
                }
            }
        },
        mounted() {
            // For touch devices
            this.$refs.myCarousel.$el.addEventListener("touchmove", (e) => {
                this.drag = false;
                this.touch = true;
                this.logic(e);
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("touchend", (e) => {
                this.move = [];
            });

            // For non-touch devices
            this.$refs.myCarousel.$el.addEventListener("mousedown", (e) => {
                this.drag = true;
                this.touch = false;
                this.logic(e);
            });
            this.$refs.myCarousel.$el.addEventListener("mousemove", (e) => {
                this.drag ? this.logic(e) : null;
            });
            // eslint-disable-next-line no-unused-vars
            window.addEventListener("mouseup", (e) => {
                this.drag = false;
                this.touch = false;
                this.move = [];
            });


        },
    };
</script>

<style scoped>
    .glow {
        color: #A20916;
        text-align: center;
        animation: glow 2s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
        from {
            text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
        }

        to {
            text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
        }
    }

    .courses-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .courses-container {
        background-color: rgb(245, 245, 245);
    }

    .console-container {

        font-family: Khula;
        font-size: 4em;
        text-align: center;
        height: 200px;
        width: 600px;
        display: block;
        position: absolute;
        color: white;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .console-underscore {
        display: inline-block;
        position: relative;
        top: -0.14em;
        left: 10px;
    }

    .hero-container {
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/worldmap.png) center center/cover no-repeat;*/
        background-color: #fffffb;
        height: 750px;
        /*calc(100vh - 50px);*/
        /*width: 100%;*/
        font-weight: normal;
        /*box-shadow: inset 0 0 0 1000px rgb('#A20916');*/
        /*rgba(0, 0, 0, 0.3);*/
        object-fit: contain;
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        /*align-items: center;*/
        /*color: #A20916;*/
        /*font-family: HallymGothic-Regular;*/
    }

    .mobile-hero-container {
        /*background: url(../assets/background_option_books_001.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-tablet-001.jpeg) center center/cover no-repeat;*/
        background-color: #fffffb;
        /*height: 100%;*/
        /*height: 100%;*/
        /*calc(100vh - 50px);*/
        width: 100%;
        font-weight: normal;
        /*box-shadow: inset 0 0 0 1000px rgb('#A20916');*/
        /*rgba(0, 0, 0, 0.3);*/
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*color: #A20916;*/
        /*font-family: HallymGothic-Regular;*/
    }


    .selling-point-container {
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background-color: #62FFE0;*/
        /*background-color: #5C060D;*/
        /*background: linear-gradient( #5C060D 9.16%, #A20916 43.89%, #cd6060 64.72%);*/
        background-color: #A30717;
        /*height: calc(100vh - 50px);*/
        /*color: #fffffb;*/
        height: 750px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-selling-point-container {
        /*background: url(../assets/background-apple-pencil.jpeg) center center/cover no-repeat;*/
        /*background-color: #5C060D;*/
        padding-bottom: 40px;
        padding-top: 40px;
        background-color: #AF0915;
        /*height: calc(100vh - 50px);*/
        height: 100%;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);
        object-fit: contain;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .faq-container {
        /*background: url(../assets/library_003.jpeg) center center/cover no-repeat;*/
        background-color: #5C060D;
        /*background-color: #ffcccb;*/
        /*height: calc(100vh - 50px);*/
        height: 750px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);*/
        /*object-fit: contain;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }

    .mobile-faq-container {
        /*background: url(../assets/library_003.jpeg) center center/cover no-repeat;*/
        background-color: #A20916;
        /*height: calc(100vh - 50px);*/
        height: 500px;
        width: 100%;
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);*/
        /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0);*/
        /*object-fit: contain;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
    }


    .center-horizontally {
        display: flex;
        flex-direction: column;
        /*justify-content: left;*/
        align-items: center;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-large {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }

    /*.center-horizontally {*/
    /*    text-align: center;*/
    /*    margin: auto;*/
    /*}*/

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text-large {
        font-size: 5.5rem;
        line-height: 5.5rem;
    }

    .anywhere-section {
        background-color: #F7F8FA;
        color: #5C060D;
    }

    .faq-section {


    }

    .slogan-text {
        font-size: 40px;
    }

    .main-color {
        color: #5C060D
    }

    .top-class-tutor-section {
        background-color: #ffffff;
        color: #000000;
    }

    .smart-matching-section {
        background-color: #F7F8FA;
    }

    .circles-container {
        position: relative;
        overflow: hidden;
        height: 400px;
        max-width: 500px;
    }

    .card-transparent {
        background-color: transparent;
    }

    #circle-one-outer {
        position: absolute;
        top: 0;
        left: 50%;
    }

    #circle-one-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 32px;
        font-weight: 500;
        border-radius: 50%;
        width: 270px;
        height: 270px;
        z-index: 2;
        background-color: #ffffff;
    }

    #circle-two-outer {
        position: absolute;
        bottom: 0;
        left: 25%;
    }

    #circle-two-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 26px;
        font-weight: 500;
        border-radius: 50%;
        width: 230px;
        height: 230px;
        z-index: 1;
        background-color: #ffffff;
    }

    #circle-three-outer {
        position: absolute;
        bottom: 5%;
        left: 75%;
    }

    #circle-three-inner {
        position: relative;
        left: -50%;
        border: 5px solid #546e7a;
        color: #546e7a;
        font-size: 22px;
        font-weight: 500;
        border-radius: 50%;
        width: 200px;
        height: 200px;
        z-index: 3;
        background-color: #ffffff;
    }

    .home-text {
        /*font-family: HallymGothic-Regular;*/
        font-size: 42px;
        text-align: left;
        font-weight: bolder;
        /*text-shadow: 3px 3px 3px #0D0D0D;*/
        color: #000000;
    }

    .home-btn {
        color: #3c4043;
    }

    .mobile-home-text {
        font-size: 28px;
        line-height: 130%;
        text-align: center;
        color: #A20916;
    }

    @media (min-width: 1904px) {
        .new-container {
            max-width: 1440px;
        }
    }

    .button-85 {
        padding: 0.6em 2em;
        border: none;
        outline: none;
        color: rgb(255, 255, 255);
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        width: 30px;
        height: 50px;
    }

    .button-85:before {
        content: "";
        background: linear-gradient(
                45deg,
                #ff0000,
                #ff7300,
                #fffb00,
                #48ff00,
                #00ffd5,
                #002bff,
                #7a00ff,
                #ff00c8,
                #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-button-85 20s linear infinite;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
    }

    @keyframes glowing-button-85 {
        0% {
            background-position: 0 0;
        }
        50% {
            background-position: 400% 0;
        }
        100% {
            background-position: 0 0;
        }
    }

    .button-85:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #A20916;
        left: 0;
        top: 0;
        border-radius: 10px;
    }

    .tablet-img {
        /*    !*background-color: #000000;*!*/
        /*    !*border: 1px solid #fffffb;*!*/
        /*    border-radius: 1px;*/
        /*    border: 1px solid #000000;*/
        /*    !*padding: 20px;*!*/
        /*    !*width: 200px;*!*/
        height: 100%;
    }

    .register-btn {
        border: 1px solid #fffffb;
        border-radius: 10px;
        border: 15px solid #000000;
        /*box-shadow: inset 0px 0px 0px 1000px #A20916;*/
    }

    .mobile-hero-background {
        /*background: url(../assets/background-colored-pencils.jpeg) center center/cover no-repeat;*/
        background-color: #fffffb;
    }

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
    .ivy-ed-font {
        font-family: 'Montserrat', sans-serif;
        letter-spacing: -2px;
        font-size: 21px;
        color: #1F383A;
    }

    .align-right {
        text-align: right;
    }

    /*.mobile-hero-text { }*/

    .sub-desc-font {
        color: #A20916;
        font-size: 20px;
        font-weight: normal;
    }

    .main-desc-font {
        color: #253523;
    }

    .color-1 {
        color: #290F29;

    }

    .color-2 {
        color: #75363B;
        /*color: #1D3638;*/

    }

    .main-text-color {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 40px;
        /*color: #75363B;*/
        color: #fffffb;
        text-shadow: 2px 2px 5px #5C060D;

    }

    .mobile-sub-text {
        font-weight: normal;
    }

    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 35px;
    }

    .paragraph-font {
        font-size: 23px;
        font-weight: bold;
    }

    .justify-right {
        text-align: end;
    }

    .justify-left {
        text-align: left;
    }

    .grayish-color {
        background-color: #F7F8FA;
        color: #F7F8FA;
    }

    .teacher-section-title {
        font-size: 20px;
        font-weight: bold;
    }

    .rounded-card {
        border-radius: 100px;
    }

    .icon-style {
        text-shadow: 1px 1px 1.5px #5C060D;
    }

    .faq-section {
        background-color: #F3E5E5;
    }

    .home-background {
    }

    /*.v-carousel__next > button,*/
    /*.v-carousel__prev > button {*/
    /*    color: transparent !important;*/
    /*    background-color: transparent !important;*/
    /*}*/

</style>

