<template>
    <div>
        <v-card class="pa-5">
            <v-card-title class="font-weight-bold text-h5">
                Application Essay / Personal Statement 가 미국 상위권 대학 입시에 중요한 이유! (Part 1)
            </v-card-title>
            <v-card-text style="font-size: 17px; line-height: 30px">
                안녕하세요 아이비에드입니다.
                <br/>
                <br/>
                오늘은 미국 대학을 지원하는데 있어 한국과는 다르게 Application Essay / Personal Statement 가 얼마나 중요하고 왜 입학사정처에서 무게있게 다루는지를
                알아보겠습니다.
                <br/>
                <br/>
                모든 유학생들이 GPA 나 Standardized Test Score (SAT, SAT2, AP 등) 의 중요성들은 설명하지 않아도 충분히 알고 있습니다. 그러다보니 Application
                Essay 는 늘 마지막에 준비하는 경향이 있습니다. 마지막에 준비하는 이유는 다양하겠지만 그 중 Application Essay가 실제로 입시에 있어 얼마나 중요히 여겨지고,
                입학사정관들이 이를 통해 무엇을 얻으려고 하는지 모호 하기 때문도 있을겁니다.
                <br/>
                <br/>
                이 포스트에서 Application Essay 의 중요성에 대해 심도 있게 다뤄보겠습니다.
                <v-divider class="my-7"></v-divider>
                <h3>Essay가 얼마나 중요한가요?</h3>
                <br/>
                <v-img class="my-4"
                       :src="'https://blog.collegevine.com/wp-content/uploads/2016/08/college-applications-components.png'"></v-img>
                <h4 style="font-weight: normal">
                    위 차트에서 보여지는 것과 같이 통계적으로 미국 상위 250위권 대학들에 지원시 무려 25%나 입시원서 / 입학에 영향에 주는걸 알 수 있습니다. 놀랍게도 이 숫자는 Test
                    Scores (SAT 등) 부터 GPA, Recommendation letters 보다도 중요하다는걸 알 수 있습니다.

                    <br/>
                    <br/>
                    이 통계는 코로나로 인해 Test optional 이 도입될 시점쯤 조사된 자료라고 보았을때, 현재는 Application Essay가 차지하는 비중은 더 클 수 밖에 없다는
                    것입니다.
                    <br/>
                    <br/>
                    워낙 한국과 입시 과정이 달라 Application Essay가 이렇게 많이 입시에 차지한다는것에 놀라실 수 있습니다. 또 그러한 이유 때문에 많은 유학생들이 SAT 점수,
                    GPA에만 집중하고 Application Essay는 종종 후순위로 미루는 경향을 보이고 있습니다.
                </h4>
                <v-img class="my-4"
                       :src="'https://img.freepik.com/free-vector/pensive-businessman-making-decision_74855-5283.jpg?t=st=1663637482~exp=1663638082~hmac=b5dbd04c781a6b607503ab18a4cc01b1c310fb5e24b2ee99256bbadebdcb5e68'"></v-img>
                <br/>
                하지만 생각해보면 Application Essay가 상위권 대학에서 중요할 수 밖에 없습니다.
                <br/>
                <br/>
                예를 들어 어떤 대학에 지원을 하는 상위 10% 학생들의 성적과 시험점수는 거의 비슷할 것입니다. 높은 확률로 Extra Curricular Acitivities 도 결국 비슷할
                것이구요.
                <br/>
                <br/>
                예를 들어 입학사정관이 성적과 시험점수가 거의 동일한 4명의 학생 중 단 한명만 뽑아야하는 상황이 온다면 단 한명을 추려낼 수 있는 방법은 학생들의 Essay를 읽어보고 학생들의 Fit이
                해당학교와 잘 맞는지밖에 남지 않습니다.
                <br/>
                <br/>
                이러한 이유로 내신성적과 시험점수가 높은 학생들이 원하는 대학에 합격하지 못한 케이스들을 종종 볼 수 있습니다.
                <v-divider class="my-5"></v-divider>

                <h3>그럼 Application Essay를 어떻게, 언제부터 준비하는게 좋을까요?</h3>
                <br/>
                <h4 style="font-weight: normal">
                    Application Essay 는 Personal Statement라고도 부릅니다. 그 만큼 "Personal" 한 내용을 다뤄야 하는 essay 라고 생각하시면 됩니다.
                    <br/>
                    <br/>
                    대부분의 유학생들을 보면 단순히 과거 하버드를 입학시킨 에세이들을 참고하면서 글을 이쁘게 쓰면 되는거라고 접근하는 경우가 있습니다.

                    <br/>
                    <br/>
                    글을 이쁘게 쓰는건 Personal 한 내용이 충분히 들어간 후에 추가해도 되는 영역입니다. 다시말해 입학사정관에게 <strong>정말 내가 어떤 학생이고 왜 해당학교와 나의
                    배경이 잘 맞는지를 </strong> 녹여 내는것이 핵심입니다.

                    <br/>
                    <br/>
                    <v-img class="my-4"
                           :src="'https://img.freepik.com/free-photo/woman-with-question-notes-why-who-where-how-why_53876-121541.jpg?t=st=1663638624~exp=1663639224~hmac=05fa1015e70284946c99e6cb83cc79d45f9aa143c999bd9269faaff821f13e7f'"></v-img>
                    <br/>
                    전 학생들에게 Essay 를 쓰기전 내 자신에 대해 돌아보는 시간을 70% 이상 사용하도록 권하고 있습니다. 생각보다 내 자신이 어떤 삶을 살아왔고, 옆에 있는 친구와 내가
                    어떤부분이 특별한지 등을 잘 알지 못하는 경우가 많습니다.

                    <br/>
                    <br/>
                    "내 자신에 대해 알아가기"라는 말이 처음엔 추상적으로 들리고 그 때문에 학생들이 어떻게 시작해야 할지를 모르는 경우들이 많습니다.
                    <br/>
                    <br/>
                    이 내용에 대해선 다음 블로그 포스트에서 더 심도 있게 다루도록 하겠습니다.

                </h4>
                <v-divider class="my-5"></v-divider>
                <br/>
                <h3>마치며</h3>
                <br/>
                <h4 style="font-weight: normal">
                    오늘은 미국 상위권 대학 입시에 있어 <strong>많은 유학생 / 학부모님들이 생각하시는것 보다 얼마나 Application Essay 가 중요</strong>한지 다뤄
                    보았습니다.

                    <br/>
                    <br/>
                    미 상위권 대학들에 있어 통계적으로 <strong> Application의 무려 25%</strong>나 차지하고 있습니다.
                    <br/>
                    <br/>
                    입시 시즌이 돌아온만큼 Application Essay를 가볍게 보고 마지막에 부랴부랴 쓰시는 일이 없길 바라는 마음과 함께 오늘 글을 마치도록 하겠습니다.

                    <br/>
                    <br/>
                    다음 포스트에선 <strong>어떻게 Essay 준비를 시작하면 좋은지</strong>에 대해 심도 있게 다루도록 하겠습니다.
                </h4>
                <br/>
            </v-card-text>

        </v-card>

    </div>
</template>

<script>
    export default {
        name: "EAvsED"
    }
</script>

<style scoped>

</style>
