<template>
    <div id="app">
        <v-app app>
<!--            <v-system-bar app class="top-banner center-vertical" color="#1A1A1A" height="38px" v-if="!currentUser">-->
<!--                <a href="/schedule/call" class="font-weight-bold white&#45;&#45;text" style="font-size: 18px" target="">상담 신청하고 <strong> 무료체험 받기 </strong> >-->
<!--&lt;!&ndash;                    <v-icon color="#414271" size="25px">mdi-face-man</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-icon color="#414271" size="25px">mdi-face-woman</v-icon>&ndash;&gt;-->
<!--                </a>-->
<!--            </v-system-bar>-->
            <NavigationBar v-if="showNavBarAndFooter"></NavigationBar>
            <v-main class="main-style">
                <!--            여기 router-view 는 navBar 밑에 children routes들에 감. -->
                <!--            Home.vue를 따로 만들어야 함. -->
                <v-fade-transition mode="out-in" :appear="true" :duration="200">
                    <router-view></router-view>
                </v-fade-transition>
            </v-main>
            <Footer v-if="showNavBarAndFooter"></Footer>

            <!--            <ChannelIO style="height: 250px !important;" v-if="this.showChannelIOandKakaoButton">adfsaf</ChannelIO>-->
            <!--            <KakaoChannelButton v-if="this.showFloatingButton"></KakaoChannelButton>-->
<!--            <ContactButton v-if="showContactButton"></ContactButton>-->
<!--            <KakaoFloatingButton v-if="this.showContactButton"></KakaoFloatingButton>-->
            <FloatingPayButton v-if="this.showFloatingButton"></FloatingPayButton>
        </v-app>
    </div>
</template>
<script>
    import NavigationBar from "../components/NavigationBar";
    import FloatingPayButton from "../components/helpers/FloatingPayButton";
    import Footer from "../components/helpers/Footer";
    // import ChannelIO from "../components/helpers/ChannelIO";
    // import KakaoFloatingButton from "../components/helpers/KakaoFloatingButton";
    // import ContactButton from "../components/helpers/ContactButton";
    // import KakaoChannelButton from "../components/helpers/KakaoChannelButton";

    export default {
        computed: {
            currentUser() {

                return this.$store.state.auth.user;
            },
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            showFooter() {
                if (this.$route.name == 'profile') {
                    return false;
                }
                return true;
            },

            showFloatingButton() {
                // console.log(this.currentUser == true);
                if (this.currentUser) {
                    if (this.currentUser.roles[0] == 'ROLE_TEACHER' || this.currentUser.roles[0] == 'ROLE_ADMIN') {
                        return false
                    }
                }
                if (this.$route.name == 'home') {

                    return true;
                }
                return false;
            },
            showContactButton() {
                if (this.$route.name == 'home'
                    || this.$route.name == 'scheduleCall'
                    // || this.$route.name == 'enrollment'
                ) {
                    return true;
                }
                return false;
            },
            showChannelIOandKakaoButton() {
                if (this.$route.name == 'home'
                    || this.$route.name == 'profile'
                    || this.$route.name == 'viewTeacherSummary'
                    || this.$route.name == 'scheduleCall'
                ) {

                    return true;
                }
                return false;
            },
            showNavBarAndFooter() {
                if (this.$route.name == 'scheduleCall') {
                    return false;
                }
                return true;
            },

        },
        mounted() {
        },
        components: {
            // ContactButton,
            // KakaoChannelButton,
            // KakaoFloatingButton,
            // ChannelIO,
            Footer,
            NavigationBar,
            FloatingPayButton
        },
        methods: {
            // showFloatingButton() {
            //
            // }
        }

    }
</script>
<style scoped>
    .main-style {
        /*background: url(../assets/library_002.jpeg) center center/cover no-repeat;*/
        /*background-color: #b71c1c;*/
    }

    .top-banner {
        margin-bottom: 10px;
        text-align: center;
        /*background-color: #7a00ff;*/
        height: 35px;
        /*color: #;*/
        /*background: linear-gradient(40deg, #FFE5DC 70%, #A9A6DC 35%, #B17065 35%);*/
        background-color: #1F5C00;
        /*linear-gradient(40deg, #FFE5DC 70%, #A9A6DC 35%, #B17065 35%);*/
        /*background: linear-gradient(110deg, #A20916 70%, #DE2828 70%);*/


    }

    .top-banner a, .top-banner span {
        display: inline-block;
    }

    .top-banner a {
        width: 100%;
        /*padding: 15px 0;*/
        text-decoration: none;
        color: #2200cc;
        /*color: rgba(255, 255, 255, .8);*/
    }

    .top-banner span {
        /*width: 50px;*/
        /*heigth: 28px;*/
        text-align: justify-all;
        /*line-height: 28px;*/
        background-color: #ffcccb;
        border-radius: 20px;
    }

    .top-banner a:hover {
        color: #fff9e0;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
