import axios from "axios";
import authHeader from "./auth-header";
import info from "../../info";

// const API_URL = info.urls.local;
// const API_URL = 'https://backend-ie.herokuapp.com/api/';
// https://backend-ie.herokuapp.com/api/test/all

//prod
const API_URL = info.urls.production;

class StudentService {

    getStudentInfo(userId) {
        return axios.get(API_URL + 'student/' + userId, {headers: authHeader()});
    }

    getAllStudents() {
        return axios.get(API_URL + 'student/', {headers: authHeader()});
    }

    getLessonInfoByStudentId(studentId) {
        return axios.get(API_URL + 'student/' + studentId + "/lessons", {headers: authHeader()});
    }

    getUserInfoOfStudent(userId) {
        return axios.get(API_URL + 'student/user/' + userId, {headers: authHeader()});
    }

    getCardInfoOfStudent(userId) {
        return axios.get(API_URL + 'student/card/info/' + userId, {headers: authHeader()});
    }

    getUserInfoByLessonId(lessonId) {
        return axios.get(API_URL + 'student/lessons/' + lessonId, {headers: authHeader()});
    }

    getStudentLessonInfo(userId) {
        return axios.get(API_URL + 'test/student/lesson/' + userId, {headers: authHeader()});
    }

    createStudentPayment(requestBody, userId) {
        return axios.post(API_URL + "student/" + userId + "/payment", requestBody, {headers: authHeader()});
    }

    createStudentPaymentForeignCard(requestBody, userId) {
        return axios.post(API_URL + "student/" + userId + "/payment/foreign", requestBody, {headers: authHeader()});
    }

    createManualStudentPayment(requestBody, userId) {
        return axios.post(API_URL + "student/" + userId + "/manual/payment", requestBody, {headers: authHeader()});
    }

    createNewCustomerBilling(requestBody, userId) {
        return axios.post(API_URL + "student/billing/card/" + userId, requestBody, {headers: authHeader()});
    }

    updateStudent(userId, requestBody) {
        return axios.put(API_URL + "student/" + userId, requestBody, {headers: authHeader()});
    }
}

export default new StudentService();
