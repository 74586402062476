var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faq-container"},[_c('nav',{staticClass:"accordion arrows"},[_c('input',{attrs:{"type":"radio","name":"accordion","id":"cb1"}}),_c('section',{staticClass:"box"},[_c('label',{staticClass:"box-title",attrs:{"for":"cb1"}},[_c('span',{staticClass:"question-text",class:{
                'question-text': _vm.$vuetify.breakpoint.mdAndUp,
                'question-text-mobile': _vm.$vuetify.breakpoint.smAndDown
                }},[_c('span',{staticClass:"primary--text"},[_vm._v("Q.")]),_vm._v(" Why choose IVYED?")])]),_c('label',{staticClass:"box-close",attrs:{"for":"acc-close"}}),_vm._m(0)]),_c('input',{attrs:{"type":"radio","name":"accordion","id":"cb2"}}),_c('section',{staticClass:"box"},[_c('label',{staticClass:"box-title",attrs:{"for":"cb2"}},[_c('span',{staticClass:"question-text",class:{
                'question-text': _vm.$vuetify.breakpoint.mdAndUp,
                'question-text-mobile': _vm.$vuetify.breakpoint.smAndDown
                }},[_c('span',{staticClass:"primary--text"},[_vm._v("Q.")]),_vm._v(" How do I get matched?")])]),_c('label',{staticClass:"box-close",attrs:{"for":"acc-close"}}),_vm._m(1)]),_c('input',{attrs:{"type":"radio","name":"accordion","id":"cb4"}}),_c('section',{staticClass:"box"},[_c('label',{staticClass:"box-title",attrs:{"for":"cb4"}},[_c('span',{staticClass:"question-text",class:{
                'question-text': _vm.$vuetify.breakpoint.mdAndUp,
                'question-text-mobile': _vm.$vuetify.breakpoint.smAndDown
                }},[_c('span',{staticClass:"primary--text"},[_vm._v("Q.")]),_vm._v(" Can I change my tutor?")])]),_c('label',{staticClass:"box-close",attrs:{"for":"acc-close"}}),_vm._m(2),_c('br'),_c('br')])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_c('span',{staticClass:"font-italic font-weight-bold"},[_vm._v("ANYWHERE")]),_c('br'),_vm._v(" You can study and learn literally anywhere and at anytime you want. "),_c('br'),_c('br'),_c('span',{staticClass:"font-italic font-weight-bold"},[_vm._v("TOP CLASS TUTORS")]),_c('br'),_vm._v(" Tutors from Ivy Leagues and top tier colleges. "),_c('br'),_c('br'),_c('span',{staticClass:"font-italic font-weight-bold"},[_vm._v("SMART MATCHING")]),_c('br'),_vm._v(" Strictly tailored to student's needs and learning style. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content"},[_vm._v(" Once you submit a 'Free Trial' form, our matching process begins. "),_c('br'),_c('br'),_vm._v(" You will find the form upon registration, and it's crucial that you provide a type of tutor you prefer and detailed information on yourself (current school, desired major, preferred date and time) as this information is used for our smart matching. "),_c('br'),_c('br'),_vm._v(" Normally, our matching does not take more than 3 business days. "),_c('br'),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-content "},[_vm._v(" Yes! "),_c('br'),_vm._v("We match tutors based on the information you have submitted initially. "),_c('br'),_c('br'),_vm._v("If you would like to add more preferences for tutor matching, please contact us before a matching begins. ")])}]

export { render, staticRenderFns }