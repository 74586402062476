<template>
    <div class="intro">

            <FounderIntro></FounderIntro>
            <HowItWorksIntro></HowItWorksIntro>

            <TeacherIntro></TeacherIntro>
            <WhyIvyed></WhyIvyed>

            <CourseIntro class=""></CourseIntro>

            <ClassNoteIntro></ClassNoteIntro>
    </div>
</template>

<script>


    import TeacherIntro from "./TeacherIntro";
    import CourseIntro from "./CourseIntro";
    import HowItWorksIntro from "./HowItWorksIntro";
    import FounderIntro from "./FounderIntro";
    import WhyIvyed from "./WhyIvyed";
    import ClassNoteIntro from "./ClassNoteIntro";
    export default {
        components: {
            ClassNoteIntro,
            WhyIvyed,
            TeacherIntro,
            FounderIntro,
            HowItWorksIntro,
            CourseIntro
            },
        name: 'Intro',

        data: () => ({
            show: false,
            showCardOne: false,
            showCardTwo: false,
            showCardThree: false,
        }),
        mounted() {
        }
    };
</script>

<style scoped>
    .intro {
        max-width: 100%;
        /*height: 100%;*/
        /*!*max-width: 90%;*!*/
    }
    .background-test {
        background-color: #5278AA;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);

    }
</style>
