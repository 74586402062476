<template>
    <v-container>

        <div v-if="getDefaultLanguage.includes('ko')">
            <v-card
                    class="mx-auto  pa-5 primary--text"
                    max-width="750"
                    flat
            >
            <span style="font-size: 22px" :class="{
            }">
                <strong>회원 정보 수정</strong>
            </span>
            </v-card>
            <!--        <v-divider></v-divider>-->
            <div>
                <!--            <StudentInfo v-if="studentContent.studentRegisterStatus != 'NOT_STARTED'"></StudentInfo>-->
                <v-card
                        class="mx-auto my-5 primary--text rounded-xl"
                        max-width="750"
                        elevation="5"
                >
                    <!--        {{!enrollmentData}}-->
                    <v-form class="ml-5 mr-5 mt-5 pt-9 pb-5 mb-5"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                                    <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Email (아이디)
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        :messages="['변경 불가']"
                                        prepend-icon="mdi-email-outline"
                                        v-model="currentUser.email"
                                        disabled
                                >
                                    Default Slot
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>

                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        전화번호
                                    </span>
                        <v-row>

                            <v-col
                                    cols="12"
                                    md="3"
                            >
                                <v-autocomplete
                                        prepend-icon="mdi-phone"
                                        label="국가코드"
                                        required
                                        :items="countryCodes"
                                        v-model="currentUser.countryCode"
                                        :rules="[rules.required]"
                                        class="font-weight-thin"
                                        :messages="['국가 코드']"
                                        auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col
                                    cols="12"
                                    md="7">
                                <v-text-field

                                        v-model="currentUser.phoneNumber"
                                        :messages="['전화 번호']"
                                >

                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>

                        <!--<v-icon>$vuetify.icons.custom</v-icon>-->
                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        카카오톡 아이디
                                    </span>
                        <v-row>


                            <v-col
                                    cols="12"
                            >
                                <v-text-field
                                        prepend-icon="$vuetify.icons.kakao"
                                        v-model="currentUser.kakaoId"
                                        placeholder="카카오톡 아이디 / 이메일 입력해주세요."
                                >

                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--                    {{currentUser}}-->
                        <v-divider class="mb-2"></v-divider>

                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        생년월일
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        :messages="['생년월일 (\'-\' 없이 숫자만 입력)']"
                                        prepend-icon="mdi-calendar-range"
                                        label=""
                                        v-model="currentUser.birthDate"
                                        required
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-2"></v-divider>
                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        비밀번호 변경
                                    </span>
                        <v-row>
                            <v-col cols="12"
                                   md="7">
                                <v-text-field
                                        :messages="['현재 비밀번호 입력']"
                                        prepend-icon="mdi-lock-reset"
                                        label=""
                                        v-model="currentUser.currentPassword"
                                        required
                                        dense
                                        :type="showPassword ? 'text' : 'password'"
                                ></v-text-field>

                            </v-col>
                            <v-col cols="12"
                                   md="3">
                                <v-btn
                                        @click="checkCurrentPassword(currentUser.currentPassword)"
                                        width="60%"
                                        rounded
                                        outlined
                                        color="primary"
                                        class="text-h9"

                                >
                                    비밀번호 확인
                                </v-btn>
                            </v-col>

                        </v-row>
                        <span v-show="this.wrongPassword == true">
                        비밀번호가 일치하지 않습니다. 다시 시도하여 주세요.
                        </span>
                        <v-card
                                class="mx-auto ma-5 pa-5 primary--text"
                                max-width="750"
                                elevation="0"
                                v-show="this.passwordMatchInfo == true && this.showPasswordCard==0">
                            <v-form v-model="passwordValid">


                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Password"
                                            required
                                            v-model="passwordData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.MinPassLen, rules.passwordDifferent(passwordData.password, currentUser.currentPassword)]"
                                            :type="showPassword ? 'text' : 'password'"
                                            messages="새로운 비밀번호"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >

                                </v-col>
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Confirm Password"
                                            required
                                            v-model="passwordData.passwordConfirm"
                                            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                            messages="비밀번호 한번 더 입력"
                                            :rules="[rules.required, rules.passwordMatch(passwordData.passwordConfirm, passwordData.password),]"
                                            :type="showPasswordConfirm ? 'text' : 'password'"
                                            @click:append="showPasswordConfirm = !showPasswordConfirm"
                                            dense
                                    ></v-text-field>
                                </v-col>


                                <v-btn
                                        :disabled="!passwordValid"
                                        @click="updateUserPassword(currentUser.id, passwordData)"
                                        width="40%"
                                        rounded
                                        color="primary"
                                        class="text-h9 ma-3"
                                >비밀번호 변경
                                </v-btn>
                                <v-btn
                                        @click="cancelPasswordChange()"
                                        width="40%"
                                        rounded
                                        outlined
                                        color="primary"
                                        class="text-h9"
                                >취소
                                </v-btn>
                            </v-form>

                        </v-card>
                        <v-card
                                class="mx-auto ma-5 pa-5 primary--text"
                                max-width="750"
                                elevation="0"
                                v-show="this.showPasswordCard==2">
                            <v-col cols="10">
                                비밀번호가 성공적으로 변경 되었습니다.
                            </v-col>
                        </v-card>

                        <v-row justify="center" align="center" class="pt-15 mt-0 mb-10">
                            <v-col cols="10" class="text-center">
                                <v-dialog
                                        v-model="dialog"
                                        width="500"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                width="60%"
                                                rounded
                                                outlined
                                                color="primary"
                                                class="text-h6"
                                        >

                                            수정 완료
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="  font-weight-bold grey lighten-2">
                                            회원정보 수정 안내
                                        </v-card-title>


                                        <v-card-text class="pa-5 ml-2 card-text-area">
                                            수정을 진행하시면 과거 정보를 영구 삭제합니다.
                                            <br/>
                                            회원정보 수정을 진행 하시겠습니까?
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    color="primary"
                                                    text
                                                    @click.prevent="updateUserInfo(currentUser.id,currentUser)"
                                            >
                                                동의합니다
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                            </v-col>
                        </v-row>
                    </v-form>

                </v-card>
            </div>
        </div>
        <div v-if="getDefaultLanguage.includes('en')">
            <v-card
                    class="mx-auto  pa-5 primary--text"
                    max-width="750"
                    flat
            >
            <span :class="{
            }"
                  style="font-size: 22px"
            >
                <strong>Account Information</strong>
            </span>
            </v-card>
            <!--        <v-divider></v-divider>-->
            <div>
                <!--            <StudentInfo v-if="studentContent.studentRegisterStatus != 'NOT_STARTED'"></StudentInfo>-->
                <v-card
                        class="mx-auto my-5 primary--text rounded-xl"
                        max-width="750"
                        elevation="5"
                >
                    <!--        {{!enrollmentData}}-->
                    <v-form class="ml-5 mr-5 mt-5 pt-9 pb-5 mb-5"
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                                    <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Email (Username)
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        prepend-icon="mdi-email-outline"
                                        v-model="currentUser.email"
                                        disabled
                                >
                                    Default Slot
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>

                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Contact Information
                                    </span>
                        <v-row>

                            <v-col
                                    cols="12"
                                    md="3"
                            >
                                <v-autocomplete
                                        prepend-icon="mdi-phone"
                                        required
                                        :items="countryCodes"
                                        v-model="currentUser.countryCode"
                                        :rules="[rules.required]"
                                        class="font-weight-thin"
                                        :messages="['Country Code']"
                                        auto-select-first
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col
                                    cols="12"
                                    md="7">
                                <v-text-field

                                        v-model="currentUser.phoneNumber"
                                        :messages="['Phone Number']"
                                >

                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider class="mb-2"></v-divider>

                        <!--<v-icon>$vuetify.icons.custom</v-icon>-->
                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        KakaoTalk account
                                    </span>
                        <v-row>


                            <v-col
                                    cols="12"
                            >
                                <v-text-field
                                        prepend-icon="$vuetify.icons.kakao"
                                        v-model="currentUser.kakaoId"
                                        placeholder="Please enter your Kakao account"
                                >

                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--                    {{currentUser}}-->
                        <v-divider class="mb-2"></v-divider>

                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Birth Information
                                    </span>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                        :messages="['YYYYMMDD (Numbers without \'-\' in between )']"
                                        prepend-icon="mdi-calendar-range"
                                        label=""
                                        v-model="currentUser.birthDate"
                                        required
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-divider class="mb-2"></v-divider>
                        <span :class="{
                                    'text-h6': $vuetify.breakpoint.mdAndDown,
                                    'text-h6': $vuetify.breakpoint.lgAndUp
                                    }">
                                        Change Password
                                    </span>
                        <v-row>
                            <v-col cols="12"
                                   md="7">
                                <v-text-field
                                        :messages="['Current password']"
                                        prepend-icon="mdi-lock-reset"
                                        label=""
                                        v-model="currentUser.currentPassword"
                                        required
                                        dense
                                        :type="showPassword ? 'text' : 'password'"
                                ></v-text-field>

                            </v-col>
                            <v-col cols="12"
                                   md="3">
                                <v-btn
                                        @click="checkCurrentPassword(currentUser.currentPassword)"
                                        width="60%"
                                        rounded
                                        outlined
                                        color="primary"
                                        class="text-h9"

                                >
                                    Confirm password
                                </v-btn>
                            </v-col>

                        </v-row>
                        <span v-show="this.wrongPassword == true">
                        비밀번호가 일치하지 않습니다. 다시 시도하여 주세요.
                        </span>
                        <v-card
                                class="mx-auto ma-5 pa-5 primary--text"
                                max-width="750"
                                elevation="0"
                                v-show="this.passwordMatchInfo == true && this.showPasswordCard==0">
                            <v-form v-model="passwordValid">


                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Password"
                                            required
                                            v-model="passwordData.password"
                                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.MinPassLen, rules.passwordDifferent(passwordData.password, currentUser.currentPassword)]"
                                            :type="showPassword ? 'text' : 'password'"
                                            messages="New Password"
                                            @click:append="showPassword = !showPassword"
                                            dense
                                    ></v-text-field
                                    >

                                </v-col>
                                <v-col cols="10">
                                    <v-text-field
                                            outlined
                                            label="Confirm Password"
                                            required
                                            v-model="passwordData.passwordConfirm"
                                            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required, rules.passwordMatch(passwordData.passwordConfirm, passwordData.password),]"
                                            :type="showPasswordConfirm ? 'text' : 'password'"
                                            @click:append="showPasswordConfirm = !showPasswordConfirm"
                                            dense
                                    ></v-text-field>
                                </v-col>


                                <v-btn
                                        :disabled="!passwordValid"
                                        @click="updateUserPassword(currentUser.id, passwordData)"
                                        width="40%"
                                        rounded
                                        color="primary"
                                        class="text-h9 ma-3"
                                >Save
                                </v-btn>
                                <v-btn
                                        @click="cancelPasswordChange()"
                                        width="40%"
                                        rounded
                                        outlined
                                        color="primary"
                                        class="text-h9"
                                >Cancel
                                </v-btn>
                            </v-form>

                        </v-card>
                        <v-card
                                class="mx-auto ma-5 pa-5 primary--text"
                                max-width="750"
                                elevation="0"
                                v-show="this.showPasswordCard==2">
                            <v-col cols="10">
                                Password changed successfully
                            </v-col>
                        </v-card>

                        <v-row justify="center" align="center" class="pt-15 mt-0 mb-10">
                            <v-col cols="10" class="text-center">
                                <v-dialog
                                        v-model="dialog"
                                        width="500"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                width="60%"
                                                rounded
                                                outlined
                                                color="primary"
                                                class="text-h6"
                                        >

                                            Save
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-card-title class="  font-weight-bold grey lighten-2">
                                            Disclaimer
                                        </v-card-title>


                                        <v-card-text class="pa-5 ml-2 card-text-area">
                                            If you click 'Save', your previous data will be permanently deleted.
                                            <br/>
                                            Do you want to continue?
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                    color="primary"
                                                    text
                                                    @click.prevent="updateUserInfo(currentUser.id,currentUser)"
                                            >
                                                Agree
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                            </v-col>
                        </v-row>
                    </v-form>

                </v-card>
            </div>
        </div>
    </v-container>
</template>

<script>

    import UserService from '../../services/user.service'
    import info from "../../../info";
    import StudentService from '../../services/student.service'
    import TeacherService from '../../services/teacher.service'

    export default {
        name: "EditProfile",
        components: {},
        data() {
            return {
                dialog: false,
                userContent: '',
                currentPassword: '',
                passwordMatchInfo: null,
                showPasswordCard: 1,
                // showPasswordCard
                // 0 = true
                // 1 = false
                // 2 = done
                wrongPassword: false,
                passwordData: {
                    password: "",
                    passwordConfirm: ""
                },
                countryCodes: info.countryCodes,
                showPassword: false,
                showPasswordConfirm: false,
                passwordValid: false,
                valid: false,
                kakaoId: '',
                rules: {
                    required: (value) => !!value || 'Required.',
                    passwordMatch: (v1, v2) => v1 === v2 || 'Passwords Do not Match',
                    MinPassLen: (v) => v.length >= 8 || '9자 이상의 패스워드만 허용 됩니다.',
                    passwordDifferent: (v1, v2) => v1 != v2 || '새로운 패스워드만 가능합니다..',
                },
                studentContent: "",
                teacherContent: "",
            }
        },
        computed: {
            getDefaultLanguage() {
                return window.navigator.userLanguage || window.navigator.language;
            },
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
            if (this.currentUser.roles[0].includes('STUDENT')) {
                StudentService.getStudentInfo(this.currentUser.id).then(
                    (response) => {
                        this.studentContent = response.data;
                        // console.log(this.studentContent.kakaoId == null)
                        if (this.kakaoId == null) {
                            this.kakaoId = this.studentContent.kakaoId;

                        }
                    },
                    (error) => {
                        this.studentContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        if (this.studentContent == 'Access is denied' || this.studentContent.includes('denied') || this.studentContent.includes('401')) {
                            this.logOut();
                        }
                    }
                );
            }

            if (this.currentUser.roles[0].includes('TEACHER')) {
                TeacherService.getTeacherInfo(this.currentUser.id).then(
                    (response) => {
                        this.teacherContent = response.data;
                        if (this.kakaoId == null) {
                            this.kakaoId = this.teacherContent.kakaoId;

                        }
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        if (this.teacherContent == 'Access is denied' || this.teacherContent.includes('denied') || this.teacherContent.includes('401')) {
                            this.logOut();
                        }
                    }
                );
            }

            // this.kakaoId = this.currentUser.kakaoId;
        },
        methods: {
            logOut() {
                this.$store.dispatch('auth/logout');
                this.$router.push('/login');
            },
            cancelPasswordChange() {
                this.passwordMatchInfo = false;
            },

            checkCurrentPassword(password) {
                UserService.checkCurrentPassword(this.currentUser.id, password).then(
                    (response) => {
                        this.passwordMatchInfo = response.data;
                        if (!this.passwordMatchInfo) {
                            this.wrongPassword = true;
                        } else if (this.passwordMatchInfo) {
                            this.showPasswordCard = 0;
                            this.wrongPassword = false;
                        }
                        return this.passwordMatchInfo;
                    },
                    (error) => {
                        this.passwordMatchInfo =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },

            updateUserInfo(userId, requestBody) {
                this.userContent = "";
                console.log(requestBody)
                this.userContent = {
                    email: requestBody.email,
                    kakaoId: this.kakaoId,
                    phoneNumber: requestBody.phoneNumber,
                    birthDate: requestBody.birthDate
                },
                    UserService.updateUserInfo(userId, this.userContent).then(
                        (response) => {
                            this.userContent = response.data;
                            this.$router.push("/profile")
                        },
                        (error) => {
                            this.userContent =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            console.log("error")
                            console.log(this.userContent)
                            console.log("error")
                        }
                    )
            },


            updateUserPassword(userId, requestBody) {
                const pwRequestBody = {
                    password: requestBody.password
                }
                UserService.updateUserInfo(userId, pwRequestBody).then(
                    (response) => {
                        this.userContent = response.data;
                        if (response.status == 200) {
                            // console.log("wea re the world")
                            // console.log(response)
                            // console.log("---------------")
                            this.showPasswordCard = 2;
                        }

                    },
                    (error) => {
                        this.userContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log("error")
                        console.log(this.userContent)
                        console.log("error")
                    }
                )
            },

            updateTeacherMatchingActivateInfo(userId, requestBody) {
                requestBody.matchingActivated = true;

                TeacherService.updateTeacherMatchingActivateStatus(userId, requestBody).then(
                    (response) => {
                        this.teacherContent = response.data;
                        // this.$router.push("/teacher/verify")
                        this.next = true
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            }

        }

    }
</script>

<style scoped>
    .card-text-area {
        height: 130px;
    }
</style>
