<template>
    <v-container class="" style="">
        <div class="pa-4 font-weight-bold " style=";font-size: 30px; margin-top: 80px; margin-bottom: 25px;">Our
            Courses
        </div>
        <v-card class="pa-6 "  elevation="" color="" flat style="background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 20%, rgba(255, 255, 255, 0) 0%)">

            <!--            <h1>CSS + HTML only Accordion Element</h1>sf-->
<!--            background-color: #10192C-->
            <ul class="pa-2" style="">
                <li style="">
                    <input type="checkbox" style="" >
                    <i></i>
                    <h2 class="font-weight-bold" style="">Computer Science</h2>
                    <!--                   asdf <div class="ml-10">-->
<!--                    167C9F-->

                    <p style="font-size: 22px; color: #4f6d7a" class="font-weight-bold" >Advanced
                        <br/>
                        <span class="ml-5 font-weight-light " style="font-size: 18px; color: #414141">
                                AP CS Principles<br/>
                        </span>
                        <span class="ml-5 font-weight-light" style="font-size: 18px; color: #414141">
                                AP CS A<br/>
                        </span>
                        <br/>
                    <span style="font-size: 25px; color: #4f6d7a" class="" >Intermediate</span>
                        <br/>
                        <span class="ml-5 font-weight-light" style="font-size: 18px; color: #414141">CS & Programming</span><br/>
                        <span class="ml-5 font-weight-light" style="font-size: 18px;color: #414141">
                                Robotics<br/>
                        </span>
                    </p>
                </li>
                <li style="">

                    <input type="checkbox" checked>
                    <i></i>
                    <h2 class="font-weight-bold"  style="">Mathematics</h2>
                    <p style="font-size: 25px; color: #FEA53C" class="">Advanced
                        <br/>
                        <span class="ml-5" style="font-size: 18px">
                                Multi-variate Calculus<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Calc AB<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Calc BC<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Statistics<br/>
                        </span>
                        <br/>
                        <span style="font-size: 25px" class="">Intermediate</span>
                        <br/>
                        <span class="ml-5" style="font-size: 18px">Calculus</span><br/>
                        <span class="ml-5" style="font-size: 18px">
                                Pre-calculus<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Algebra<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Geometry<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Statistics<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                SAT<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Math (Middle School)<br/>
                        </span>
                    </p>
                </li>
                <li style="">
                    <input type="checkbox" checked>
                    <i></i>
<!--                    AP Physics 1 <br/>&ndash;&gt;-->
                    <!--                            AP Physics 2 <br/>-->
                    <!--                            AP Physics C Mech <br/>-->
                    <!--                            AP Physics C E&M <br/>-->
                    <!--                            AP Chemistry <br/>-->
                    <!--                            AP Biology <br/>-->
                    <!--                            AP Environmental Science <br/>-->
                    <!--                        </div>-->
                    <!--                        <div style="font-size: 25px" class="">Intermediate</div>-->
                    <!--                        <div class="ml-5">-->
                    <!--                            Physics <br/>-->
                    <!--                            Chemistry <br/>-->
                    <!--                            Biology <br/>-->
                    <!--                            Astronomy <br/>-->
                    <!--                            Environmental Science <br/>-->
                    <!--                            Science (Middle School) <br/>-->
                    <h2 class="font-weight-bold">Science</h2>
                    <p style="font-size: 25px" class="">Advanced
                        <br/>
                        <span class="ml-5" style="font-size: 18px">
                                AP Physics 1<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Physics 2<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Calc BC<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Physics C Mech<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Physics C E&M<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Chemistry<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Biology<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                AP Environmental Science<br/>
                        </span>
                        <br/>
                        <span style="font-size: 25px" class="">Intermediate</span>
                        <br/>
                        <!--                            Physics <br/>-->
                        <!--                            Chemistry <br/>-->
                        <!--                            Biology <br/>-->
                        <!--                            Astronomy <br/>-->
                        <!--                            Environmental Science <br/>-->
                        <!--                            Science (Middle School) <br/>-->
                        <span class="ml-5" style="font-size: 18px">Physics</span><br/>
                        <span class="ml-5" style="font-size: 18px">
                                Chemistry<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Biology<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Astronomy<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Environmental Science<br/>
                        </span>
                        <span class="ml-5" style="font-size: 18px">
                                Science (Middle School)<br/>
                        </span>
<!--                        <span class="ml-5" style="font-size: 18px">-->
<!--                                Math (Middle School)<br/>-->
<!--                        </span>-->
                    </p>
                </li>
            </ul>
        </v-card>
        <div class="py-10"></div>
        <v-card elevation="10" class="rounded-xl">
            <section class="dl-blurbs " style="" >
                <dl>
                    <div style="font-size: 30px; color: #4f6d7a" class="pt-3">CS</div>
                    <div class="ml-10">
                        <div style="font-size: 25px" class="">Advanced</div>
                        <div class="ml-5">
                            AP CS Principles <br/>
                            AP CS A <br/>
                        </div>
                        <div style="font-size: 25px" class="">Intermediate</div>
                        <div class="ml-5">
                            CS & Programming <br/>
                            Robotics <br/>
                        </div>
                    </div>
                    <div style="font-size: 30px; color: #4f6d7a">Mathematics</div>
                    <div class="ml-10">
                        <div style="font-size: 25px" class="">Advanced</div>
                        <div class="ml-5">
                            Multi-variate Calculus <br/>
                            AP Calc AB <br/>
                            AP Calc BC <br/>
                            AP Statistics
                            <br/>
                        </div>
                        <div style="font-size: 25px" class="">Intermediate</div>
                        <div class="ml-5">
                            Calculus <br/>
                            Pre-calculus <br/>
                            Algebra <br/>
                            Geometry <br/>

                            Statistics <br/>
                            SAT <br/>
                            Math (Middle School) <br/></div>
                    </div>

                    <!--            <dt>Test</dt>-->
                    <!--            <dd>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.</dd>-->
                </dl>
                <dl>
                    <div style="font-size: 30px; color: #4f6d7a" class="pt-3">Science</div>

                    <div class="ml-10 " style="">
                        <div style="font-size: 25px" class="">Advanced</div>
                        <div class="ml-5">
                            AP Physics 1 <br/>
                            AP Physics 2 <br/>
                            AP Physics C Mech <br/>
                            AP Physics C E&M <br/>
                            AP Chemistry <br/>
                            AP Biology <br/>
                            AP Environmental Science <br/>
                        </div>
                        <div style="font-size: 25px" class="">Intermediate</div>
                        <div class="ml-5">
                            Physics <br/>
                            Chemistry <br/>
                            Biology <br/>
                            Astronomy <br/>
                            Environmental Science <br/>
                            Science (Middle School) <br/>
                        </div>
                    </div>
                    <!--            <dt>Rinse</dt>-->
                    <!--            <dd>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.</dd>-->
                    <!---->
                    <!--            <dt>Repeat</dt>-->
                    <!--&lt;!&ndash;&ndash;&gt;            <dd>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet.</dd>-->
                </dl>
            </section>

        </v-card>
    </v-container>

</template>

<script>
    export default {
        name: "CourseIntro"
    }
</script>

<style scoped>
    @import url(&#39;
    https://fonts.googleapis.com/css2?family=Abril+Fatface&amp;
    display=swap&#39;
    );
    @import url(&#39;
    https://fonts.googleapis.com/css2?family=Montserrat&amp;
    display=swap&#39;
    );
    - Screen Sizes
     COLOR HEX
     COLOR Gradient
    import fonts
    media queries
    html {
        box-sizing: border-box;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        background-color: #eaeaea;
        /*font-family: &#39;*/
        /*Montserrat&#39;*/
        font-size: 0.875em;
        color: #444;
        line-height: 1.5;
    }

    .dl-blurbs {
        display: grid;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    @media (min-width: 768px) {
        .dl-blurbs {
            grid-template-columns: repeat(2, 1fr);
            gap: 2vw;
        }
    }

    /* LIST */
    dl {
        counter-reset: count;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 20%, rgba(255, 255, 255, 0) 0%);
        padding-left: 4vw;
        padding-right: 4vw;
        margin: 0;
    }

    @media (min-width: 768px) {
        dl {
            padding-left: 2vw;
        }
    }

    @media (min-width: 1024px) {
        dl {
            padding-top: 3vw;
            padding-bottom: 3vw;
        }
    }

    dl + dl {
        counter-reset: counter 4;
    }

    dt {
        counter-increment: count;
        color: #4f6d7a;
        /*font-family: &#39;*/
        /*Montserrat&#39;*/
        font-size: 4vw;
        text-transform: uppercase;
    }

    dt::before {
        content: counter(count, decimal-leading-zero);

        color: #5ea5b8;
        margin-left: -1vw;
        padding-right: 12px;
    }

    dd {
        margin-left: 5vw;
        margin-bottom: 2vh;
    }

    .transition, ul li i:before, ul li i:after, p {
        transition: all 0.25s ease-in-out;
    }

    .flipIn, ul li, h1 {
        animation: flipdown 0.5s ease both;
    }

    .no-select, h2 {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    html {
        width: 100%;
        height: 100%;
        perspective: 900;
        overflow-y: scroll;
        background-color: #dce7eb;
        font-family: "Titillium Web", sans-serif;
        color: rgba(48, 69, 92, 0.8);
    }

    body {
        min-height: 0;
        display: inline-block;
        position: relative;
        left: 50%;
        margin: 90px 0;
        transform: translate(-50%, 0);
        box-shadow: 0 10px 0 0 #ff6873 inset;
        background-color: #fefffa;
        max-width: 450px;
        padding: 30px;
    }

    @media (max-width: 550px) {
        body {
            box-sizing: border-box;
            transform: translate(0, 0);
            max-width: 100%;
            min-height: 100%;
            margin: 0;
            left: 0;
        }
    }

    h1, h2 {
        color: #000;

    }

    h1 {
        text-transform: uppercase;
        font-size: 36px;
        line-height: 42px;
        letter-spacing: 3px;
        font-weight: 100;
    }

    h2 {
        font-size: 26px;
        line-height: 34px;
        font-weight: 300;
        letter-spacing: 1px;
        display: block;
        background-color: transparent;
        margin: 0;
        cursor: pointer;

        /*background-color: #10192C*/
    }

    p {
        color: rgba(48, 69, 92, 0.8);
        font-size: 17px;
        line-height: 26px;
        letter-spacing: 1px;
        position: relative;
        overflow: hidden;
        max-height: 800px;
        opacity: 1;
        transform: translate(0, 0);
        margin-top: 14px;
        z-index: 2;
    }

    ul {
        list-style: none;
        perspective: 900;
        padding: 0;
        margin: 0;
    }

    ul li {
        position: relative;
        padding: 0;
        margin: 0;
        padding-bottom: 4px;
        padding-top: 18px;
        /*border-top: 1px dotted #dce7eb;*/

    }

    ul li:nth-of-type(1) {
        animation-delay: 0.5s;
    }

    ul li:nth-of-type(2) {
        animation-delay: 0.75s;
    }

    ul li:nth-of-type(3) {
        animation-delay: 1s;
    }

    ul li:last-of-type {
        padding-bottom: 0;
    }

    ul li i {
        position: absolute;
        transform: translate(-6px, 0);
        margin-top: 16px;
        right: 0;
    }

    ul li i:before, ul li i:after {
        content: "";
        position: absolute;
        background-color: #ff6873;
        width: 3px;
        height: 9px;
    }

    ul li i:before {
        transform: translate(-2px, 0) rotate(45deg);
    }

    ul li i:after {
        transform: translate(2px, 0) rotate(-45deg);
    }

    ul li input[type=checkbox] {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
    }

    ul li input[type=checkbox]:checked ~ p {
        margin-top: 0;
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
    }

    ul li input[type=checkbox]:checked ~ i:before {
        transform: translate(2px, 0) rotate(45deg);
    }

    ul li input[type=checkbox]:checked ~ i:after {
        transform: translate(-2px, 0) rotate(-45deg);
    }

    @keyframes flipdown {
        0% {
            opacity: 0;
            transform-origin: top center;
            transform: rotateX(-90deg);
        }
        5% {
            opacity: 1;
        }
        80% {
            transform: rotateX(8deg);
        }
        83% {
            transform: rotateX(6deg);
        }
        92% {
            transform: rotateX(-3deg);
        }
        100% {
            transform-origin: top center;
            transform: rotateX(0deg);
        }
    }
</style>
