<template>
    <v-container style=""  fluid>
<!--        <div class="pl-5  font-weight-bold" style="font-size: 30px">-->


<!--        </div>-->

        <div class="animated-title px-2" style="font-family: Roboto, sans-serif; padding-top: 0px !important;">

            <div class="text-top">
                <div>
                    <span class=" mb-3" >INTRODUCTION</span>
                    <span style="line-height: 1.2">코로나를 맞이하면서 대학 입시에 내신 비중이 점점 커지는 추세입니다.</span>
<!--                    <span style="color: #222 !important;"></span>-->
<!--                    <span></span>-->
                </div>
            </div>
            <div class="text-bottom" style="padding-bottom: 0px !important;">
                <div style="line-height: 1.2">학교 quiz, homework, projects, exams들을 대비하기 위해 꾸준한 맞춤 학습관리 니즈가 증가되어 <br/><br/> 언제 어디서나 체계적인 학습 코칭 서비스를 제공하고자 IVYED를 출시하게 되었습니다.</div>
            </div>

        </div>
    </v-container>
</template>

<script>
    export default {
        name: "FounderIntro"
    }
</script>

<style xml:lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css?family=Roboto:700');
    @keyframes showTopText {
        0% { transform: translate3d(0, 100%, 0); }
        40%, 60% { transform: translate3d(0, 50%, 0); }
        100% { transform: translate3d(0, 0, 0); }
    }
    @keyframes showBottomText {
        0% { transform: translate3d(0, -100%, 0); }
        100% { transform: translate3d(0, 0, 0); }
    }
    .animated-title {
        color: #222;
        /*left: 50%;*/
        /*height: 200px;*/
        /*font-family: Roboto, Arial, sans-serif;*/
        /*height: 90vmin;*/
        /*left: 50%;*/
        position: relative;
        /*top: 50%;*/
        /*transform: translate(-50%, -50%);*/
        /*width: 90vmin;*/
    }
    .animated-title > div {
        /*height: 50%;*/
        overflow: auto;
        position: relative;
        /*height: 210px;*/
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        /*width: 100%;*/
    }
    .animated-title > div div {
        font-size: 22px;
        /*font-size: 8vmin;*/
        font-weight: bold;
        padding: 2vmin 0;
        position: absolute;

    }
    .animated-title > div div span {
        display: block;
    }
    .animated-title > div.text-top {
        border-bottom: 1vmin solid #000;
        /*top: -70px;*/
        /*bottom: 100px;*/
        height: 110px;
        padding-top: 0px !important;
        margin-top: 0px !important;
    }
    .animated-title > div.text-top div {
        animation: showTopText 1s;
        animation-delay: 0.7s;
        animation-fill-mode: forwards;
        bottom: 0;
        transform: translate(0, 100%);
    }
    .animated-title > div.text-top div span:first-child {
        color: #767676;
    }
    .animated-title > div.text-bottom {
        /*bottom: 70px;*/
        height: 205px;
    }
    .animated-title > div.text-bottom div {
        animation: showBottomText 0.5s;
        animation-delay: 1.75s;
        animation-fill-mode: forwards;
        top: 0;
        transform: translate(0, -100%);
    }

</style>
