<template>
    <v-container fluid style="background-color: #F2F3F4;">
        <v-container class="new-container">
            <v-row
                    align=""
                    class="mt-3"
                    :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                <v-col>
                    <p class="font-weight-bold black--text pb-5" style="font-size: 36px;">Why IVYED?</p>
                </v-col>
            </v-row>
        </v-container>
        <div>


            <v-container style="max-width: 500px">
                <v-row dense>
                    <v-col cols="12">
                        <v-card
                                min-height="130px"
                                max-width="500px"
                                color="#F7F8FA"
                                dark
                                class="mb-10 center-vertical"
                                width="95%"
                                elevation="10"
                                style="margin-left: -50px; border-radius: 80px; border-bottom-left-radius: 0; border-top-left-radius: 0px;"
                        >

                            <v-card-title class="font-weight-bold black--text mb-1"
                                          style="font-size: 22px;margin-left:30px; margin-right: 25px; padding-bottom: 0px !important;">
                                Learning Management
                            </v-card-title>

                            <v-card-text class=""
                                         style="color: #535353; font-size: 16px; margin-left:30px; margin-right: 100px; padding-right: 80px;">
                                With feedback report and class recording from each class, we make sure what was learned in class truly becomes part of our students.
                            </v-card-text>
                            <!--                            <v-card-actions>-->
                            <!--                                <v-btn text>-->
                            <!--                                    Listen Now-->
                            <!--                                </v-btn>-->
                            <!--                            </v-card-actions>-->
                        </v-card>
                        <div class="py-5"></div>

                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <v-card
                                max-width="500px"
                                min-height="130px"
                                color="#F7F8FA"
                                dark
                                class="mb-13 center-vertical"
                                elevation="10"
                                width="95%"
                                style="border-top-left-radius: 80px;
                                border-bottom-left-radius: 80px;
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;
                                                                                float: right; margin-right: -24px ;

                                overflow: hidden;
                                ;
                                "
                        >
                            <!--                            float: right; margin-right: -23px-->
                            <div style="">
                                <v-card-title class="ml-10 black--text font-weight-bold mb-1"
                                              style="padding-left: 15px; font-size: 22px; padding-bottom: 0px !important;">
                                    Focusing On Weaknesses
                                </v-card-title>

                                <v-card-text class="ml-10  pr-12"
                                             style="font-size: 16px; color: #535353; padding-left: 15px; padding-top: 0px !important;">
                                    Everyone is different, and we respect that. By tailoring each class to a student's true weaknesses, we guarantee score improvements in any courses and exam preps whether that be AP, IB, SATs and more.

                                </v-card-text>

                            </div>

                        </v-card>

                    </v-col>
                </v-row>
                <div class="py-5"></div>

                <v-row dense>
                    <v-col cols="12">
                        <v-card
                                max-width="500px"
                                color="#F7F8FA"
                                dark
                                class="mb-10 center-vertical"
                                width="95%"
                                elevation="10"
                                style="margin-left: -50px; border-radius: 80px; border-bottom-left-radius: 0; border-top-left-radius: 0px"
                                min-height="130px"
                        >

                            <v-card-title class="font-weight-bold black--text mb-1"
                                          style="margin-left:30px; margin-right: 25px; font-size: 22px; padding-bottom: 0px !important;">
                                During school semesters
                            </v-card-title>

                            <v-card-text class=""
                                         style="color: #535353; font-size: 16px; margin-left:30px; margin-right: 100px; padding-right: 80px">

                                As every class is online, you can get help with anything and everything at anytime of the year.
                                You can ask for help with anything to your tutor whether that be a school subject or an exam preparation.
                            </v-card-text>
                        </v-card>
                        <div class="py-5"></div>

                    </v-col>
                </v-row>


                <v-row dense class="mb-4">
                    <v-col cols="12">
                        <v-card
                                min-height="130px"
                                max-width="500px"
                                color="#F7F8FA"
                                dark
                                class="center-vertical"

                                elevation="10"
                                width="95%"
                                style="border-top-left-radius: 80px;
                                                border-bottom-left-radius: 80px;
                                                border-top-right-radius: 0px;
                                                border-bottom-right-radius: 0px;
                                                float: right; margin-right: -24px ;
                                                margin-bottom: 65px;
                                                overflow: hidden"
                        >
                            <div style="" class="">

                                <v-card-title class="ml-10  mb-1 black--text font-weight-bold"
                                              style="padding-left: 15px; font-size: 22px; padding-bottom: 0px !important;">
                                    We Guarantee Quality
                                </v-card-title>
                                <v-card-text class="ml-10  pr-12"
                                             style="font-size: 16px; color: #535353; padding-left: 15px; padding-top: 0px !important;">

                                    To guarantee highest quality, we have a 100% refund policy for any unsatisfaction.

                                </v-card-text>

                            </div>

                        </v-card>
                        <div class="py-10"></div>

                    </v-col>
                </v-row>
            </v-container>



            <!-- SOCIAL PANEL HTML -->
            <div class="social-panel-container">
                <div class="social-panel">
                    <p>Created with <i class="fa fa-heart"></i> by
                        <a target="_blank" href="https://florin-pop.com">Florin Pop</a></p>
                    <button class="close-btn"><i class="fas fa-times"></i></button>
                    <h4>Get in touch on</h4>
                    <ul>
                        <li>
                            <a href="https://www.patreon.com/florinpop17" target="_blank">
                                <i class="fab fa-discord"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/florinpop1705" target="_blank">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://linkedin.com/in/florinpop17" target="_blank">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://facebook.com/florinpop17" target="_blank">
                                <i class="fab fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/florinpop17" target="_blank">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--            <button class="floating-btn">-->
            <!--                Get in Touch-->
            <!--            </button>-->

            <!--            <div class="floating-text">-->
            <!--                Part of <a href="https://florin-pop.com/blog/2019/09/100-days-100-projects" target="_blank">#100Days100Projects</a>-->
            <!--            </div>-->
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "WhyIvyed"
    }
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

    * {
        box-sizing: border-box;
    }


    body {
        background-image: linear-gradient(45deg, #7175da, #9790F2);
        font-family: 'Muli', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        margin: 0;
    }

    .courses-container {
    }

    .course {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        max-width: 100%;
        margin: 20px;
        overflow: hidden;
        width: 700px;
    }

    .course h6 {
        opacity: 0.6;
        margin: 0;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .course h2 {
        letter-spacing: 1px;
        margin: 10px 0;
    }

    .course-preview {
        background-color: #2A265F;
        color: #fff;
        padding: 30px;
        max-width: 250px;
    }

    .course-preview a {
        color: #fff;
        display: inline-block;
        font-size: 12px;
        opacity: 0.6;
        margin-top: 30px;
        text-decoration: none;
    }

    .course-info {
        padding: 30px;
        position: relative;
        width: 100%;
    }

    .progress-container {
        position: absolute;
        top: 30px;
        right: 30px;
        text-align: right;
        width: 150px;
    }

    .progress {
        background-color: #ddd;
        border-radius: 3px;
        height: 5px;
        width: 100%;
    }

    .progress::after {
        border-radius: 3px;
        background-color: #2A265F;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 5px;
        width: 66%;
    }

    .progress-text {
        font-size: 10px;
        opacity: 0.6;
        letter-spacing: 1px;
    }

    .btn {
        background-color: #2A265F;
        border: 0;
        border-radius: 50px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        color: #fff;
        font-size: 16px;
        padding: 12px 25px;
        position: absolute;
        bottom: 30px;
        right: 30px;
        letter-spacing: 1px;
    }

    /* SOCIAL PANEL CSS */
    .social-panel-container {
        position: fixed;
        right: 0;
        bottom: 80px;
        transform: translateX(100%);
        transition: transform 0.4s ease-in-out;
    }

    .social-panel-container.visible {
        transform: translateX(-10px);
    }

    .social-panel {
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0 16px 31px -17px rgba(0, 31, 97, 0.6);
        border: 5px solid #001F61;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Muli';
        position: relative;
        height: 169px;
        width: 370px;
        max-width: calc(100% - 10px);
    }

    .social-panel button.close-btn {
        border: 0;
        color: #97A5CE;
        cursor: pointer;
        font-size: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .social-panel button.close-btn:focus {
        outline: none;
    }

    .social-panel p {
        background-color: #001F61;
        border-radius: 0 0 10px 10px;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        padding: 2px 17px 6px;
        position: absolute;
        top: 0;
        left: 50%;
        margin: 0;
        transform: translateX(-50%);
        text-align: center;
        width: 235px;
    }

    .social-panel p i {
        margin: 0 5px;
    }

    .social-panel p a {
        color: #FF7500;
        text-decoration: none;
    }

    .social-panel h4 {
        margin: 20px 0;
        color: #97A5CE;
        font-family: 'Muli';
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
    }

    .social-panel ul {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .social-panel ul li {
        margin: 0 10px;
    }

    .social-panel ul li a {
        border: 1px solid #DCE1F2;
        border-radius: 50%;
        color: #001F61;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 50px;
        text-decoration: none;
    }

    .social-panel ul li a:hover {
        border-color: #FF6A00;
        box-shadow: 0 9px 12px -9px #FF6A00;
    }

    .floating-btn {
        border-radius: 26.5px;
        background-color: #001F61;
        border: 1px solid #001F61;
        box-shadow: 0 16px 22px -17px #03153B;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        padding: 12px 20px;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
    }

    .floating-btn:hover {
        background-color: #ffffff;
        color: #001F61;
    }

    .floating-btn:focus {
        outline: none;
    }

    .floating-text {
        background-color: #001F61;
        border-radius: 10px 10px 0 0;
        color: #fff;
        font-family: 'Muli';
        padding: 7px 15px;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 998;
    }

    .floating-text a {
        color: #FF7500;
        text-decoration: none;
    }

    @media screen and (max-width: 480px) {

        .social-panel-container.visible {
            transform: translateX(0px);
        }

        .floating-btn {
            right: 10px;
        }
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
</style>
