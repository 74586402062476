<template>
    <v-container fluid class="faq-page">
        <p class="justify-center sub-main-01 main-color py-10">자주 묻는 질문</p>
        <div class="faq-container">
            <nav class="accordion arrows">
                <!--        <header class="box">-->
                <!--            <label for="acc-close" class="box-title">Accordion menu</label>-->
                <!--        </header>-->
                <h1 class="ma-3">TOP</h1>
<!--                <input type="radio" name="accordion" id="cb0"/>-->
<!--                <section class="box">-->
<!--                    <label class="box-title" for="cb0"><span class="question-text"-->
<!--                                                             :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                    ><span class="primary&#45;&#45;text">Q.</span> 어떤 과목 수업을 제공하나요?</span></label>-->
<!--                    <label class="box-close" for="acc-close"></label>-->
<!--                    <div class="box-content">-->
<!--                        현재 수학 과학 계열만 제공하고 있으며 자세한 과목은 아래 참고 부탁드립니다.-->
<!--                        <br/>-->
<!--                        <br/>-->
<!--                        <h2 class="font-weight-bold" style="font-size: 18px">Mathematics / CS</h2>-->
<!--                        AP CS Principles <br/>-->
<!--                        AP CS A <br/>-->
<!--                        CS & Programming <br/>-->
<!--                        Robotics <br/>-->
<!--                        Multi-variate Calculus <br/>-->
<!--                        AP Calc AB <br/>-->
<!--                        AP Calc BC <br/>-->
<!--                        Calculus <br/>-->
<!--                        Pre-calculus <br/>-->
<!--                        Algebra <br/>-->
<!--                        Geometry <br/>-->
<!--                        AP Statistics <br/>-->
<!--                        Statistics <br/>-->
<!--                        SAT <br/>-->
<!--                        Math (Middle School)-->
<!--                        <br/>-->
<!--                        <br/>-->

<!--                        <h2 class="font-weight-bold" style="font-size: 18px">Science</h2>-->

<!--                        AP Physics 1 <br/>-->
<!--                        AP Physics 2 <br/>-->
<!--                        AP Physics C Mech <br/>-->
<!--                        AP Physics C E&M <br/>-->
<!--                        Physics <br/>-->
<!--                        AP Chemistry <br/>-->
<!--                        Chemistry <br/>-->
<!--                        AP Biology <br/>-->
<!--                        Biology <br/>-->
<!--                        AP Environmental Science <br/>-->
<!--                        Astronomy <br/>-->
<!--                        Environmental Science <br/>-->
<!--                        Science (Middle School) <br/>-->
<!--                    </div>-->

<!--                </section>-->
                <input type="radio" name="accordion" id="cb1"/>
                <section class="box">
                    <label class="box-title" for="cb1"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> IVYED는 어떤 점이 좋은가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        <span class="font-italic font-weight-bold">ANYWHERE</span><br/>
                        학생과 선생님이 각자 원하는 장소에서 태블릿으로 수업을 진행합니다.
                        <br/>
                        <br/>
                        <span class="font-italic font-weight-bold">TOP CLASS TUTORS</span><br/>
                        아이비리그 및 미국 최상위 명문대 선생님들을 만나 볼 수 있습니다.
                        <br/>
                        <br/>

                        <span class="font-italic font-weight-bold">SMART MATCHING</span><br/>

                        학생의 요청사항을 맞춰 나에게 딱 맞는 선생님을 배정해드립니다.
                    </div>
                </section>
                <input type="radio" name="accordion" id="cb2"/>
                <section class="box">
                    <label class="box-title" for="cb2"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 선생님 매칭은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        선생님 매칭은 '무료 체험수업 신청서' 제출이 완료되면 시작됩니다!
                        <br/>
                        <br/>
                        회원가입 후 '무료 체험수업 신청서'를 작성할 때, 원하는 선생님 스타일과 학생의 정보 (재학중인 학교, 희망 전공 계열, 선호 수업시간대 등)을 꼼꼼히 작성해주시면 학생에게 맞는 최적의 선생님을 매칭해 드리고있습니다.
                        <br/>
                        <br/>
                        선생님 매칭은 신청서 제출 후 영업일 기준 3일 정도 소요됩니다.
                        <br/>
                        <br/>
                        단, 수업 가능 시간대가 너무 적거나 매칭이 어려운 조건으로 요청하실 경우 매칭기간이 좀 더 소요될 수 있으며, 이 경우 사전에 안내를 드리고 있습니다.
                    </div>
                </section>
                <input type="radio" name="accordion" id="cb3"/>
                <section class="box">
                    <label class="box-title" for="cb3"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 과외 횟수나 시간을 선택할 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content hidden-md-and-up">
                        IVYED의 과외 선생님들은 <br/>Harvard, Stanford, Yale, MIT 등 <br/>미국 상위 1% 대학교 출신의 재학, 휴학, <br/> 졸업하신
                        선생님들로 이루어져 있습니다.
                        <br/>이 최소 조건 외에도 과외 경력과 교습능력 <br/>
                        등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.
                        <br/>
                        <br/>
                        재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊
                    </div>
                    <div class="box-content hidden-sm-and-down">
                        IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업하신 선생님들로 이루어져
                        있습니다.<br/><br/>
                        이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.<br/><br/>

                        재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb4"/>
                <section class="box">
                    <label class="box-title" for="cb4"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> 선생님을 중간에 바꿀 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content hidden-md-and-up">
                        🙆🏻 ‍️IVYED는 학생이 <br/>'학생정보 입력 및 수업 신청서'에
                        <br/>적어주신 내용을 반영하여 선생님을 매칭해 드리고 있습니다!
                        <br/><br/>매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.

                        <br/><br/>단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.
                        IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다! 😀
                    </div>
                    <div class="box-content hidden-sm-and-down">
                        🙆🏻 ‍️IVYED는 학생이 <br/>'학생정보 입력 및 수업 신청서'에
                        매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.<br/><br/>

                        단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.<br/>
                        IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다! 😀
                    </div>
                    <br/>
                    <br/>
                </section>
                <div class="py-5"></div>

                <h1 class="ma-3">수업방식</h1>
                <input type="radio" name="accordion" id="cb5"/>
                <section class="box">
                    <label class="box-title" for="cb5"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 수강신청 이후에는 무엇을 해야 하나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED에 무료 체험수업 신청(회원가입, 신청서 작성)을 해주셨다면 안내에 따라 다음 순서대로 진행해 주세요!
                        <br/>
                        <br/>
                        1️⃣ 회원가입 및 '무료 체험수업 신청서' 작성
                        체험수업 신청을 완료하시면 학생에게 잘 맞을 것 같은 선생님 매칭이 시작 됩니다.
                        원하는 선생님 스타일과 학생의 정보 (재학중인 학교, 희망 전공 계열, 선호 수업 시간대 등)을 꼼꼼히 작성해 주시면 됩니다.
                        이 신청서가 작성되어야 이후 단계가 진행되오니 빠른 작성 부탁드립니다.
                        (수업이 가능한 시간대를 최대한 많이 적어주실수록 더욱 빠르게 선생님이 매칭됩니다!)
                        <br/>
                        <br/>

                        2️⃣ 선생님 매칭
                        무료 체험수업 신청서에 적어주신 내용을 기반으로 한 분 한 분 꼼꼼히 대조하여 선생님을 매칭해 드리고 있으며 신청서 제출 후 영업일 기준 3일 정도 소요됩니다.
                        <br/>
                        <br/>

                        3️⃣ 수업 준비 및 수업 일정 확정
                        선생님 매칭이 완료되면 IVYED의 상단 메뉴 > [수업] 에서 매칭된 선생님의 정보를 확실 수 있습니다.
                        <br/>
                        <br/>

                        4️⃣ 수업 시작!🎉
                        선생님과 일정까지 잡았다면 첫 수업일을 잘 기억해서 수업을 시작하시면 됩니다.
                        IVYED 상단 메뉴 > [수업]에서 과외방 입장하기 버튼을 클릭하여 수업을 시작하시면 됩니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb6"/>
                <section class="box">
                    <label class="box-title" for="cb6"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 성적이 좋지 않아도 해도 될까요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍ IVYED는 1:1 맞춤형 과외 서비스로 학생이 원하는 수준과 진도에 맞춰서 수업을 진행할 수 있습니다!
                        <br/>
                        <br/>
                        따라서, 현재 학년에 비해 진도를 따라잡기 어렵거나 이해가 부족하다고 생각하신다면 IVYED 1:1 맞춤형 과외가 효과적입니다.
                        <br/>
                        <br/>
                        1:1 과외이기에 학생의 수준과 진도에 맞춰서 최대한 빠르게 약점 보완과 실력 향상을 도와드리고 있습니다.👍
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb7"/>
                <section class="box">
                    <label class="box-title" for="cb7"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 선행 학습도 가능한가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍ IVYED는 1:1 맞춤형 과외 서비스로 학생이 원하는 수준과 진도에 맞춰서 수업을 진행할 수 있습니다!
                        <br/>
                        <br/>
                        과외신청서 작성 시에 학생이 요청한 학습 내용이 반영된 커리큘럼으로 과외가 가능하며, 그에 맞게 1:1 과외 선생님을 배정해드리고 있습니다.
                        <br/>
                        <br/>
                        수업 진행 중 선행 학습으로 변경하고 싶다면 선생님께 요청하여 유연하게 학습 난이도와 진도를 관리할 수 있습니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb8"/>
                <section class="box">
                    <label class="box-title" for="cb8"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 일반 교재로 과외를 진행해도 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻 IVYED는 1:1 맞춤형 과외 서비스로 학생이 원하는 교재를 선정해 수업을 진행할 수 있습니다. 선정하신 교재를 구입하면 선생님과 해당 교재를 이용하여 태블릿으로
                        수업 진행이 가능합니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb9"/>
                <section class="box">
                    <label class="box-title" for="cb9"><span class="question-text"
                                                             :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 학교 자료나 프린트물도 과외가 가능한가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍ IVYED 1:1 맞춤형 과외 서비스로 학생이 원하는 수준과 진도에 맞춰서 수업을 진행할 수 있습니다!
                        <br/>
                        <br/>
                        원하시는 교재나 자료가 있는 경우, 선생님과 조율 후 수업에 활용해 주시면 됩니다!
                        <br/>
                        <br/>
                        학교 자료나 프린트물과 같은 개인 학습자료도 선생님에게 사진으로 공유한 뒤 선생님께 수업 시 개념 설명 또는 추가 문제풀이 요청이 가능합니다. 😊
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb10"/>
                <section class="box">
                    <label class="box-title" for="cb10"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 추가적인 학습관리도 해주시나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED는 주기적인 수업 만족도를 실시하며 불만족하는 상황이 발행할 경우 별도 조치를 통해 만족도를 개선하고 있습니다.
                        <br/>
                        <br/>
                        과외 진행 중 갑자기 IVYED에게 연락이 왔다면 학생분의 수업 만족도를 향상시키기 위함이니 협조 부탁드립니다😊
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb11"/>
                <section class="box">
                    <label class="box-title" for="cb11"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 수업 내용 다시 보기가 가능한가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍️수업 당시 풀었던 문제와 선생님의 필기 내용은 종료된 과외방에서 확인할 수 있습니다!
                        <br/>
                        <br/>
                        단, 이미 종료된 과외방에 추가적으로 필기를 하시더라도 추가된 필기 내용은 저장되지 않습니다!
                        <br/>
                        (종료된 과외방에는 수업 시간 도중 기록된 정보만 남습니다.😊)
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb12"/>
                <section class="box">
                    <label class="box-title" for="cb12"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile-2': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 수업 횟수, 스케쥴, 선생님을 중간에 바꿀 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍ IVYED는 학생의 사정에따라 주 수업횟수, 스케쥴, 선생님 모두 변경이 가능합니다!
                        <br/>
                        <br/>
                        수업횟수나 스케쥴은 매치된 선생님과 조율이 가능합니다.
                        <br/>
                        <br/>
                        혹 선생님과 조율이 안되거나 다른 사유로 선생님 변경이 필요할 시 IVYED 카카오 채널로 요청하시면 다른 선생님과 매칭해드립니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb13"/>
                <section class="box">
                    <label class="box-title" for="cb13"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 사정이 생겨 수업을 못할 땐 어떡하나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        수업 요일과 시간을 부득이한 개인 사정으로 인해 변경해야 할 때는 담당 선생님과 사전 조율 후 일정을 미뤄 진행하실 수 있습니다.
                        <br/>
                        <br/>
                        별도 이야기 없이 잦은 변경이 이루어질 경우, 페널티가 부과될 수 있으니 반드시 미리 조율 후 일정 변경해 주시기 바랍니다!
                        <br/>
                        <br/>
                        특히 수업 당일에 취소하거나 변경을 요청하는 경우에는 수업이 차감될 수 있으므로 최소한 1일 전에 선생님께 말씀해 주세요!😀
                    </div>

                </section>
                <input type="radio" name="accordion" id="cb14"/>
                <section class="box">
                    <label class="box-title" for="cb14"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 여러 과목을 함께 수강할 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍️ 물론 가능합니다! 카톡 상담채널로 개별 요청해주세요!
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb15"/>
                <section class="box">
                    <label class="box-title" for="cb15"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 수업 가능한 과목은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED는 확실한 케어와 최상의 수업을 보장하기 위해 현재 수학&과학 계열 과목 수업에만 집중하고있습니다.
                        <br/>
                        <br/>
                        학생들이 많이 어려워하는 Computer Science, Calculus, Physics 등의 advanced 과정 부터 중/고 일반 과정까지 포괄적으로 수업을
                        제공해드립니다! 😊
                        <br/>
                        <br/>
                        빠른 시일내로 과목을 확장하여 서비스 제공할 수 있도록 노력하겠습니다!
                    </div>

                </section>
                <div class="py-5"></div>

                <h1 class="ma-3">선생님</h1>
                <input type="radio" name="accordion" id="cb16"/>
                <section class="box">
                    <label class="box-title" for="cb16"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 선생님 매칭은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        선생님 매칭은 '무료 체험수업 신청서' 후 제출이 완료되면 시작됩니다!
                        <br/>
                        <br/>
                        회원가입 후 '무료 체험수업 신청서'를 작성할 때, 원하는 선생님 스타일과 학생의 정보 (재학중인 학교, 희망 전공 계열, 선호 수업시간대 등)을 꼼꼼히 작성해주시면 학생에게 맞는 최적의 선생님을 매칭해 드리고있습니다.
                        <br/>
                        <br/>
                        선생님 매칭은 신청서 제출 후 영업일 기준 3일 정도 소요됩니다.
                        <br/>
                        <br/>
                        단, 수업 가능 시간대가 너무 적거나 매칭이 어려운 조건으로 요청하실 경우 매칭기간이 좀 더 소요될 수 있으며, 이 경우 사전에 안내를 드리고 있습니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb17"/>
                <section class="box">
                    <label class="box-title" for="cb17"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> IVYED 선생님은 어떤 학교 출신이신가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업생들로 이루어져 있습니다.
                        <br/>
                        <br/>
                        이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.
                        <br/>
                        <br/>

                        상위 1% 대학 목록은 USNews Rank 기준으로 선정됩니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb18"/>
                <section class="box">
                    <label class="box-title" for="cb18"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span>                 IVYED 선생님들은 모두 재학생인가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED의 과외 선생님들은 Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업하신 선생님들로 이루어져 있습니다.
                        <br/>
                        <br/>
                        이 최소 조건 외에도 과외 경력과 교습능력 등을 꼼꼼히 평가받으신 분들로 구성되어 있습니다.
                        <br/>
                        <br/>

                        재학생 선생님은 입시를 최근에 경험하셔서 입시 관련 상담까지 일부 도와드릴 수 있는 장점이 있고, 졸업생 선생님은 좀 더 교수 경험이 풍부하다는 장점이 있습니다.😊
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb19"/>
                <section class="box">
                    <label class="box-title" for="cb19"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 선생님을 중간에 바꿀 수 있나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻 ‍️IVYED는 학생이 <br/>'학생정보 입력 및 수업 신청서'에 적어주신 내용을 반영하여 선생님을 매칭해 드리고 있습니다!
                        <br/>
                        <br/>
                        매칭된 선생님과 수업 진행 중, 선생님 변경이 필요하신 경우 월 1회까지 선생님 변경 요청이 가능합니다.
                        <br/>
                        <br/>

                        단, 재매칭이 진행되는 동안 기존 수업은 진행되지 않습니다.
                        <br/>
                        <br/>

                        IVYED는 횟수제 수업으로 새로운 선생님 매칭 이후 남은 수업을 진행해 주시면 됩니다!😀
                    </div>

                </section>
                <div class="py-5"></div>

                <h1 class="ma-3">결제/환불</h1>
                <input type="radio" name="accordion" id="cb20"/>
                <section class="box">
                    <label class="box-title" for="cb20"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 환불 규정은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        <strong>- 교습 시작 전:</strong> 이미 납부한 교습비 등의 전액 환불
                        <br/>
                        <strong>- 교습 시작 후:</strong>
                        <br/>
                        <v-card flat class="ml-5">
                            1. 수강기간이 1개월(30일) 이내인 강좌의 경우
                            <br/>
                            <v-card flat class="ml-7">
                                - 수강시작일로부터 ⅓ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ⅔에 해당하는 금액
                                <br/>
                                - 수강시작일로부터 ½ 경과 전인 경우: 환불액 = 이미 납부한 교습비의 ½에 해당하는 금액
                                <br/>
                                - 수강시작일로부터 ½ 경과 후: 반환하지 않음
                                <br/>
                            </v-card>

                            <br/>
                            <br/>

                            2. 수강기간이 1개월(30일)을 초과하는 강좌의 경우
                            <br/>
                            <v-card flat class="ml-7">

                                - 환불 접수 발생월의 반환대상 교습비 등 (교습기간이 1개월 이내인 경우의 기준에 따라 산출한 금액)과 나머지 월의 교습비 등의 전액을 합산한 금액
                            </v-card>
                        </v-card>
                        <br/>
                        ** 학생이 당일 취소한 과외건은 환불 대상에 포함하지 않는다.
                        <br/>
                        ** 수강률은 당월 수강한 강의 수/전체 강의 수'를 의미한다.
                        <br/>
                        <br/>

                        더 자세한 내용은 이용약관 제4장 청약철회 및 환불 내용을 참고부탁드립니다.
                    </div>

                </section>
                <input type="radio" name="accordion" id="cb21"/>
                <section class="box">
                    <label class="box-title" for="cb21"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> 환불 절차는 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        수업료 환불 요청은 IVYED 카카오채널로 연락주시면 5영업일 이내에 교습비를 환불합니다.
                        <br/>
                        <br/>
                        환불 수업료는 체크카드를 이용해주신 경우에는 취소 후 바로 환급되고, 신용카드의 경우에는 카드사에 따라 평균 5~7일 정도 소요됩니다.
                        <br/>
                        <br/>

                        이미 카드값이 빠져나간 이후라면 그 다음달 카드값(결제대금)에 취소된 금액이 마이너스 되어 청구되는 점 참고부탁드립니다.
                    </div>

                </section>
                <div class="py-5"></div>

                <h1 class="ma-3">선생님 지원</h1>
                <input type="radio" name="accordion" id="cb22"/>
                <section class="box">
                    <label class="box-title" for="cb22"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> [선생님 지원] 선생님 지원 자격은 어떻게 되나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        Harvard, Stanford, Yale, MIT 등 미국 상위 1% 대학교 출신의 재학, 휴학, 졸업한 선생님들만 활동이 가능합니다. 타 대학 선생님들을 모시지 못 하는
                        점 양해 부탁드립니다.
                        <br/>
                        <br/>
                        타 대학 편입, 자퇴 등의 경우에는 역시 활동이 불가하신 점 양해부탁드립니다.
                    </div>

                </section>
                <input type="radio" name="accordion" id="cb23"/>
                <section class="box">
                    <label class="box-title" for="cb23"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> [선생님 지원] 해외에서도 선생님 활동이 가능한가요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        🙆🏻‍️IVYED는 유학 전문 과외 특성상 해외 거주 중인 선생님들도 활동이 가능합니다!
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb24"/>
                <section class="box">
                    <label class="box-title" for="cb24"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> [선생님 지원] 선생님 활동 세부규정이 궁금해요.</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        선생님 활동 시의 세부규정에 대해서는 선생님 지원을 모두 완료하시면 안내받으실 수 있습니다.
                        <br/>
                        <br/>

                        현재 활동중이시라면 IVYED 카카오톡 채널로 문의주시거나, 이전에 안내받으신 선생님 가이드에서 확인하실 수 있습니다!
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb25"/>
                <section class="box">
                    <label class="box-title" for="cb25"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> [선생님 지원] 학생계정으로 잘못 가입했어요.</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        학생으로 잘못 가입하신 경우에 IVYED 카카오톡 채널로 요청주시면 선생님 계정으로 변경할 수 있도록 안내해드리겠습니다.
                    </div>

                </section>

                <input type="radio" name="accordion" id="cb26"/>
                <section class="box">
                    <label class="box-title" for="cb26"><span class="question-text"
                                                              :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                    ><span class="primary--text">Q.</span> [선생님 지원] 선생님 지원은 어떻게 하나요?</span></label>
                    <label class="box-close" for="acc-close"></label>
                    <div class="box-content">
                        IVYED 홈페이지에서 회원 가입하고 신청서 제출하시면 지원 끝!
                        <br/>
                        <br/>
                        1~2 영업일 내로 바로 연락드립니다! 😊
                        <br/>
                        <br/>

                        IVYED에 선생님 지원 관련 추가 문의사항은 IVYED 카카오 채널이나
                        하단 링크를 클릭하세요!
                        <br/>
                        <a href="https://www.notion.so/262e0e113d9046128c5f8eadf4669b13">👉선생님 모집 페이지 바로가기</a>

                    </div>

                </section>
            </nav>

        </div>


    </v-container>
</template>

<script>
    // import FaqTemplate from "./FaqTemplate";
    export default {
        name: "FaqPage",
        components: {}
    }
</script>

<style scoped>
    .faq-page {
        background: #F3E5E5;
    }

    .justify-center {
        text-align: center;
    }

    .sub-main-01 {
        font-weight: bold;
        /*color: #290F29;*/
        font-size: 36px;
    }

    body {
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 0;
        padding: 10px;
        display: flex;
        background: #f2f2f2;
        color: rgba(0, 0, 0, .87);
        font-family: 'Roboto', sans-serif;
    }

    .accordion {
        margin: auto;
        max-width: 600px;
    }

    .accordion input {
        display: none;
    }

    .box {
        /*border-radius: 1;*/
        vertical-align: center;
        position: relative;
        background: white;
        height: 52px;
        margin-bottom: 20px;
        transition: all .15s ease-in-out;
    }

    .box::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        vertical-align: center;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
    }

    header.box {
        background: #00BCD4;
        z-index: 100;
        cursor: initial;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px -2px rgba(0, 0, 0, .12), 0 2px 4px -4px rgba(0, 0, 0, .24);
    }

    header .box-title {
        margin: 0;
        font-weight: normal;
        font-size: 16pt;
        color: white;
        cursor: initial;
    }

    .box-title {
        /*width: calc(100% - 40px);*/
        height: 64px;
        line-height: 52px;
        /*vertical-align: center;*/
        padding: 0 20px;
        /*display: inline-block;*/
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .box-content {
        /*width: calc(100% - 40px);*/
        padding: 30px 20px;
        font-size: 12pt;
        /*color: rgba(0,0,0,.54);*/
        display: none;
    }

    .box-close {
        position: absolute;
        height: 64px;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        display: none;
    }

    input:checked + .box {
        height: auto;
        margin: 16px 0;
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
    }

    input:checked + .box .box-title {
        /*border-bottom: 1px solid rgba(0, 0, 0, .18);*/
    }

    input:checked + .box .box-content,
    input:checked + .box .box-close {
        display: inline-block;
    }

    .arrows section .box-title {
        padding-left: 44px;
        width: calc(100% - 64px);
    }

    .arrows section .box-title:before {
        position: absolute;
        display: block;
        content: '\203a';
        /*vertical-align: center;*/
        font-size: 18pt;
        left: 20px;
        top: -2px;
        transition: transform .15s ease-in-out;
        color: rgba(0, 0, 0, .54);
    }

    input:checked + section.box .box-title:before {
        transform: rotate(90deg);
    }

    .question-text {
        font-weight: bold;
    }

    .question-text-mobile {
        font-weight: bold;
        font-size: 14px;
    }
    .question-text-mobile-2 {
        font-weight: bold;
        font-size: 13px;
    }

    .faq-container {
        height: 100%;
    }
</style>
