import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from './plugins/vuetify'
import Vuex from 'vuex';
import VueRouter from "vue-router";
// import VueAnalytics from "vue-analytics";
// import VueGtag from 'vue-gtag'

Vue.config.productionTip = false;
// Vue.use(router);
// Vue.use(store);

Vue.use(VueRouter);
Vue.use(Vuex);
// Vue.use(VueMeta);
// Vue.use(VueAnalytics, {
//   id:'321176109',
//   router
// });
// Vue.use(VueGtag, {
//   config: {
//     id: 'G-XSHBZ4YD0F'
//   },
// });
const app = new Vue({
  router: router,
  store,
  vuetify,
  render: (h) => h(App)
});
// app.use(router);
// app.use(store);
app.$mount("#app");

