<template>
    <!--    <div id="faq">-->
    <!--        <h1>자주 묻는 질문</h1>-->
    <!--        <ul>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <div class="" :class="{'question-font': $vuetify.breakpoint.mdAndUp,-->
    <!--                            'mobile-question-font': $vuetify.breakpoint.smAndDown}">-->
    <!--                    <span class="primary&#45;&#45;text">Q. </span>선생님 매칭은 어떻게 되나요?</div>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae debitis iusto voluptatum doloribus rem, qui nesciunt labore tempore fugit iste deserunt incidunt error provident repudiandae laudantium quo ipsa unde perspiciatis, nihil autem distinctio! Deserunt, aspernatur.</p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <h2>Hvorfor..?</h2>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sapiente quasi, quia provident facere recusandae itaque assumenda fuga veniam dicta earum dolorem architecto facilis nisi pariatur.</p>-->
    <!--            </li>-->
    <!--            <li>-->
    <!--                <input type="checkbox" checked>-->
    <!--                <i></i>-->
    <!--                <h2>Hvad..?</h2>-->
    <!--                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam quas placeat assumenda mollitia magni consequatur dolorum, quod nihil distinctio aperiam officia alias! Voluptate dolore ex officiis sit, magnam non a, eligendi pariatur aut, earum dolores tenetur ipsam id illo deleniti. Laudantium deserunt eaque ipsam voluptatum consequuntur voluptatibus sed minima ad accusamus debitis eos similique laboriosam, molestiae? Consequatur neque tempore quis. Eligendi, in ut aspernatur esse nesciunt libero.</p>-->
    <!--            </li>-->
    <!--        </ul>-->
    <!--    </div>-->
    <div class="faq-container">
        <nav class="accordion arrows">
            <!--        <header class="box">-->
            <!--            <label for="acc-close" class="box-title">Accordion menu</label>-->
            <!--        </header>-->
<!--            <input type="radio" name="accordion" id="cb0"/>-->
<!--            <section class="box">-->
<!--                <label class="box-title" for="cb0"><span class="question-text"-->
<!--                                                         :class="{-->
<!--                'question-text': $vuetify.breakpoint.mdAndUp,-->
<!--                'question-text-mobile': $vuetify.breakpoint.smAndDown-->
<!--                }"-->
<!--                ><span class="primary&#45;&#45;text">Q.</span> 어떤 과목 수업을 제공하나요?</span></label>-->
<!--                <label class="box-close" for="acc-close"></label>-->
<!--                <div class="box-content">-->
<!--                    현재 수학 과학 계열만 제공하고 있으며 자세한 과목은 아래 참고 부탁드립니다.-->
<!--                    <br/>-->
<!--                    <br/>-->
<!--                    <h2 class="font-weight-bold"  style="font-size: 18px">Mathematics / CS</h2>-->
<!--                    AP CS Principles <br/>-->
<!--                    AP CS A <br/>-->
<!--                    CS & Programming <br/>-->
<!--                    Robotics <br/>-->
<!--                    Multi-variate Calculus <br/>-->
<!--                    AP Calc AB <br/>-->
<!--                    AP Calc BC <br/>-->
<!--                    Calculus <br/>-->
<!--                    Pre-calculus <br/>-->
<!--                    Algebra <br/>-->
<!--                    Geometry <br/>-->
<!--                    AP Statistics <br/>-->
<!--                    Statistics <br/>-->
<!--                    SAT <br/>-->
<!--                    Math (Middle School)-->
<!--                    <br/>-->
<!--                    <br/>-->

<!--                    <h2 class="font-weight-bold"  style="font-size: 18px">Science</h2>-->

<!--                    AP Physics 1 <br/>-->
<!--                    AP Physics 2 <br/>-->
<!--                    AP Physics C Mech <br/>-->
<!--                    AP Physics C E&M <br/>-->
<!--                    Physics <br/>-->
<!--                    AP Chemistry <br/>-->
<!--                    Chemistry <br/>-->
<!--                    AP Biology <br/>-->
<!--                    Biology <br/>-->
<!--                    AP Environmental Science <br/>-->
<!--                    Astronomy <br/>-->
<!--                    Environmental Science <br/>-->
<!--                    Science (Middle School) <br/>-->
<!--                </div>-->

<!--            </section>-->
            <input type="radio" name="accordion" id="cb1"/>
            <section class="box">
                <label class="box-title" for="cb1"><span class="question-text"
                :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"
                ><span class="primary--text">Q.</span> Why choose IVYED?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content">
                    <span class="font-italic font-weight-bold">ANYWHERE</span><br/>
                    You can study and learn literally anywhere and at anytime you want.
                    <br/>
                    <br/>
                    <span class="font-italic font-weight-bold">TOP CLASS TUTORS</span><br/>
                    Tutors from Ivy Leagues and top tier colleges.
                    <br/>
                    <br/>

                    <span class="font-italic font-weight-bold">SMART MATCHING</span><br/>
                    Strictly tailored to student's needs and learning style.
                </div>
            </section>
            <input type="radio" name="accordion" id="cb2"/>
            <section class="box">
                <label class="box-title" for="cb2"><span class="question-text"
                                                         :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> How do I get matched?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content">
                    Once you submit a 'Free Trial' form, our matching process begins.
                    <br/>
                    <br/>
                    You will find the form upon registration, and it's crucial that you provide a type of tutor you prefer and detailed information on yourself (current school, desired major, preferred date and time) as this information is used for our smart matching.

                    <br/>
                    <br/>
                    Normally, our matching does not take more than 3 business days.
                    <br/>
                    <br/>

                </div>
            </section>

            <input type="radio" name="accordion" id="cb4"/>
            <section class="box">
                <label class="box-title" for="cb4"><span class="question-text"
                                                         :class="{
                'question-text': $vuetify.breakpoint.mdAndUp,
                'question-text-mobile': $vuetify.breakpoint.smAndDown
                }"><span class="primary--text">Q.</span> Can I change my tutor?</span></label>
                <label class="box-close" for="acc-close"></label>
                <div class="box-content ">
                    Yes!
                    <br/>We match tutors based on the information you have submitted initially.
                    <br/><br/>If you would like to add more preferences for tutor matching, please contact us before a matching begins.

                </div>
                <br/>
                <br/>
            </section>

        </nav>
<!--        <div class="text-center pt-4">-->
<!--            <a target="_blank" :class="{-->

<!--                    }" :href="'/faq'">  자주 묻는 질문 더 보기 </a>-->

<!--        </div>-->

    </div>
</template>

<script>
    export default {
        name: "FaqTemplate"
    }
</script>

<style scoped>
    body {
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        margin: 0;
        padding: 10px;
        display: flex;
        background: #f2f2f2;
        color: rgba(0, 0, 0, .87);
        font-family: 'Roboto', sans-serif;
    }

    .accordion {
        margin: auto;
        max-width: 500px;
    }

    .accordion input {
        display: none;
    }

    .box {
        /*border-radius: 1;*/
        vertical-align: center;
        position: relative;
        background: white;
        height: 52px;
        margin-bottom: 20px;
        transition: all .15s ease-in-out;
    }

    .box::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        vertical-align: center;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .24);
    }

    header.box {
        background: #00BCD4;
        z-index: 100;
        cursor: initial;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px -2px rgba(0, 0, 0, .12), 0 2px 4px -4px rgba(0, 0, 0, .24);
    }

    header .box-title {
        margin: 0;
        font-weight: normal;
        font-size: 16pt;
        color: white;
        cursor: initial;
    }

    .box-title {
        /*width: calc(100% - 40px);*/
        height: 64px;
        line-height: 52px;
        /*vertical-align: center;*/
        padding: 0 20px;
        /*display: inline-block;*/
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .box-content {
        /*width: calc(100% - 40px);*/
        padding: 30px 20px;
        font-size: 12pt;
        /*color: rgba(0,0,0,.54);*/
        display: none;
    }

    .box-close {
        position: absolute;
        height: 64px;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        display: none;
    }

    input:checked + .box {
        height: auto;
        margin: 16px 0;
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
    }

    input:checked + .box .box-title {
        /*border-bottom: 1px solid rgba(0, 0, 0, .18);*/
    }

    input:checked + .box .box-content,
    input:checked + .box .box-close {
        display: inline-block;
    }

    .arrows section .box-title {
        padding-left: 44px;
        width: calc(100% - 64px);
    }

    .arrows section .box-title:before {
        position: absolute;
        display: block;
        content: '\203a';
        /*vertical-align: center;*/
        font-size: 18pt;
        left: 20px;
        top: -2px;
        transition: transform .15s ease-in-out;
        color: rgba(0, 0, 0, .54);
    }

    input:checked + section.box .box-title:before {
        transform: rotate(90deg);
    }

    .question-text {
        font-weight: bold;
    }
    .question-text-mobile {
        font-weight: bold;
        font-size: 14px;
    }
    .faq-container {
        height: 100%;
    }
</style>
