<template>
    <v-container fluid class="" style="background-color: #F2F3F4; padding-bottom: 88px; padding-top: 36px;">
        <v-container class="new-container">
            <v-row
                    align=""
                    class="mt-3"
                    :class="{
            'mb-5': $vuetify.breakpoint.xs,
          }">
                <v-col>
                    <p class="font-weight-bold black--text pb-5" style="font-size: 36px;">How It Works</p>
                </v-col>
            </v-row>
        </v-container>
        <div class="center-horizontal">
            <v-card class="rounded-xl mx-3 " elevation="10" max-width="500px">
                <v-card class="rounded-xl pa-3" flat>
                    <v-card-title
                            style="font-size: 40px; font-weight: bold; color: #5C060D; padding-bottom: 0 !important;">
                        01
                    </v-card-title>
                    <v-card-title class="font-weight-bold mb-1" style="font-size: 22px">Fill out the Free Trial form<br/>
                    </v-card-title>
                    <v-card-subtitle style="font-size: 16px">Based on the information you have filled out on registration and on the free trial form, we find a tutor(s) that best matches you and your needs</v-card-subtitle>
                </v-card>
                <v-row class="mb-5 pb-5 justify-center">
                    <v-col cols="5" md="4" sm="4" class="pr-1">
                        <v-card max-width="250px" class="ml-3 " outlined>
                            <div class="text-center white--text font-weight-bold" style="background-color: #084698">
                                STUDENT
                            </div>
                            <v-divider></v-divider>
                            <v-img contain height="120px"
                                   src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=Gray02&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Smile&skinColor=Pale"></v-img>
                            <div class="pl-2" style=" font-size: 15px;">
                                <!--                            #Columbia<br/>-->

                                <span class="highlight-container"><span
                                        class="highlight" style="color: black !important;">#BoardingSchool</span></span><br/>
                                <span class="highlight-container-light-blue"><span
                                        class="highlight-light-blue"
                                        style="color: black !important;">#Geometry</span></span><br/>
                                <span class="highlight-container-green"><span
                                        class="highlight-green"
                                        style="color: black !important;">#HelpWithGPA</span></span><br/>
                                #MoreProblemSolving
                            </div>
                        </v-card>
                    </v-col>
                    <div class="center-vertical">
                        <v-icon size="40" color="" style="">mdi-arrow-left-right-bold</v-icon>
                    </div>
                    <v-col md="4" cols="5" sm="4" class="pl-1">
                        <v-card max-width="250px" class="mr-3" outlined>
                            <div class="text-center white--text font-weight-bold" style="background-color: #A20916">
                                TEACHER
                            </div>

                            <v-img contain height="120px"
                                   src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"></v-img>
                            <div class="pl-2" style=" font-size: 15px;">
                            <span class="highlight-container"><span
                                    class="highlight" style="color: black !important; ">#PrepSchoolGrad</span></span><br/>
                                #Stanford<br/>

                                <span class="highlight-container-light-blue"><span
                                        class="highlight-light-blue"
                                        style="color: black !important;">#MathMajor</span></span><br/>
                                <span class="highlight-container-green"><span
                                        class="highlight-green"
                                        style="color: black !important;">#Experienced</span></span><br/>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

            <div class="py-8"></div>
            <v-card max-width="500px" class="rounded-xl mx-3 pa-3 mb-10 pb-15" elevation="10" height="300px">
                <v-card-title style="font-size: 40px; font-weight: bold; color: #5C060D; padding-bottom: 0 !important;">
                    02
                </v-card-title>
                <v-card-title class="font-weight-bold mb-1" style="font-size: 22px">Free Trial</v-card-title>
                <v-card-subtitle style="font-size: 16px">
                    Class takes place in our own platform, an interactive real-time whiteboard that provides class recordings after each class.
                </v-card-subtitle>

                <v-img contain class="hidden-sm-and-up img-shadow pa-15 mt-7 mr-7"
                       src="https://i.postimg.cc/g23s0KJR/new-pic.png"></v-img>
                <v-img contain class="hidden-xs-only img-shadow pa-15 mt-7 mr-7" style="left: 260px"
                       src="https://i.postimg.cc/g23s0KJR/new-pic.png"></v-img>
                <!--            <v-img contain class=" img-shadow-mobile pa-15 mt-7 mr-7" src="https://i.postimg.cc/g23s0KJR/new-pic.png"></v-img>-->
                <!--            <v-img class="" src="https://i.postimg.cc/g23s0KJR/new-pic.png"></v-img>-->

            </v-card>
            <div class="py-8"></div>
            <v-card max-width="500px" class="rounded-xl pa-3 mx-3 mb-5" elevation="10">
                <v-card-title style="font-size: 40px; font-weight: bold; color: #5C060D; padding-bottom: 0 !important;">
                    03
                </v-card-title>
                <v-card-title class="font-weight-bold mb-1" style="font-size: 22px">Review</v-card-title>
                <v-card-subtitle style="font-size: 16px">
                    After each lesson, students are provided with feedback, homework, and class recordings for review.
                </v-card-subtitle>
                <!--            <v-card class="pa-3" color="black" width="70%" height="100px">-->
                <!--                dsf-->
                <!--            </v-card>-->
                <!--            <FeedbackReportCard></FeedbackReportCard>-->
                <v-card flat style="padding-left: -10px; margin-left: -12px " class="pt-3">

                    <!--  ipad and phones  -->
                    <v-timeline
                            style="padding: 0px !important; "
                            align-top
                            class="hidden-md-and-up"
                            :dense="$vuetify.breakpoint.smAndDown"
                    >
                        <v-timeline-item
                                v-for="(item, i) in items"
                                :key="i"
                                :color="item.color"
                                :icon="item.icon"
                                fill-dot
                        >
                            <v-card
                                    :color="item.color"
                                    dark
                            >
                                <v-card-title class="" style="font-size: 18px">
                                    10-04-2022 Class Report
                                </v-card-title>
                                <v-card-text class="white pa-3 text--primary">
                                    <p class="my-2 font-weight-bold">Class Score</p>
                                    <p>2 (OK)</p>
                                    <!--                            <span class="font-weight-bold">수업 내용 / 이해도</span>-->
                                    <p class="my-2 font-weight-bold">What was covered</p>
                                    <p class="">
                                        ⬝ Walk through Incorrect HW questions
                                        <br/>
                                        ⬝ Concept + Sample questions on exponential equations

                                    </p>

                                    <p class="my-2 font-weight-bold">Level of Understanding</p>
                                    <p class="">
                                        ⬝ Struggled with correctly applying the log quotient & product rules when
                                        simplifying.
                                        This is a common mistake students make in the beginning until they solve a lot
                                        of
                                        practice problems.
                                        <br/>
                                        ⬝ Good at recognizing same base exponential expressions to simplify equations
                                    </p>
                                    <v-row class="justify-center">
                                        <v-btn
                                                :color="item.color"
                                                class="mx-0 my-4 "
                                                outlined
                                        >
                                            Watch Replay
                                        </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>


                    <!--  desktop browser  -->
                    <v-timeline
                            style="padding: 0px !important; margin-left: -380px;"
                            align-top
                            class="hidden-sm-and-down"
                            :dense="$vuetify.breakpoint.smAndDown"
                    >
                        <v-timeline-item
                                v-for="(item, i) in items"
                                :key="i"
                                :color="item.color"
                                :icon="item.icon"
                                fill-dot
                        >
                            <v-card
                                    :color="item.color"
                                    dark
                            >
                                <v-card-title class="" style="font-size: 18px">
                                    10-04-2022 Class Report
                                </v-card-title>
                                <v-card-text class="white pa-3 text--primary">
                                    <p class="my-2 font-weight-bold">Class Score</p>
                                    <p>2 (OK)</p>
                                    <!--                            <span class="font-weight-bold">수업 내용 / 이해도</span>-->
                                    <p class="my-2 font-weight-bold">What was covered</p>
                                    <p class="">
                                        ⬝ Walk through Incorrect HW questions
                                        <br/>
                                        ⬝ Concept + Sample questions on exponential equations

                                    </p>

                                    <p class="my-2 font-weight-bold">Level of Understanding</p>
                                    <p class="">
                                        ⬝ Struggled with correctly applying the log quotient & product rules when
                                        simplifying.
                                        This is a common mistake students make in the beginning until they solve a lot
                                        of
                                        practice problems.
                                        <br/>
                                        ⬝ Good at recognizing same base exponential expressions to simplify equations
                                    </p>
                                    <v-row class="justify-center">
                                        <v-btn
                                                :color="item.color"
                                                class="mx-0 my-4 "
                                                outlined
                                        >
                                            Watch Replay
                                        </v-btn>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-timeline-item>
                    </v-timeline>

                </v-card>
            </v-card>
        </div>
    </v-container>
</template>

<script>
    // import StudentCheckBox from "./StudentCheckBox";
    // import StudentCheckBox from "./StudentCheckBox";

    // import FeedbackReportCard from "./FeedbackReportCard";
    export default {
        name: "HowItWorksIntro",
        components: {},
        data: () => ({
            items: [
                {
                    color: 'red ',
                    icon: 'mdi-grease-pencil',
                },
                // {
                //     color: 'purple darken-1',
                //     icon: 'mdi-book-variant',
                // },
                // {
                //     color: 'green lighten-1',
                //     icon: 'mdi-airballoon',
                // },
                // {
                //     color: 'indigo',
                //     icon: 'mdi-buffer',
                // },
            ],
        }),
    }
</script>

<style scoped>
    body .container .card .box .content h2 {
        position: absolute;
        top: -10px;
        right: 30px;
        font-size: 8rem;
        color: rgba(255, 255, 255, 0.1);
    }

    .student-form-filling {
        background-image: url("https://i.postimg.cc/LhKX9Wcj/no-check.png");

        background-size: contain;
        /*height: 300px;*/
    }

    p.bubble {
        position: relative;
        width: 300px;
        text-align: center;
        line-height: 1.4em;
        margin: 40px auto;
        background-color: #fff;
        border: 8px solid #333;
        border-radius: 30px;
        font-family: sans-serif;
        padding: 20px;
        font-size: large;
    }

    p.thought {
        width: 300px;
        border-radius: 200px;
        padding: 30px;
    }

    p.bubble:before,
    p.bubble:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
    }

    p.speech:before {
        left: 30px;
        bottom: -50px;
        border: 25px solid;
        border-color: #333 transparent transparent #333;
    }

    p.speech:after {
        left: 38px;
        bottom: -30px;
        border: 15px solid;
        border-color: #fff transparent transparent #fff;
    }

    p.thought:before,
    p.thought:after {
        left: 10px;
        bottom: -30px;
        width: 40px;
        height: 40px;
        background-color: #fff;
        border: 8px solid #333;
        -webkit-border-radius: 28px;
        -moz-border-radius: 28px;
        border-radius: 28px;
    }

    p.thought:after {
        width: 20px;
        height: 20px;
        left: 5px;
        bottom: -40px;
        -webkit-border-radius: 18px;
        -moz-border-radius: 18px;
        border-radius: 18px;
    }

    .center-vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .center-horizontal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

    .highlight-container-light-blue, .highlight-light-blue {
        position: relative;
        /*font-weight: bold;*/
        color: #1E81F0;
    }


    .highlight-container-light-blue {
        display: inline-block;
    }

    .highlight-container-light-blue:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #28FFFD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

    .highlight-container-green, .highlight-green {
        position: relative;
        /*font-weight: bold;*/
        color: #1E81F0;
    }


    .highlight-container-green {
        display: inline-block;
    }

    .highlight-container-green:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #6EFF9E;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

    .img-shadow {
        filter: brightness(50%);
        filter: contrast(50%);
        filter: drop-shadow(5px 5px 5px black);
        /*position: absolute;*/
        /*z-index: 20;*/
        /*height: 100%;*/
        /*width: 100%;*/
        width: 250px;
        left: 90px;
        /*top: 10px;*/
        bottom: 40px;
    }

    .img-shadow-mobile {
        filter: brightness(50%);
        filter: contrast(50%);
        filter: drop-shadow(5px 5px 5px black);
        /*position: absolute;*/
        /*z-index: 20;*/
        /*height: 100%;*/
        /*width: 100%;*/
        width: 250px;
        left: 200px;
        /*top: 10px;*/
        bottom: 40px;
    }

</style>
