<template>
    <div>
        <v-card class="pa-5">
            <v-card-title class="font-weight-bold text-h5">
                Early Action vs Early Decision
            </v-card-title>
            <v-card-text style="font-size: 17px; line-height: 30px">
                대학 입시 준비할 때 고려 할 것이 정말 많습니다. 저 또한 고민을 많이 했었구요... 그 중에서도 오늘은 rising senior 들이 많이 고민하는 Early Action (EA)
                Early Decision (ED)에 관해서 이야기해보겠습니다.
                <v-divider class="my-7"></v-divider>
                <h3>왜 EA/ED 가 중요한가요?</h3>
                <br/>
                <h4>더 일찍 입학 결정을 받으실 수 있습니다.</h4>
                <v-img height="100%" class="my-4" :src="'https://i.postimg.cc/DzXrrQfs/eaed.png'">
                </v-img>
                <h4 style="font-weight: normal">
                    당연한 이야기겠지만 대학에 일찍 지원한다는 것은 입학 결정도 빨리 받는 것을 의미합니다.
                    <br/>
                    <br/>
                    빠르면 12월에 입학 통지를 받게되어 원서 스트레스 없이 졸업반 마지막 학기를 마무리 할 수 있게됩니다.
                </h4>
                <br/>
                <h4>합격할 가능성이 더 높을 수 있습니다.</h4>
                <h4 style="font-weight: normal">
                    다트머스 대학교를 비롯한 많은 학교들이 수시 전형 입학률이 월등히 높다고 인정하며 National Association for College Admission Counseling에서 발표한 리서치에서도 수시 전형이 월등히 합격률이 높다고 발표하고 있습니다.
                    <br/>
                    <br/>
                    입학률 통계자료를 직접보더라도 ED/EA 합격률이 정시 대비 2배정도 높아 해당 제도를 활용해야 목표 대학에 진학할 확률을 높일 수 있습니다.
                    <v-img class="my-4" :src="'https://i.postimg.cc/SNVb4ChW/eaed2.png'">

                    </v-img>
                </h4>
                <br/>
                <h3>Early Action 이란</h3>
                <h4 style="font-weight: normal">
                    학생들이 일반 지원자보다 훨씬 일찍 하나 이상의 학교에 지원 그리고 합격 통지를 받을 수 있는 제도입니다. 여러개의 학교에 자유롭게 지원이 가능하며 구속력이 없는 option으로 합격여부와 상관 없이 진학을 거부할 수 있습니다.
                    <br/>
                    <br/>
                    보통 12월, 1월, 늦어도 2월중으로는 결과를 통보해주며 학생들은 5월 1일까지 입학 여부를 결정해주시면 됩니다.
                    <br/>
                    <br/>
                    단... 하버드와 프린스턴 등 상위 특정 학교들은 Restrictive Early Action으로 진학을 거부 할 수 있는 option은 동일하지만 다른 학교 수시 전형 지원을 제한하고 있어 최상위권 대학교를 노리는 학생이라면 보통 수시 전형으로 한군데만 지원하게 됩니다.
                </h4>
                <v-img class="my-4" :src="'https://i.postimg.cc/XvP23KxD/eaed3.png'"></v-img>
                <br/>
                <h3>Early Decision 이란</h3>
                <h4 style="font-weight: normal">
                    Early Action과는 달리 Early Decision은 구속력이 있는 option 입니다. ED로 수시 지원을 하시면 다른 학교의 수시 지원이 불가하며, 합격 시 입학을 꼭 하셔야 합니다. (해당 사항 관련해서 지원 시 협약서에 학부모, 학생, 학교 카운슬러의 서명하시게됩니다.
                    <br/>
                    <br/>
                    입학 결정은 12월에 보통 결정되며 합격 시 정시 지원도 중단/철회하셔야 됩니다. 불합격일 경우 정시 풀로 재심사되는 경우도 있지만 정시에서 다시 합격하는 경우는 극히 드문 케이스입니다.
                    <br/>
                    <br/>
                    Early Decision Top 5 Universities<br/>
                    - Duke<br/>
                    - Brown<br/>
                    - University of Pennsylvania<br/>
                    - Dartmouth<br/>
                    - Cornell<br/>
                </h4>
                <v-divider class="my-5"></v-divider>
                <h3>Early Action vs. Early Decision</h3>
                <h4 style="font-weight: normal">
                    사실... 크게 상관이 없습니다. 학생이 원하는 reach school이 EA인지 ED인지에 따라 자연스럽게 결정하는게 좋습니다. EA, ED 둘다 정시 대비 합격률이 높습니다. 목표 대학 진학률을 최대한 높이는 수단으로 활용하시는 것을 추천드립니다.
                    <br/>
                    <br/>
                    Early 마감이 10월이라 9,10,11학년 성적과 스펙만 가지고 입학 결정이 이루어지는 만큼 시험 성적, 내신, personal essay를 <strong> 미리</strong> 그리고 <strong>꾸준히 </strong> 준비하시는게 중요합니다.
                </h4>
                <v-divider class="my-5"></v-divider>
                <h4 style="font-weight: normal; font-style: italic">
                    "IVYED는 학생들이 언제 어디서든 도움을 받으 실 수 있도록 1:1 과외/코칭해드리고 있습니다."

                </h4>
            </v-card-text>

            <v-card-text style="line-height: 30px">

            </v-card-text>
        </v-card>

    </div>
</template>

<script>
    export default {
        name: "EAvsED"
    }
</script>

<style scoped>

</style>
