<template>
<div>
    <v-form class=""
            ref="form"
            v-model="valid"
    >
        <v-card
                class="mx-auto primary--text pa-5 ma-2"
                max-width="750"
                flat
        >
            <v-card-title class="mt-5"
                          :class="{
                    'text-h5': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>현재 카드정보</strong>
            </v-card-title>
            <v-card flat>
                <v-card-title>
                    {{studentCardInfo.response.cardName}}
                    ({{String(studentCardInfo.response.cardNumber).slice(-4)}})

                </v-card-title>
            </v-card>

            <v-card-title class="mt-5">
                    <span
                            :class="{
                    'text-h5': $vuetify.breakpoint.lgAndUp,
                    'text-h5': $vuetify.breakpoint.mdAndDown,
                    // 'text-strong': $vuetify.breakpoint.mdAndDown
                    }"
                    ><strong>결제 수단 변경</strong></span>
            </v-card-title>
            <v-divider></v-divider>
            <template>
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <v-list-item-subtitle class="black&#45;&#45;text mb-n4 mt-4">할부 개월</v-list-item-subtitle>-->
<!--                    </v-col>-->
<!--                    <v-col>-->
<!--                        <v-list-item-subtitle class="justify-right mb-n4  mt-4 margin-test">-->

<!--                            <v-dialog-->
<!--                                    v-model="dialog"-->
<!--                                    width="500"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <a-->
<!--                                            class="text-decoration-underline"-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on">무이자 할부 가능-->
<!--                                    </a>-->
<!--                                    <v-icon class="" size="17px">mdi-information-outline</v-icon>-->


<!--                                </template>-->

<!--                                <v-card class="py-4 ">-->
<!--                                    <v-card-subtitle class=" dialog-title">-->
<!--                                        무이자 할부 적용 가능 금액-->
<!--                                        <p class="dialog-subtitle">-->
<!--                                            5만원 이상-->
<!--                                        </p>-->
<!--                                    </v-card-subtitle>-->

<!--                                    <v-card-text>-->
<!--                                        &lt;!&ndash;                                                BC 2 ~ 7개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                KB국민 2 ~ 7개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                하나(외환) 2 ~ 8개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                삼성 2 ~ 6개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                신한 2 ~ 7개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                현대 2 ~ 7개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                롯데 2 ~ 4개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                NH농협 2 ~ 8개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                전북 2 ~ 5개월 무이자 <br/>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                                                우리 2 ~ 7개월 무이자 <br/>&ndash;&gt;-->
<!--                                    </v-card-text>-->

<!--                                    <v-divider></v-divider>-->
<!--                                    <v-card-actions class="justify-end">-->
<!--                                        <v-btn-->
<!--                                                text-->
<!--                                                @click="dialog = false"-->
<!--                                        >닫기-->
<!--                                        </v-btn>-->
<!--                                    </v-card-actions>-->
<!--                                </v-card>-->
<!--                            </v-dialog>-->


<!--                        </v-list-item-subtitle>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-select-->
<!--                        v-model="requestBody.financing"-->
<!--                        :items="financingOptions"-->
<!--                        class="form-input-text"-->
<!--                        :rules="[rules.required, rules.listNotEmpty]"-->
<!--                        required-->
<!--                ></v-select>-->
                <v-list-item-subtitle class="black--text mt-4 mb-n4">카드 번호</v-list-item-subtitle>
                <v-text-field
                        v-model="billingRequestBody.cardNumber"
                        :counter="16"
                        :rules="[rules.creditCardNumberRules]"
                        placeholder="'-' 생략하고 입력"
                        class="form-input-text"
                        required
                ></v-text-field>

                <v-list-item-subtitle class="black--text mb-n4 mt-4">카드 유효기간</v-list-item-subtitle>
                <v-text-field
                        v-model="billingRequestBody.expiration"
                        :rules="[rules.creditCardExpirationRules]"
                        placeholder="월 (MM) / 년 (YY)"

                        class="form-input-text"
                        required
                ></v-text-field>
                <v-list-item-subtitle class="black--text mb-n4 mt-4">비밀번호 앞 2자리</v-list-item-subtitle>
                <v-text-field
                        v-model="billingRequestBody.cardPassword"
                        :counter="2"
                        :rules="[rules.creditCardPasswordRules]"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"

                        class="form-input-text"
                        @click:append="showPassword = !showPassword"
                        :type="showPassword ? 'text' : 'password'"


                        placeholder="**"
                        required
                ></v-text-field>
                <v-list-item-subtitle class="black--text mb-n4 mt-4">생년월일 6자리 (개인) / 사업자 번호 10자리 (법인)
                </v-list-item-subtitle>

                <v-text-field
                        v-model="billingRequestBody.birthday"
                        :counter="10"
                        :rules="[rules.required ]"
                        class="form-input-text"
                        placeholder="'-' 생략하고 입력"
                        required
                ></v-text-field>

                <p class="mt-4 msg-text black--text">* 결제한 수업이 모두 진행되면, <strong>위 카드로 다음 과외가 자동 결제</strong>됩니다.
                </p>
                <p class="msg-text black--text">* 카드정보는 <strong>NICEPAY</strong> 전자결제 회사에서 암호화해 안전하게 관리합니다. </p>
                <v-container class="text-center" fluid>

                    <v-btn
                            color="secondary"
                            class="mr-4"
                            x-large
                            :disabled="!valid"
                            @click="createStudentBillingCreditCard(billingRequestBody, currentUser.id)"
                    >
                        카드 정보 등록
                    </v-btn>

                </v-container>
                <div v-if="message">
                    <v-alert type="error">
                        {{ message }}
                    </v-alert>
                </div>
            </template>
        </v-card>
    </v-form>

</div>
</template>

<script>
    import info from "../../../info";
    import StudentService from "../../services/student.service";
    import EmailService from "../../services/email.service";

    export default {
        name: "CardInfo",
        computed: {
            enrollmentInfo() {
                return this.$store.state.enrollmentData;
            },

            paymentInfo() {
                return this.$store.state.studentRegisterData;
            },
            currentUser() {
                return this.$store.state.auth.user;
            },
            totalLessons() {
                return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 4;
            },
            discountedRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return 20;
                } else if (this.studentContent.expectedLessonDuration == 3) {
                    return 28;
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return 40;
                } else if (this.studentContent.expectedLessonDuration == 2) {
                    return 28;
                }
                return "";
            },
            originalPrice() {
                return this.studentContent.expectedLessonDuration * this.studentContent.expectedNumberOfLessonsPerWeek * 250000 * 4
            }
            ,
            discountedAmount() {
                return this.originalPrice*.01*this.discountedRate;
            },
            finalAmount() {
                return this.originalPrice - this.discountedAmount
            },
            selectedDiscountRate() {
                if (this.studentContent.expectedLessonDuration == 1) {
                    return '20%';
                }  else if (this.studentContent.expectedLessonDuration == 3) {
                    return '28%'
                } else if (this.studentContent.expectedLessonDuration == 12) {
                    return '40%'
                } else if (this.studentContent.expectedLessonDuration == 2) {
                    return '28%'
                }
                return '';
            },

        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
            if (this.currentUser.roles[0] != 'ROLE_STUDENT') {
                this.$router.push('/profile');
            }

            this.requestBody.finalPrice = 120000 * parseInt(this.studentContent.expectedNumberOfLessonsPerWeek)

            StudentService.getStudentInfo(this.currentUser.id).then(
                (response) => {
                    this.studentContent = response.data;
                    // this.studentContent.subjectsOfInterest = this.removeEmptyStringFromList(this.studentContent.subjectsOfInterest);
                    // console.log("IN GET STUDENT INFO")
                    this.calculatePrices(this.studentContent)
                    // console.log("IN GET STUDENT INFO")
                    // console.log(this.$router.history)
                    // if (this.$router.currentRoute.name == 'payment' && this.studentContent.payments[0].active == true) {
                    //     this.$router.push('/profile')
                    // }
                    // console.log(this.$router.currentRoute == 'payment' && )
                    // if ( this.studentContent.payments[0].active == true) {
                    //     this.$router.push('/profile')
                    // }

                },
                (error) => {
                    this.studentContent =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
            StudentService.getCardInfoOfStudent(this.currentUser.id).then(
                (response) => {
                    // console.log(response.data)
                    // this.lessons.subjects_of_expertise = this.removeEmptyStringFromList(this.lessons.subjects_of_expertise)
                    this.studentCardInfo = response.data;
                },
                (error) => {
                    this.studentCardInfo =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        methods: {
            calculatePrices(studentContent) {
                this.requestBody.originalPrice = studentContent.expectedNumberOfLessonsPerWeek * 120000 * studentContent.expectedLessonDuration * 4
                // test- 20% discount
                this.requestBody.discountedPrice = studentContent.expectedNumberOfLessonsPerWeek * 120000 * studentContent.expectedLessonDuration * 4 * 0.8
                this.requestBody.finalPrice = this.requestBody.discountedPrice
            },
            calculateDiscountPrice(price) {
                let discounted = parseFloat(price) * 0.6;
                return discounted;
            },
            finalPrice(price) {
                return this.$store.state.studentRegisterData.duration.replace(/[^\d.]/g, '') * price;
            },
            applyFinancing(price) {
                return price / 12;
            },
            validate() {
                this.$refs.form.validate()
            },

            sendNotificationEmail(requestBody) {
                EmailService.sendEmailNotification(requestBody).then(
                    (response) => {
                        console.log(response.data)
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                )
            },
            createStudentBillingCreditCard(requestBody, userId) {
                StudentService.createNewCustomerBilling(requestBody, userId).then(
                    (response) => {
                        this.studentBillingInfo = response.data;
                        // this.sendNotificationEmail({
                        //     'email': this.currentUser.email,
                        //     'emailBody': 'orderConfirmation'
                        // })
                        this.$router.push("/profile")
                    },
                    (error) => {
                        this.studentBillingInfo =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.handleErrorMessages(this.studentBillingInfo);
                    }
                )
            },

            createStudentPayment(requestBody, userId) {

                // requestBody.finalPrice = this.finalAmount + "";
                requestBody.finalPrice = 100 + "";
                requestBody.originalPrice = this.originalPrice + ""
                requestBody.discountedPrice = this.discountedAmount + ""

                StudentService.createStudentPayment(requestBody, userId).then(
                    (response) => {
                        console.log(response.data)
                        this.sendNotificationEmail({
                            'email': this.currentUser.email,
                            'emailBody': 'orderConfirmation'
                        })
                        this.$router.push("/payment/confirmation")
                    },
                    (error) => {
                        this.teacherContent =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        this.handleErrorMessages(this.teacherContent);
                    }
                )
            },
            handleErrorMessages(message) {
                // this.message = message;
                if (message.includes("유효하지않은 카드번호를")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 유효하지않은 카드번호를 입력하셨습니다."
                } else if (message.includes("유효기간")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 유효기간 오류";
                }
                // else if (message.includes("F113")) {
                //     this.message = "사용불가 카드입니다.";
                // }
                else if (message.includes("비밀번호")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 비밀번호 오류";
                } else if (message.includes("주민")) {
                    this.message = "카드정보 인증에 실패하였습니다 - 주민번호 or 사업자등록 번호 오류";
                } else if (message.includes("한도초과")) {
                    this.message = "[오류] 카드 한도초과";
                } else {
                    this.message = message;
                }
                // else if (message.includes("Email")) {
                //     this.message = "이미 사용중인 이메일 주소입니다."
                // } else if (message.includes("parse")) {
                //     this.message = "위 필드를 모두 입력하셔야 가입이 가능합니다."
                // } else if (message.includes("Bad")) {
                //     this.message = "아이디 혹은 비밀번호가 일치하지 않습니다."
                // } else if (message.includes("disabled")) {
                //     this.message = "이메일 인증을 진행 해주세요."
                // }
            },
        },
        data() {
            return {
                studentCardInfo: "",
                studentBillingInfo: "",
                rules: {
                        required: (value) => !!value || '필수 입력',
                    listNotEmpty: (value) => value.length > 0 || '필수 선택',
                    creditCardNumberRules: ((value) => !!value || '필수 입력') && ((value) =>  (value.length == 16 || value.length == 15) || ' \'-\' 을 제외한 16자리 카드번호를 입력해주세요.'),
                    creditCardExpirationRules:((v) => !!v || '필수 입력') && (v => (v.match('[0-90-9]\\/[0-90-9]')) || ' MM/YY 형식으로 입력해주세요. (예. 02/24)'),
                    creditCardPasswordRules: ((v) => (v.match('[0-9][0-9]')) || ' 비밀번호 첫 두자리 숫자를 입력해주세요.') && (v => (v.length == 2) || '비밀번호 두자리만 입력해주세요'),
                    creditCardBirthDayRules: ((v) => (v.length==6 || v.length==8) || "올바른 번호를 입력해주세요."),

                },
                message: "",
                dialog: false,
                showPassword: false,
                studentContent: "",
                agreements: {
                    student: {
                        checkbox: false,
                        title: "(학생 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다.",
                        content: "",
                    },
                    parents: {
                        checkbox: false,
                        title: "(학부모 동의 항목) 아래의 약관 및 개인정보 처리에 모두 동의합니다. ",
                        content: "",
                    }
                },
                requestBody: {
                    // subjectsOfInterest: this.$store.state.studentRegisterData.subjectsOfInterest,
                    // currentLocation: this.$store.state.studentRegisterData.currentLocation,
                    // frequency: this.$store.state.studentRegisterData.expecte,
                    // duration: this.$store.state.studentRegisterData.duration,
                    // tablet: this.$store.state.studentRegisterData.tablet,
                    originalPrice: "",
                    discountRate: "",
                    discountedPrice: "",
                    paidAmount: "",
                    paymentType: "",
                    finalPrice: "",
                    cardNumber: "",
                    expiration: "",
                    cardPassword: "",
                    birthday: "",
                    financing: "",
                },

                billingRequestBody: {
                    // subjectsOfInterest: this.$store.state.studentRegisterData.subjectsOfInterest,
                    // currentLocation: this.$store.state.studentRegisterData.currentLocation,
                    // frequency: this.$store.state.studentRegisterData.expecte,
                    // duration: this.$store.state.studentRegisterData.duration,
                    // tablet: this.$store.state.studentRegisterData.tablet,
                    cardNumber: "",
                    expiration: "",
                    cardPassword: "",
                    birthday: "",
                    // financing: "",
                },
                // temp: this.requestBody.financing.replace(/[^0-9.]+/g, ""),
                radios: null,
                nameRules: [
                    v => !!v || '필수 입력',
                    v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                ],
                cardNumberRules: [
                    // v => ,
                    v => !!v || '필수 입력' && (v.length == 16 || v.length == 15) || ' \'-\' 을 제외한 16자리 카드번호를 입력해주세요.',
                ],
                cardExpirationRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-90-9]\\/[0-90-9]')) || ' MM/YY 형식으로 입력해주세요. (예. 02/24)',
                ],
                cardPasswordRules: [
                    v => !!v || '필수 입력',
                    v => (v.match('[0-9][0-9]')) || ' 비밀번호 첫 두자리 숫자를 입력해주세요.',
                    v => (v.length == 2) || '비밀번호 두자리만 입력해주세요'
                ],
                birthdayRules: [
                    v => !!v || '필수 입력',
                    v => (v.length >= 6) || '생년월일은 6자리, 사업자 번호는 10자리 입력하여 주세요.',
                ],
                financingOptions: info.financingOptions,
                valid: false,
            }
        },
    }
</script>

<style scoped>
    .payment-background {
        background-color: #F7F8FA;
    }

    .form-input-text {
        font-size: 14px;
    }

    .justify-right {
        text-align: end;
    }

    .margin-test {
        font-size: 12px;

    }

    a:link {
        color: dimgray;
        background-color: transparent;
        text-decoration: underline;

    }

    .dialog-title {
        font-size: 16px;
        font-weight: bold;
    }

    .dialog-subtitle {
        font-size: 15px;
        font-weight: bold;
    }

    .msg-text {
        font-size: 10px;
    }
    .highlight-container, .highlight {
        position: relative;
        /*font-weight: bold;*/
        color: red;
    }

    .highlight-container {
        display: inline-block;
    }

    .highlight-container:before {
        content: " ";
        display: block;
        height: 90%;
        width: 100%;
        margin-left: -3px;
        margin-right: -3px;
        position: absolute;

        background: #FEDFDD;
        transform: rotate(2deg);
        top: -1px;
        left: -1px;
        border-radius: 20% 25% 20% 24%;
        padding: 10px 3px 3px 10px;
    }

</style>
